import { useDispatch, useSelector } from "react-redux";
import { currentCalculationBusinessUnitIdSelector, interimOrCurrentCalculationSelector, salesContactTeamSelector } from "../../store/selectors";
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select, TextField, Typography, } from '@material-ui/core';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './ContactInfoModal.module.scss';
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useDebounce } from "../Utilities/hooks/useDebounce";
import { callApi } from "../../common/api";
import { associateContact } from "../../actions/contactActions";
const config = {
    stringify: (option) => option.email,
};
const filter = createFilterOptions(config);
export function ContactInfoModal({ onClose }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const dispatch = useDispatch();
    const calculation = useSelector(interimOrCurrentCalculationSelector);
    const currentCalculationBusinessUnitId = useSelector(currentCalculationBusinessUnitIdSelector);
    const salesContactTeams = useSelector(salesContactTeamSelector);
    const contactTypes = [
        { "id": 1, "typeName": "Account Manager" },
        { "id": 2, "typeName": "Architect" },
        { "id": 3, "typeName": "Architectural Technologist" },
        { "id": 4, "typeName": "BER Assessor" },
        { "id": 5, "typeName": "Builder" },
        { "id": 6, "typeName": "Building Control" },
        { "id": 7, "typeName": "BUILDING STANDARDS" },
        { "id": 8, "typeName": "Civil Engineering (Infrastructure)" },
        { "id": 9, "typeName": "Civil Engineering (Utilities)" },
        { "id": 10, "typeName": "Community and Amenity" },
        { "id": 11, "typeName": "Construction Engineer" },
        { "id": 12, "typeName": "Consultant" },
        { "id": 13, "typeName": "Contractor" },
        { "id": 14, "typeName": "CPD Coordinator/Contact" },
        { "id": 15, "typeName": "Customer" },
        { "id": 16, "typeName": "Design Manager" },
        { "id": 17, "typeName": "Development Manager" },
        { "id": 18, "typeName": "Director" },
        { "id": 19, "typeName": "Distributor" },
        { "id": 20, "typeName": "Ductwork Installer" },
        { "id": 21, "typeName": "Education" },
        { "id": 22, "typeName": "End user / Owner" },
        { "id": 23, "typeName": "Energy Expert" },
        { "id": 24, "typeName": "Engineer" },
        { "id": 25, "typeName": "Fire consultant" },
        { "id": 26, "typeName": "Fire Expert" },
        { "id": 27, "typeName": "Group Purchasing Manager" },
        { "id": 28, "typeName": "Heritage Conservationist" },
        { "id": 29, "typeName": "Hotel and Leisure" },
        { "id": 30, "typeName": "Industrial" },
        { "id": 31, "typeName": "Koolduct Installer" },
        { "id": 32, "typeName": "Main Contractor" },
        { "id": 33, "typeName": "Mechanical Engineer" },
        { "id": 34, "typeName": "Medical and Scientific" },
        { "id": 35, "typeName": "Merchant" },
        { "id": 36, "typeName": "Moisture consultant" },
        { "id": 37, "typeName": "Offices/Commercial" },
        { "id": 38, "typeName": "Other" },
        { "id": 39, "typeName": "Price List Updates" },
        { "id": 40, "typeName": "Private Housing" },
        { "id": 41, "typeName": "Production" },
        { "id": 42, "typeName": "Production Director" },
        { "id": 43, "typeName": "Project Manager" },
        { "id": 44, "typeName": "Promoter" },
        { "id": 45, "typeName": "Property Services" },
        { "id": 46, "typeName": "Prospect" },
        { "id": 47, "typeName": "Purchasing Manager" },
        { "id": 48, "typeName": "Quantity Surveyor" },
        { "id": 49, "typeName": "Remodeler" },
        { "id": 50, "typeName": "Retail" },
        { "id": 51, "typeName": "Roofing Contractor" },
        { "id": 52, "typeName": "Social Housing" },
        { "id": 53, "typeName": "Specifier" },
        { "id": 54, "typeName": "Sub-Contractor" },
        { "id": 55, "typeName": "Supplier" },
        { "id": 56, "typeName": "Surveyor" },
        { "id": 57, "typeName": "System Suppliers" },
        { "id": 58, "typeName": "TEK Designer" },
        { "id": 59, "typeName": "Thermal Engineer" }
    ];
    const defaultState = {
        newContact: {
            id: null,
            businessUnitId: currentCalculationBusinessUnitId,
            firstName: '',
            lastName: '',
            jobTitle: '',
            company: '',
            contactType: '',
            businessPhone: '',
            mobilePhone: '',
            email: '',
            salesContactTeam: '',
            salesContactTeamValue: '',
            companyPostCode: '',
        },
        existingContact: null,
    };
    const [state, setState] = React.useState(defaultState);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [existingContact, setExistingContact] = React.useState([]);
    const [error, setError] = React.useState(null);
    const disableButtons = (calculation === null || calculation === void 0 ? void 0 : calculation.locked) || (state.newContact ? !state.newContact.email : !((_a = state.existingContact) === null || _a === void 0 ? void 0 : _a.email));
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const lookupContactType = (contactType) => {
        var _a, _b;
        return (_b = (_a = contactTypes.find(ct => ct.typeName === contactType)) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : contactTypes[0].id;
    };
    const updateField = (fieldName, value) => {
        setState(currentState => (Object.assign(Object.assign({}, currentState), (state.newContact
            ? {
                newContact: Object.assign(Object.assign({}, currentState.newContact), { [fieldName]: value }),
            }
            : {
                existingContact: Object.assign(Object.assign({}, currentState.existingContact), { [fieldName]: value }),
            }))));
    };
    const handleContactEmailChange = (contact) => {
        if (!contact) {
            return setState(defaultState);
        }
        if (contact.id != null) {
            setState(currentState => (Object.assign(Object.assign({}, currentState), { newContact: null, existingContact: contact })));
        }
        else {
            if (state.newContact) {
                setState(currentState => (Object.assign(Object.assign({}, currentState), { newContact: Object.assign(Object.assign({}, currentState.newContact), { email: contact.email, businessUnitId: currentCalculationBusinessUnitId }), existingContact: null })));
            }
            else {
                setState(currentState => (Object.assign(Object.assign({}, currentState), { newContact: contact, existingContact: null })));
            }
        }
    };
    const handleSubmit = React.useCallback(async (event, shouldAssociateToAll) => {
        event.preventDefault();
        try {
            if (state.newContact) {
                await dispatch(associateContact(state.newContact, true, shouldAssociateToAll));
            }
            else if (state.existingContact) {
                await dispatch(associateContact(state.existingContact, false, shouldAssociateToAll));
            }
            onClose();
        }
        catch (e) {
            setError('Your contact information could not be saved and associated, please try again.');
        }
    }, [dispatch, state, onClose]);
    React.useEffect(() => {
        if (calculation === null || calculation === void 0 ? void 0 : calculation.contact) {
            (async () => {
                var _a;
                const existingContact = await callApi(dispatch, 'GET', `/Contact/${currentCalculationBusinessUnitId}/${(_a = calculation.contact) === null || _a === void 0 ? void 0 : _a.id}`);
                if (existingContact) {
                    setState({
                        newContact: null,
                        existingContact,
                    });
                }
            })();
        }
    }, [dispatch, calculation, currentCalculationBusinessUnitId]);
    React.useEffect(() => {
        if (debouncedSearchTerm) {
            (async () => {
                if (searchTerm.length < 3) {
                    return setExistingContact([]);
                }
                const existingCoontacts = await callApi(dispatch, 'GET', `/Contact/search/${currentCalculationBusinessUnitId}/${debouncedSearchTerm}`);
                if (existingCoontacts) {
                    setExistingContact(existingCoontacts);
                }
            })();
        }
    }, [dispatch, searchTerm, debouncedSearchTerm, setExistingContact, currentCalculationBusinessUnitId]);
    return (React.createElement(Dialog, { open: true, onClose: onClose, "aria-labelledby": "modal-contact-info-title" },
        React.createElement("form", { "data-qa-id": "contactInfoModalForm", onSubmit: (event) => handleSubmit(event, false), noValidate: true },
            React.createElement("div", { className: modalStyles.modalHeader },
                React.createElement(DialogTitle, { id: "modal-contact-info-title" },
                    React.createElement(Typography, { component: "span", variant: "h5" }, "Contact Information"))),
            React.createElement("div", { className: `${[modalStyles.modalContent].join('')}` },
                React.createElement(DialogContent, null,
                    error && (React.createElement(Alert, { severity: "error", className: styles.alert, "data-qa-id": "contactInfoError" }, error)),
                    React.createElement(Grid, { container: true, spacing: 3 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoEmailInput", "data-qa-id": "contactInfoEmailLabel" }, "Email"),
                                    React.createElement(Autocomplete, { id: "contactInfoEmailInput", "data-qa-id": "contactInfoEmailInput", options: existingContact, autoHighlight: true, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, getOptionLabel: (option) => option.email, onInputChange: (event, searchTerm) => setSearchTerm(searchTerm), onChange: (event, contact) => handleContactEmailChange(contact), value: (_b = state.newContact) !== null && _b !== void 0 ? _b : state.existingContact, forcePopupIcon: true, freeSolo: true, renderOption: (contact) => {
                                            var _a;
                                            return contact.id != null ? (contact.email) : (React.createElement(Grid, { container: true, justify: "space-between", alignItems: "center" },
                                                React.createElement("span", { "data-qa-id": "newContactOptionEmail" }, contact.email),
                                                React.createElement(Grid, { item: true },
                                                    React.createElement(Button, { variant: "outlined", "data-qa-id": "newContactOptionButton" }, ((_a = state.newContact) === null || _a === void 0 ? void 0 : _a.email) ? 'Update' : '+ New Contact'))));
                                        }, filterOptions: (options, params) => {
                                            const filtered = filter(options, params);
                                            if (params.inputValue !== '') {
                                                const option = state.newContact
                                                    ? Object.assign(Object.assign({}, state.newContact), { email: params.inputValue }) : Object.assign(Object.assign({}, defaultState.newContact), { email: params.inputValue });
                                                filtered.push(option);
                                            }
                                            return filtered.reduce((acc, contact) => {
                                                const isNewOption = contact.id === null;
                                                const isAlreadyASuggestion = existingContact.find(p => p.email.toLowerCase() === contact.email.toLowerCase());
                                                return isNewOption && isAlreadyASuggestion ? acc : [...acc, contact];
                                            }, []);
                                        }, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { placeholder: "Enter 3 or more characters to search...", variant: "outlined", autoComplete: "off" // Disable browser autocomplete and autofill
                                         }))) })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoFirstNameInput", "data-qa-id": "contactInfoFirstNameLabel" }, "First Name"),
                                    React.createElement(TextField, { id: "contactInfoFirstNameInput", "data-qa-id": "contactInfoFirstNameInput", fullWidth: true, variant: "outlined", value: state.newContact ? state.newContact.firstName : (_c = state.existingContact) === null || _c === void 0 ? void 0 : _c.firstName, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('firstName', value), inputProps: {
                                            maxLength: 255,
                                        } })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoLastNameInput", "data-qa-id": "contactInfoLastNameLabel" }, "Last Name"),
                                    React.createElement(TextField, { id: "contactInfoLastNameInput", "data-qa-id": "contactInfoLastNameInput", fullWidth: true, variant: "outlined", value: state.newContact ? state.newContact.lastName : (_d = state.existingContact) === null || _d === void 0 ? void 0 : _d.lastName, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('lastName', value), inputProps: {
                                            maxLength: 255,
                                        } }))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoJobTitleInput", "data-qa-id": "contactInfoJobTitleLabel" }, "Job Title"),
                                    React.createElement(TextField, { id: "contactInfoJobTitleInput", "data-qa-id": "contactInfoJobTitleInput", fullWidth: true, variant: "outlined", value: state.newContact ? state.newContact.jobTitle : (_e = state.existingContact) === null || _e === void 0 ? void 0 : _e.jobTitle, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('jobTitle', value), inputProps: {
                                            maxLength: 255,
                                        } })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoCompanyInput", "data-qa-id": "contactInfoCompanyLabel" }, "Company"),
                                    React.createElement(TextField, { id: "contactInfoCompanyInput", "data-qa-id": "contactInfoCompanyInput", fullWidth: true, variant: "outlined", value: state.newContact ? state.newContact.company : (_f = state.existingContact) === null || _f === void 0 ? void 0 : _f.company, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('company', value), inputProps: {
                                            maxLength: 255,
                                        } })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoCompanyPostCode", "data-qa-id": "contactInfoCompanyPostCodeLabel" }, "Company Postcode"),
                                    React.createElement(TextField, { id: "contactInfoCompanyPostCodeInput", "data-qa-id": "contactInfoCompanyPostCodeInput", fullWidth: true, variant: "outlined", value: state.newContact ? state.newContact.companyPostCode : (_g = state.existingContact) === null || _g === void 0 ? void 0 : _g.companyPostCode, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('companyPostCode', value), inputProps: {
                                            maxLength: 255,
                                        } }))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoContactTypeInput", "data-qa-id": "contactInfoContactTypeLabel" }, "Contact Type"),
                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "contactInfoContactTypeInput", id: "contactInfoContactTypeInput", value: state.newContact ? lookupContactType(state.newContact.contactType) : lookupContactType(state.existingContact.contactType), disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => { var _a, _b; return updateField('contactType', (_b = (_a = contactTypes.find(ct => ct.id === value)) === null || _a === void 0 ? void 0 : _a.typeName) !== null && _b !== void 0 ? _b : contactTypes[0].typeName); }, variant: "outlined" },
                                        React.createElement(MenuItem, { key: "null", value: "" }, "\u00A0"),
                                        contactTypes.map(({ id, typeName }) => (React.createElement(MenuItem, { key: id, value: id }, typeName))))),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoBusinessPhoneInput", "data-qa-id": "contactInfoBusinessPhoneLabel" }, "Business Phone"),
                                    React.createElement(TextField, { id: "contactInfoBusinessPhoneInput", "data-qa-id": "contactInfoBusinessPhoneInput", fullWidth: true, variant: "outlined", value: state.newContact ? state.newContact.businessPhone : (_h = state.existingContact) === null || _h === void 0 ? void 0 : _h.businessPhone, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('businessPhone', value), inputProps: {
                                            maxLength: 255,
                                        } })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoMobilePhoneInput", "data-qa-id": "contactInfoMobilePhoneLabel" }, "Mobile Phone"),
                                    React.createElement(TextField, { id: "contactInfoMobilePhoneInput", "data-qa-id": "contactInfoMobilePhoneInput", fullWidth: true, variant: "outlined", value: state.newContact ? state.newContact.mobilePhone : (_j = state.existingContact) === null || _j === void 0 ? void 0 : _j.mobilePhone, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('mobilePhone', value), inputProps: {
                                            maxLength: 255,
                                        } }))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "contactInfoSalesContactTeamInput", "data-qa-id": "contactInfoSalesContactTeamLabel" }, "Sales Contact Team"),
                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "contactInfoSalesContactTeamInput", id: "contactInfoSalesContactTeamInput", value: state.newContact ? state.newContact.salesContactTeam : (_k = state.existingContact) === null || _k === void 0 ? void 0 : _k.salesContactTeam, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => {
                                            var _a, _b;
                                            updateField('salesContactTeam', value);
                                            updateField('salesContactTeamValue', (_b = (_a = salesContactTeams.find(sct => sct.id === value)) === null || _a === void 0 ? void 0 : _a.areaCode) !== null && _b !== void 0 ? _b : '');
                                        }, variant: "outlined" },
                                        React.createElement(MenuItem, { key: "null", value: "" }, "\u00A0"),
                                        salesContactTeams.map(({ id, areaCode }) => (React.createElement(MenuItem, { key: id, value: id }, areaCode)))))))))),
            React.createElement("div", { className: modalStyles.modalActions },
                React.createElement(DialogActions, null,
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "contactInfoCloseButton", onClick: onClose, variant: "outlined" }, "Cancel")),
                        React.createElement(Grid, { container: true, item: true, xs: 3, justify: "flex-end" },
                            React.createElement(Button, { type: "button", "data-qa-id": "contactInfoAssociateAllButton", fullWidth: true, onClick: (event) => handleSubmit(event, true), color: "primary", variant: "outlined", disabled: disableButtons }, "Associate to all")),
                        React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(Button, { type: "submit", "data-qa-id": "contactInfoSubmitButton", fullWidth: true, color: "primary", variant: "contained", disabled: disableButtons }, "Associate to current"))))))));
}
