import { combineReducers } from 'redux';
import { apiReducer } from './apiReducer';
import { inactiveCalculationsReducer } from './inactiveCalculationsReducer';
import { calculationReducer } from './calculationReducer';
import { masterDataReducer } from './masterDataReducer';
import { pdfReducer } from './pdfReducer';
import { userProfileReducer } from './userProfileReducer';
export const reducers = combineReducers({
    api: apiReducer,
    inactiveCalculations: inactiveCalculationsReducer,
    calculation: calculationReducer,
    masterData: masterDataReducer,
    pdf: pdfReducer,
    userProfile: userProfileReducer,
});
