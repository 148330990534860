import React from 'react';
import { useDispatch } from 'react-redux';
import * as ReactDnd from 'react-dnd';
import { MTableBodyRow } from 'material-table';
import { moveLayerInCalculation } from '../../../actions/calculationActions';
import styles from '../LayersTable.module.scss';
export function LayersTableRow({ disableDrag, renderProps, onLayerClick }) {
    const dispatch = useDispatch();
    const isInsideSurface = renderProps.data.name === 'Inside Surface';
    const isOutsideSurface = renderProps.data.name === 'Outside Surface';
    const isReadOnly = renderProps.data.isReadOnly;
    const [{ isDraggingThisRow }, drag] = ReactDnd.useDrag({
        item: { type: 'ROW', dragLayerInstanceId: renderProps.data.instanceId },
        collect: monitor => ({
            isDraggingThisRow: monitor.isDragging(),
        }),
    });
    const [{ isHovered }, drop] = ReactDnd.useDrop({
        accept: 'ROW',
        drop: (item) => {
            dispatch(moveLayerInCalculation(item.dragLayerInstanceId, renderProps.data.instanceId));
        },
        collect: monitor => ({
            isHovered: monitor.isOver(),
        }),
    });
    return (React.createElement("div", { className: `${styles.dragDropContainer} ${styles.dragDropContainerDragging}` },
        !isInsideSurface && (React.createElement("tr", { ref: drop, "data-qa-id": "layersTableDropZone", className: `${styles.tableRowDropZone} ${isHovered ? styles.tableRowDropZoneHovered : ''}` },
            React.createElement("td", { colSpan: 9999 }))),
        React.createElement("tbody", Object.assign({ "data-qa-id": "layersTableRow", className: `${styles.tableRowContainer} ${isDraggingThisRow ? styles.tableRowContainerDragging : ''}` }, (isInsideSurface || isOutsideSurface
            ? {}
            : isReadOnly
                ? { ref: disableDrag ? null : drag }
                : { onClick: () => onLayerClick(renderProps.data.instanceId), ref: disableDrag ? null : drag })),
            React.createElement(MTableBodyRow, Object.assign({}, renderProps)))));
}
