import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App/App';
import { setAppConfig } from './config/config';
(async function () {
    try {
        const configData = await fetch(`${process.env.PUBLIC_URL}/config.json`);
        const appConfig = (await configData.json());
        setAppConfig(appConfig);
        ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
    }
    catch (e) {
        console.error('App config could not be loaded');
        console.error(e);
    }
})();
