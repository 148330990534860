import { createSelector } from 'reselect';
export function interimOrCurrentCalculationSelector(state) {
    var _a;
    return (_a = state.calculation.interimCalculation) !== null && _a !== void 0 ? _a : state.calculation.currentCalculation;
}
export function currentCalculationBusinessUnitIdSelector(state) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.calculation) === null || _a === void 0 ? void 0 : _a.currentCalculation) === null || _b === void 0 ? void 0 : _b.businessUnitId) !== null && _c !== void 0 ? _c : null;
}
export function inactiveCalculationsByBusinessUnitSelector(state) {
    const currentBusinessUnitId = currentCalculationBusinessUnitIdSelector(state);
    return state.inactiveCalculations.filter(ic => ic.businessUnitId === currentBusinessUnitId);
}
export function inactiveCalculationsSelector(state) {
    return state.inactiveCalculations;
}
export function masterDataSelector(state) {
    return state.masterData;
}
export function isLoadingMasterDataSelector(state) {
    return state.masterData.isLoadingMasterData === true;
}
export function floorMasterLayerSelector(state) {
    return state.masterData.floorLayers;
}
export function applicationTypeSelector(state) {
    return state.masterData.applications;
}
export function soilTypeSelector(state) {
    return state.masterData.soilTypes;
}
export function buildingRegionSelector(state) {
    return state.masterData.buildingRegions;
}
export function buildingTypeSelector(state) {
    return state.masterData.buildingTypes;
}
export function riskLevelSelector(state) {
    return state.masterData.riskLevels;
}
export function rainscreenCladdingDefaultsSelector(state) {
    return state.masterData.rainscreenCladdingDefaults;
}
export function drainageFxSelector(state) {
    return state.masterData.drainageFx;
}
export function precipitationByPostCodeAreaSelector(state) {
    return state.masterData.precipitationByPostCodeArea;
}
export function buildingUsagesSelector(state) {
    return state.masterData.buildingUsage;
}
export function projectRegionsSelector(state) {
    return state.masterData.projectRegion;
}
export function roofCharacteristicsOptionsSelector(state) {
    return state.masterData.roofCharacteristicsOptions;
}
export function envConditionsSelector(state) {
    var _a, _b, _c;
    return (_b = (_a = state.calculation.interimCalculation) === null || _a === void 0 ? void 0 : _a.envConditions) !== null && _b !== void 0 ? _b : (_c = state.calculation.currentCalculation) === null || _c === void 0 ? void 0 : _c.envConditions;
}
export function envSettingsSelector(state) {
    var _a, _b, _c;
    return (_b = (_a = state.calculation.interimCalculation) === null || _a === void 0 ? void 0 : _a.envSettings) !== null && _b !== void 0 ? _b : (_c = state.calculation.currentCalculation) === null || _c === void 0 ? void 0 : _c.envSettings;
}
export function interstitialInterfaceSelector(state) {
    var _a, _b, _c, _d;
    return (_d = (_b = (_a = state.calculation.interimCalculation) === null || _a === void 0 ? void 0 : _a.interstitialInterfaces) !== null && _b !== void 0 ? _b : (_c = state.calculation.currentCalculation) === null || _c === void 0 ? void 0 : _c.interstitialInterfaces) !== null && _d !== void 0 ? _d : [];
}
export function masterDataBradingSelector(state) {
    return state.masterData.branding;
}
export function pdfExportsSelector(state) {
    return state.pdf.exports;
}
export function pdfPendingDownloadsSelector(state) {
    return state.pdf.pendingDownloads;
}
export function apiSelector(state) {
    return state.api;
}
export function productSectorSelector(state) {
    return state.masterData.productSectors;
}
export function salesContactTeamSelector(state) {
    return state.masterData.salesContactTeams;
}
export function projectCountyStateSelector(state) {
    return state.masterData.projectCountyStates;
}
export function projectStageSelector(state) {
    return state.masterData.projectStages;
}
export function calculationTitleSelector(state) {
    return state.masterData.calculationTitles;
}
export function currentBuildingRegionNameSelector(state) {
    var _a;
    var envSettings = envSettingsSelector(state);
    if (!envSettings ||
        !envSettings.buildingRegionId ||
        !envSettings.buildingTypeId ||
        !envSettings.internalTemperature ||
        !envSettings.riskLevelId) {
        return undefined;
    }
    return (_a = state.masterData.buildingRegions.filter(br => { var _a; return br.id === ((_a = envSettingsSelector(state)) === null || _a === void 0 ? void 0 : _a.buildingRegionId); })[0]) === null || _a === void 0 ? void 0 : _a.name;
}
export function saveSuccessSelector(state) {
    return state.calculation.savedSuccessfully;
}
export function userProfileSelector(state) {
    return state.userProfile;
}
export const currentCalculationBusinessUnitSelector = createSelector([userProfileSelector, currentCalculationBusinessUnitIdSelector], (userProfile, currentCalculationBusinessUnitId) => {
    var _a, _b;
    return (_b = ((_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.useableBusinessUnits) !== null && _a !== void 0 ? _a : []).find(bu => bu.businessUnitId === currentCalculationBusinessUnitId)) !== null && _b !== void 0 ? _b : null;
});
