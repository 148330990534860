import { cloneDeep } from 'lodash';
import { actionTypes } from './actionTypes';
import { AirGapsCorrectionLevel } from '../types/store/masterDataTypes';
import { EnvConditionType, } from '../types/store/calculationTypes';
import { LayerMaterialTypeKeys } from '../types/store/LayerMaterialTypes';
import { uuid } from '../common/uuid';
import { callApi } from '../common/api';
import { associateProject } from './projectActions';
import { associateContact } from './contactActions';
export async function requestCalculation(dispatch, method, endpoint, requestData) {
    if (requestData.saveCalculation) {
        dispatch({ type: actionTypes.CLEAR_CALCULATION_SAVE_SUCCESS });
    }
    const data = await callApi(dispatch, method, endpoint, requestData);
    if (data && requestData.saveCalculation) {
        dispatch({ type: actionTypes.SET_CALCULATION_SAVE_SUCCESS });
    }
    return data;
}
export function mapCurrentResponseLayerToRequest(responseLayer) {
    const { id, instanceId, thicknessMillimetres, customReferenceLayer, layerBridging, mechanicalFastener, airGapsCorrection, vapourResistance, vapourResistivity, layerNotes, isReadOnly, primaryGroup, secondaryGroup, sector, application, budgetCategory, productTechnology, productBrand, } = responseLayer;
    if (isReadOnly) {
        return {
            isReadOnly: true,
            id: null,
            instanceId,
            thicknessMillimetres: null,
        };
    }
    return Object.assign(Object.assign({ id,
        instanceId,
        isReadOnly, thicknessMillimetres: thicknessMillimetres || null, customReferenceLayer: customReferenceLayer != null
            ? {
                name: customReferenceLayer.name,
                thermalResistance: customReferenceLayer.thermalResistance,
                thermalConductivity: customReferenceLayer.thermalConductivity,
                insideEmissivity: customReferenceLayer.insideEmissivity,
                outsideEmissivity: customReferenceLayer.outsideEmissivity,
                layerMaterialType: customReferenceLayer.layerMaterialType,
                isBlank: customReferenceLayer.isBlank,
                isCustom: true,
            }
            : undefined, layerNotes: layerNotes != null
            ? {
                notes: layerNotes.notes,
            }
            : undefined, layerBridging: layerBridging != null
            ? {
                id: layerBridging.id,
                bridgeWidthMillimetres: layerBridging.bridgeWidthMillimetres,
                centresDistanceMillimetres: layerBridging.centresDistanceMillimetres,
                nonBridgeHeightMillimetres: layerBridging.nonBridgeHeightMillimetres,
            }
            : undefined, mechanicalFastener: mechanicalFastener != null
            ? {
                id: mechanicalFastener.id,
                crossSectionalAreaMillimetresSquared: mechanicalFastener.crossSectionalAreaMillimetresSquared,
                fasteningsPerMetreSquared: mechanicalFastener.fasteningsPerMetreSquared,
            }
            : undefined }, ((airGapsCorrection === null || airGapsCorrection === void 0 ? void 0 : airGapsCorrection.calculatedCorrectionFactor) && (airGapsCorrection === null || airGapsCorrection === void 0 ? void 0 : airGapsCorrection.overrideCorrectionFactor)
        ? {
            airGapsCorrectionOverride: {
                overriddenCalculatedCorrectionFactor: airGapsCorrection === null || airGapsCorrection === void 0 ? void 0 : airGapsCorrection.calculatedCorrectionFactor,
                overrideCorrectionFactor: airGapsCorrection === null || airGapsCorrection === void 0 ? void 0 : airGapsCorrection.overrideCorrectionFactor,
            },
        }
        : {})), { vapourResistance,
        vapourResistivity,
        primaryGroup,
        secondaryGroup,
        sector,
        application,
        budgetCategory,
        productTechnology,
        productBrand });
}
export const openCalculation = (calculationId) => {
    return async (dispatch, getState) => {
        await Promise.resolve(); // Give the UI time to update, before doing the expensive `cloneDeep`
        const previousActiveCalculation = cloneDeep(getState().calculation.currentCalculation);
        const data = await callApi(dispatch, 'GET', `/Calculation/${calculationId}`);
        dispatch({
            type: actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE,
            payload: {
                previousActiveCalculation,
            },
        });
        const inactiveCalculations = getState().inactiveCalculations;
        dispatch({
            type: actionTypes.SET_ACTIVE_CALCULATION_DATA,
            payload: {
                newCalculationData: data,
                shouldUseExistingCalculationId: true,
                order: inactiveCalculations.length + 1,
            },
        });
    };
};
export const registerCalculation = (businessUnitId) => {
    return async (dispatch, getState) => {
        const wallApplicationTypeUniqueReferenceId = 1; // This refers to the unique reference ID of the Application Type. These are hardcoded in the Calculation Engine. 1 means Wall.
        const requestData = {
            businessUnitId,
            applicationDetails: {
                id: wallApplicationTypeUniqueReferenceId,
            },
            layers: [],
            saveCalculation: true,
        };
        const data = await requestCalculation(dispatch, 'POST', '/Calculation', requestData);
        if (!data)
            return;
        const previousActiveCalculation = cloneDeep(getState().calculation.currentCalculation);
        dispatch({
            type: actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE,
            payload: {
                previousActiveCalculation,
            },
        });
        await Promise.resolve(); // Yield between dispatches
        // Ensure new calculations are always last in the tab list
        const order = getState().inactiveCalculations.length + 1;
        dispatch({
            type: actionTypes.REGISTER_CALCULATION,
            payload: {
                calculation: data,
                order,
            },
        });
    };
};
export const closeActiveCalculation = () => {
    return async (dispatch, getState) => {
        const previousActiveCalculation = cloneDeep(getState().calculation.currentCalculation);
        const inactiveCalculations = cloneDeep(getState().inactiveCalculations);
        // Re-evaluate all "order" values to prevent there being gaps
        const newInactiveCalculations = [...inactiveCalculations]
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((c, index) => (Object.assign(Object.assign({}, c), { order: index + 1 })));
        let newActiveCalculation;
        if (newInactiveCalculations.length > 0) {
            // Move to the next tab if one exists, otherwise go to previous tab
            newActiveCalculation =
                newInactiveCalculations.find(c => c.order === previousActiveCalculation.order) ||
                    newInactiveCalculations.find(c => c.order === previousActiveCalculation.order - 1);
            // Remove newly-active calculation from the inactive calculations array
            const indexOfCalculationToRemove = newInactiveCalculations.findIndex(c => c.calculationId === (newActiveCalculation === null || newActiveCalculation === void 0 ? void 0 : newActiveCalculation.calculationId));
            newInactiveCalculations.splice(indexOfCalculationToRemove, 1);
        }
        dispatch({
            type: actionTypes.CLOSE_ACTIVE_CALCULATION,
            payload: {
                newActiveCalculation,
                newInactiveCalculations,
            },
        });
    };
};
export const handleAutomaticLayers = (currentCalculation, request) => {
    var _a, _b, _c, _d;
    const currentPitchedRoofDetails = (_a = currentCalculation.constructionDetails) === null || _a === void 0 ? void 0 : _a.pitchedRoofWithLoftDetails;
    const requestPitchedRoofDetailsDetails = (_b = request.constructionDetails) === null || _b === void 0 ? void 0 : _b.pitchedRoofWithLoftDetails;
    const currentFloorInsulationDetails = (_c = currentCalculation.constructionDetails) === null || _c === void 0 ? void 0 : _c.floorInsulationDetails;
    const requestFloorInsulationDetails = (_d = request.constructionDetails) === null || _d === void 0 ? void 0 : _d.floorInsulationDetails;
    // Adding pitchedRoofWithLoftDetails
    if (currentPitchedRoofDetails == null && requestPitchedRoofDetailsDetails != null) {
        return Object.assign(Object.assign({}, request), { layers: [
                ...request.layers,
                {
                    instanceId: requestPitchedRoofDetailsDetails.loftLayerInstanceId,
                    id: null,
                    thicknessMillimetres: null,
                    isReadOnly: true,
                },
            ] });
    }
    // Removing pitchedRoofWithLoftDetails
    if (currentPitchedRoofDetails != null && requestPitchedRoofDetailsDetails == null) {
        const loftLayerId = currentPitchedRoofDetails.loftLayerInstanceId;
        return Object.assign(Object.assign({}, request), { layers: request.layers.filter(layer => layer.instanceId !== loftLayerId) });
    }
    // Adding floorInsulationDetails
    if ((currentFloorInsulationDetails === null || currentFloorInsulationDetails === void 0 ? void 0 : currentFloorInsulationDetails.insulationLayerInstanceId) == null && (requestFloorInsulationDetails === null || requestFloorInsulationDetails === void 0 ? void 0 : requestFloorInsulationDetails.insulationLayerInstanceId)) {
        return Object.assign(Object.assign({}, request), { layers: [
                ...request.layers,
                {
                    instanceId: requestFloorInsulationDetails.insulationLayerInstanceId,
                    id: null,
                    thicknessMillimetres: null,
                    isReadOnly: true,
                },
            ] });
    }
    // Removing floorInsulationDetails
    if ((currentFloorInsulationDetails === null || currentFloorInsulationDetails === void 0 ? void 0 : currentFloorInsulationDetails.insulationLayerInstanceId) != null && !(requestFloorInsulationDetails === null || requestFloorInsulationDetails === void 0 ? void 0 : requestFloorInsulationDetails.insulationLayerInstanceId)) {
        const insulationLayerId = currentFloorInsulationDetails.insulationLayerInstanceId;
        return Object.assign(Object.assign({}, request), { layers: request.layers.filter(layer => layer.instanceId !== insulationLayerId) });
    }
    return request;
};
export const addConstructionDetailsToCalculation = (applicationTypeId, constructionDetails, rainscreenCladdingDetails, calculationNotes, productSectorId, projectInsulationVolumeM2, caseNumber) => {
    return async (dispatch, getState) => {
        var _a;
        const currentCalculation = getState().calculation.currentCalculation;
        if (!currentCalculation)
            return;
        const requestData = {
            businessUnitId: currentCalculation.businessUnitId,
            applicationDetails: { id: applicationTypeId },
            layers: [...currentCalculation.layers.map(mapCurrentResponseLayerToRequest)],
            calculationNotes,
            constructionDetails,
            rainscreenCladdingDetails,
            envSettings: currentCalculation.envSettings,
            saveCalculation: true,
            project: currentCalculation.project,
            productSectorId: productSectorId,
            projectInsulationVolumeM2: projectInsulationVolumeM2,
            caseNumber: caseNumber,
            contact: (_a = currentCalculation.contact) !== null && _a !== void 0 ? _a : null,
        };
        const requestDataWithLoftDetails = handleAutomaticLayers(currentCalculation, requestData);
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${currentCalculation.calculationId}`, requestDataWithLoftDetails);
        if (!data)
            return;
        const payloadCalculation = Object.assign(Object.assign({}, data), { order: currentCalculation.order, layers: data.layers.map((layer) => (Object.assign({}, layer))) });
        dispatch({
            type: actionTypes.ADD_CONSTRUCTION_DETAILS_TO_CALCULATION,
            payload: {
                calculation: payloadCalculation,
            },
        });
    };
};
export const addLayerToCalculation = (instanceId, layer, thicknessMillimetres, customReferenceLayer, layerBridging, mechanicalFastener, previousAirGapsCorrectionLevelOverride, nextAirGapsCorrectionLevelOverride, layerNotes, vapourResistance, vapourResistivity, primaryGroup, secondaryGroup, sector, application, budgetCategory, productTechnology, productBrand, isCalculationInterim = false) => {
    return async (dispatch, getState) => {
        var _a;
        const currentCalculation = getState().calculation.currentCalculation;
        if (!currentCalculation)
            return;
        const requestData = {
            businessUnitId: currentCalculation.businessUnitId,
            applicationDetails: currentCalculation.applicationDetails,
            calculationNotes: currentCalculation.calculationNotes,
            constructionDetails: currentCalculation.constructionDetails,
            rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
            layers: [
                ...currentCalculation.layers.map(mapCurrentResponseLayerToRequest),
                Object.assign(Object.assign({ id: (customReferenceLayer == null ? layer === null || layer === void 0 ? void 0 : layer.id : null) || null, instanceId: instanceId, thicknessMillimetres: thicknessMillimetres || null, customReferenceLayer,
                    layerNotes,
                    layerBridging,
                    mechanicalFastener }, (nextAirGapsCorrectionLevelOverride &&
                    ((layer === null || layer === void 0 ? void 0 : layer.layerMaterialType) === LayerMaterialTypeKeys.GenericInsulation ||
                        (layer === null || layer === void 0 ? void 0 : layer.layerMaterialType) === LayerMaterialTypeKeys.InvertedInsulation ||
                        (customReferenceLayer === null || customReferenceLayer === void 0 ? void 0 : customReferenceLayer.layerMaterialType) === LayerMaterialTypeKeys.GenericInsulation ||
                        (customReferenceLayer === null || customReferenceLayer === void 0 ? void 0 : customReferenceLayer.layerMaterialType) === LayerMaterialTypeKeys.InvertedInsulation)
                    ? {
                        airGapsCorrectionOverride: {
                            overriddenCalculatedCorrectionFactor: previousAirGapsCorrectionLevelOverride || AirGapsCorrectionLevel.Level0,
                            overrideCorrectionFactor: nextAirGapsCorrectionLevelOverride,
                        },
                    }
                    : {})), { vapourResistance: vapourResistance, vapourResistivity: vapourResistivity, primaryGroup: primaryGroup, secondaryGroup: secondaryGroup, sector: sector, application: application, budgetCategory: budgetCategory, productTechnology: productTechnology, productBrand: productBrand }),
            ],
            envSettings: currentCalculation.envSettings,
            saveCalculation: !isCalculationInterim,
            project: currentCalculation.project,
            productSectorId: currentCalculation.productSectorId,
            projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
            contact: (_a = currentCalculation.contact) !== null && _a !== void 0 ? _a : null,
            caseNumber: currentCalculation.caseNumber,
        };
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${currentCalculation.calculationId}`, requestData);
        if (!data)
            return;
        const payloadCalculation = Object.assign(Object.assign({}, data), { layers: data.layers, project: currentCalculation.project });
        if (isCalculationInterim) {
            dispatch({
                type: actionTypes.ADD_LAYER_TO_INTERIM_CALCULATION,
                payload: {
                    calculation: Object.assign(Object.assign({}, payloadCalculation), { order: currentCalculation.order }),
                },
            });
        }
        else {
            dispatch({
                type: actionTypes.ADD_LAYER_TO_CALCULATION,
                payload: {
                    calculation: Object.assign({ order: currentCalculation.order }, payloadCalculation),
                },
            });
        }
    };
};
export const editLayerInCalculation = (instanceId, layer, thicknessMillimetres, customReferenceLayer, layerBridging, mechanicalFastener, previousAirGapsCorrectionLevelOverride, nextAirGapsCorrectionLevelOverride, layerNotes, vapourResistance, vapourResistivity, primaryGroup, secondaryGroup, sector, application, budgetCategory, productTechnology, productBrand, isCalculationInterim = false) => {
    return async (dispatch, getState) => {
        var _a;
        const currentCalculation = getState().calculation.currentCalculation;
        if (!currentCalculation)
            return;
        const layers = cloneDeep(currentCalculation.layers);
        const index = layers.findIndex((layer) => layer.instanceId === instanceId);
        const nextLayers = [...layers.map(mapCurrentResponseLayerToRequest)];
        nextLayers[index] = Object.assign(Object.assign({ id: (customReferenceLayer == null ? layer === null || layer === void 0 ? void 0 : layer.id : null) || null, instanceId, thicknessMillimetres: thicknessMillimetres || null, layerNotes,
            customReferenceLayer,
            layerBridging,
            mechanicalFastener }, (nextAirGapsCorrectionLevelOverride &&
            ((layer === null || layer === void 0 ? void 0 : layer.layerMaterialType) === LayerMaterialTypeKeys.GenericInsulation ||
                (layer === null || layer === void 0 ? void 0 : layer.layerMaterialType) === LayerMaterialTypeKeys.InvertedInsulation ||
                (customReferenceLayer === null || customReferenceLayer === void 0 ? void 0 : customReferenceLayer.layerMaterialType) === LayerMaterialTypeKeys.GenericInsulation ||
                (customReferenceLayer === null || customReferenceLayer === void 0 ? void 0 : customReferenceLayer.layerMaterialType) === LayerMaterialTypeKeys.InvertedInsulation)
            ? {
                airGapsCorrectionOverride: {
                    overriddenCalculatedCorrectionFactor: previousAirGapsCorrectionLevelOverride || AirGapsCorrectionLevel.Level0,
                    overrideCorrectionFactor: nextAirGapsCorrectionLevelOverride,
                },
            }
            : {})), { vapourResistance: vapourResistance, vapourResistivity: vapourResistivity, primaryGroup: primaryGroup, secondaryGroup: secondaryGroup, sector: sector, application: application, budgetCategory: budgetCategory, productTechnology: productTechnology, productBrand: productBrand });
        const requestData = {
            businessUnitId: currentCalculation.businessUnitId,
            applicationDetails: currentCalculation.applicationDetails,
            calculationNotes: currentCalculation.calculationNotes,
            constructionDetails: currentCalculation.constructionDetails,
            rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
            layers: nextLayers,
            envSettings: currentCalculation.envSettings,
            saveCalculation: !isCalculationInterim,
            project: currentCalculation.project,
            productSectorId: currentCalculation.productSectorId,
            projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
            contact: (_a = currentCalculation.contact) !== null && _a !== void 0 ? _a : null,
            caseNumber: currentCalculation.caseNumber,
        };
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${currentCalculation.calculationId}`, requestData);
        if (!data)
            return;
        const payloadCalculation = Object.assign(Object.assign({}, data), { layers: data.layers.map((layer) => (Object.assign({}, layer))) });
        if (isCalculationInterim) {
            dispatch({
                type: actionTypes.EDIT_LAYER_IN_INTERIM_CALCULATION,
                payload: {
                    calculation: Object.assign(Object.assign({}, payloadCalculation), { order: currentCalculation.order }),
                },
            });
        }
        else {
            dispatch({
                type: actionTypes.EDIT_LAYER_IN_CALCULATION,
                payload: {
                    calculation: Object.assign(Object.assign({}, payloadCalculation), { order: currentCalculation.order }),
                },
            });
        }
    };
};
export const clearInterimCalculation = () => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_INTERIM_CALCULATION,
        });
    };
};
export const moveLayerInCalculation = (movedLayerInstanceId, targetLayerInstanceId) => {
    return async (dispatch, getState) => {
        var _a;
        if (movedLayerInstanceId === targetLayerInstanceId)
            return;
        const { currentCalculation } = getState().calculation;
        if (!currentCalculation)
            return;
        const layers = cloneDeep(currentCalculation.layers);
        const movedLayerIndex = layers.findIndex((layer) => layer.instanceId === movedLayerInstanceId);
        const movedLayer = layers[movedLayerIndex];
        // Remove the moved layer from it's current position
        layers.splice(movedLayerIndex, 1);
        const targetLayerIndex = layers.findIndex((layer) => layer.instanceId === targetLayerInstanceId);
        // Insert the moved layer before the target layer
        if (targetLayerIndex >= 0) {
            layers.splice(targetLayerIndex, 0, movedLayer);
        }
        else {
            /**
             * If we can't find the target layer then the target is the Outside Surface,
             * so move the moved layer to the end of the layers list.
             */
            layers.push(movedLayer);
        }
        /**
         * We will update the layer order locally immediately while we wait for the API to
         * respond to prevent strange-looking behaviour where the moved layer pings back to
         * its original location for the interim period before the API responds.
         */
        dispatch({
            type: actionTypes.INTERIM_MOVE_LAYER_IN_CALCULATION,
            payload: {
                calculation: Object.assign(Object.assign({}, currentCalculation), { layers }),
            },
        });
        const requestData = {
            businessUnitId: currentCalculation.businessUnitId,
            applicationDetails: currentCalculation.applicationDetails,
            calculationNotes: currentCalculation.calculationNotes,
            constructionDetails: currentCalculation.constructionDetails,
            rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
            layers: [...layers.map(mapCurrentResponseLayerToRequest)],
            envSettings: currentCalculation.envSettings,
            saveCalculation: true,
            project: currentCalculation.project,
            productSectorId: currentCalculation.productSectorId,
            projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
            contact: (_a = currentCalculation.contact) !== null && _a !== void 0 ? _a : null,
            caseNumber: currentCalculation.caseNumber,
        };
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${currentCalculation.calculationId}`, requestData);
        if (!data)
            return;
        dispatch({
            type: actionTypes.MOVE_LAYER_IN_CALCULATION,
            payload: {
                calculation: Object.assign(Object.assign({}, data), { order: currentCalculation.order }),
            },
        });
    };
};
export const duplicateLayerInCalculation = (instanceId) => {
    return async (dispatch, getState) => {
        var _a;
        const currentCalculation = getState().calculation.currentCalculation;
        if (!currentCalculation)
            return;
        const layers = cloneDeep(currentCalculation.layers);
        const index = layers.findIndex((layer) => layer.instanceId === instanceId);
        const layer = layers.find((layer) => layer.instanceId === instanceId);
        const clonedLayer = Object.assign(Object.assign({}, cloneDeep(layer)), { instanceId: uuid() });
        const isMissingHirerachyData = (layer) => {
            if (layer.primaryGroup ||
                layer.secondaryGroup ||
                layer.sector ||
                layer.application ||
                layer.budgetCategory ||
                layer.productTechnology ||
                layer.productBrand) {
                return false;
            }
            return true;
        };
        const lookupMasterLayerDataById = (id) => {
            try {
                return getState().masterData.layers.find(layer => layer.id === id);
            }
            catch (_a) {
                return undefined;
            }
        };
        const getLayerCopy = (layer) => {
            if (isMissingHirerachyData(layer) && layer.id) {
                const masterLayer = lookupMasterLayerDataById(layer.id);
                if (masterLayer) {
                    layer.primaryGroup = masterLayer.primaryGroup;
                    layer.secondaryGroup = masterLayer.secondaryGroup;
                    layer.sector = masterLayer.sector;
                    layer.application = masterLayer.application;
                    layer.budgetCategory = masterLayer.budgetCategory;
                    layer.productTechnology = masterLayer.productTechnology;
                    layer.productBrand = masterLayer.productBrand;
                }
            }
            return layer;
        };
        if (index >= 0) {
            // Insert new layer after selected one
            layers.splice(index + 1, 0, clonedLayer);
        }
        const getRequestLayerData = (layers) => {
            var layersWithHierarchyData = layers.map(getLayerCopy);
            return layersWithHierarchyData.map(mapCurrentResponseLayerToRequest);
        };
        const requestData = {
            businessUnitId: currentCalculation.businessUnitId,
            applicationDetails: currentCalculation.applicationDetails,
            calculationNotes: currentCalculation.calculationNotes,
            constructionDetails: currentCalculation.constructionDetails,
            rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
            layers: getRequestLayerData(layers),
            envSettings: currentCalculation.envSettings,
            saveCalculation: true,
            project: currentCalculation.project,
            productSectorId: currentCalculation.productSectorId,
            projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
            contact: (_a = currentCalculation.contact) !== null && _a !== void 0 ? _a : null,
            caseNumber: currentCalculation.caseNumber,
        };
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${currentCalculation.calculationId}`, requestData);
        if (!data)
            return;
        dispatch({
            type: actionTypes.DUPLICATE_LAYER_IN_CALCULATION,
            payload: {
                calculation: Object.assign(Object.assign({}, data), { order: currentCalculation.order, layers: data.layers.map((layer) => getLayerCopy(layer)) }),
            },
        });
    };
};
export const lockCalculation = (calculationId) => {
    return async (dispatch, getState) => {
        var _a;
        let isCurrentCalculation = true;
        let calculation = getState().calculation.currentCalculation;
        if (calculationId !== (calculation === null || calculation === void 0 ? void 0 : calculation.calculationId)) {
            calculation = cloneDeep(getState().inactiveCalculations.find(c => c.calculationId === calculationId));
            isCurrentCalculation = false;
        }
        if (!calculation)
            return;
        const requestData = {
            businessUnitId: calculation.businessUnitId,
            applicationDetails: calculation.applicationDetails,
            calculationNotes: calculation.calculationNotes,
            constructionDetails: calculation.constructionDetails,
            rainscreenCladdingDetails: calculation.rainscreenCladdingDetails,
            layers: calculation.layers.map(mapCurrentResponseLayerToRequest),
            envSettings: calculation.envSettings,
            saveCalculation: true,
            project: calculation.project,
            locked: true,
            productSectorId: calculation.productSectorId,
            projectInsulationVolumeM2: calculation.projectInsulationVolumeM2,
            contact: (_a = calculation.contact) !== null && _a !== void 0 ? _a : null,
            caseNumber: calculation.caseNumber,
        };
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${calculationId}`, requestData);
        if (!data)
            return;
        dispatch({
            type: actionTypes.LOCK_CALCULATION,
            payload: {
                isCurrentCalculation,
                calculation: data,
            },
        });
    };
};
export const removeLayerFromCalculation = (instanceId) => {
    return async (dispatch, getState) => {
        var _a;
        const currentCalculation = getState().calculation.currentCalculation;
        if (!currentCalculation)
            return;
        const layers = cloneDeep(currentCalculation.layers);
        const index = layers.findIndex((layer) => layer.instanceId === instanceId);
        if (index >= 0) {
            layers.splice(index, 1);
        }
        const requestData = {
            businessUnitId: currentCalculation.businessUnitId,
            applicationDetails: currentCalculation.applicationDetails,
            calculationNotes: currentCalculation.calculationNotes,
            constructionDetails: currentCalculation.constructionDetails,
            rainscreenCladdingDetails: currentCalculation.rainscreenCladdingDetails,
            layers: layers.map(mapCurrentResponseLayerToRequest),
            envSettings: currentCalculation.envSettings,
            saveCalculation: true,
            project: currentCalculation.project,
            productSectorId: currentCalculation.productSectorId,
            projectInsulationVolumeM2: currentCalculation.projectInsulationVolumeM2,
            contact: (_a = currentCalculation.contact) !== null && _a !== void 0 ? _a : null,
            caseNumber: currentCalculation.caseNumber,
        };
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${currentCalculation.calculationId}`, requestData);
        if (!data)
            return;
        dispatch({
            type: actionTypes.REMOVE_LAYER_FROM_CALCULATION,
            payload: {
                calculation: Object.assign(Object.assign({}, data), { order: currentCalculation.order, layers: data.layers.map((layer) => (Object.assign({}, layer))) }),
            },
        });
    };
};
export const changeActiveCalculation = (calculationId) => {
    return async (dispatch, getState) => {
        const previousActiveCalculation = cloneDeep(getState().calculation.currentCalculation);
        const newActiveCalculation = cloneDeep(getState().inactiveCalculations.find(c => c.calculationId === calculationId));
        dispatch({
            type: actionTypes.CHANGE_ACTIVE_CALCULATION,
            payload: {
                previousActiveCalculation,
                newActiveCalculation,
            },
        });
    };
};
export const createNewCalculation = (businessUnitId) => {
    return async (dispatch) => {
        await dispatch(registerCalculation(businessUnitId));
    };
};
export const copyCalculation = () => {
    return async (dispatch, getState) => {
        var _a;
        const currentState = getState();
        const previousActiveCalculation = cloneDeep(currentState.calculation.currentCalculation);
        const { inactiveCalculations } = currentState;
        await dispatch(registerCalculation(previousActiveCalculation.businessUnitId));
        await dispatch({
            type: actionTypes.SET_ACTIVE_CALCULATION_DATA,
            payload: {
                newCalculationData: Object.assign(Object.assign({}, previousActiveCalculation), { locked: false }),
                shouldUseExistingCalculationId: false,
                /**
                 * To put the copied calculation at the end of the list, we want the order to be 1 greater
                 * than the combined length of all open calculations (including the active one).
                 */
                order: inactiveCalculations.length + 2,
            },
        });
        const newActiveCalculation = cloneDeep(getState().calculation.currentCalculation);
        await requestCalculation(dispatch, 'PUT', `/Calculation/${newActiveCalculation.calculationId}`, {
            businessUnitId: newActiveCalculation.businessUnitId,
            applicationDetails: newActiveCalculation.applicationDetails,
            calculationNotes: newActiveCalculation.calculationNotes,
            constructionDetails: newActiveCalculation.constructionDetails,
            rainscreenCladdingDetails: newActiveCalculation.rainscreenCladdingDetails,
            layers: [...newActiveCalculation.layers.map(mapCurrentResponseLayerToRequest)],
            envSettings: newActiveCalculation.envSettings,
            saveCalculation: true,
            locked: false,
            project: newActiveCalculation.project,
            contact: (_a = newActiveCalculation.contact) !== null && _a !== void 0 ? _a : null,
        });
        /**
         * It may look like the new calculation has copied over the project correctly by this
         * point, but we need to make an association in the database for this to persist when
         * the calculation is closed and re-opened.
         */
        if (newActiveCalculation.project) {
            await dispatch(associateProject(newActiveCalculation.project, false, false));
        }
        if (newActiveCalculation.contact) {
            await dispatch(associateContact(newActiveCalculation.contact, false, false));
        }
    };
};
export const addEnvConditionOverrides = (envConditionOverrides) => {
    return async (dispatch, getState) => {
        var _a;
        const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);
        if (!interimCalculation)
            return;
        const mergedOverrides = cloneDeep([...(((_a = interimCalculation.envSettings) === null || _a === void 0 ? void 0 : _a.envConditionOverrides) || [])]);
        envConditionOverrides.forEach(o => {
            const existingOverride = mergedOverrides.find(m => m.envConditionType === o.envConditionType);
            if (existingOverride) {
                o.months.forEach(om => {
                    const existingMonth = existingOverride.months.find(em => em.id === om.id);
                    if (existingMonth) {
                        existingMonth.value = om.value;
                    }
                    else {
                        existingOverride.months.push(Object.assign({}, om));
                    }
                });
            }
            else {
                mergedOverrides.push(Object.assign(Object.assign({}, o), { months: [...o.months] }));
            }
        });
        const updatedEnvSettings = Object.assign(Object.assign({}, interimCalculation.envSettings), { envConditionOverrides: mergedOverrides });
        await editEnvSettings(dispatch, getState, updatedEnvSettings);
    };
};
export const clearEnvConditionOverrides = () => {
    return async (dispatch, getState) => {
        const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);
        if (!interimCalculation)
            return;
        const updatedEnvSettings = Object.assign(Object.assign({}, interimCalculation.envSettings), { envConditionOverrides: [] });
        await editEnvSettings(dispatch, getState, updatedEnvSettings);
    };
};
export const setBuildingRegion = (buildingRegionId) => {
    return async (dispatch, getState) => {
        var _a;
        const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);
        if (!interimCalculation)
            return;
        const updatedOverrides = [...(((_a = interimCalculation.envSettings) === null || _a === void 0 ? void 0 : _a.envConditionOverrides) || [])].filter(o => o.envConditionType !== EnvConditionType.ExternalRelativeHumidity &&
            o.envConditionType !== EnvConditionType.ExternalTemperature &&
            o.envConditionType !== EnvConditionType.InternalRelativeHumidity);
        const updatedEnvSettings = Object.assign(Object.assign({}, interimCalculation.envSettings), { buildingRegionId: buildingRegionId, envConditionOverrides: updatedOverrides });
        await editEnvSettings(dispatch, getState, updatedEnvSettings);
    };
};
export const setBuildingType = (buildingTypeId) => {
    return async (dispatch, getState) => {
        var _a;
        const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);
        if (!interimCalculation)
            return;
        const updatedOverrides = [...(((_a = interimCalculation.envSettings) === null || _a === void 0 ? void 0 : _a.envConditionOverrides) || [])].filter(o => o.envConditionType !== EnvConditionType.InternalRelativeHumidity);
        const updatedEnvSettings = Object.assign(Object.assign({}, interimCalculation.envSettings), { buildingTypeId: buildingTypeId, envConditionOverrides: updatedOverrides });
        await editEnvSettings(dispatch, getState, updatedEnvSettings);
    };
};
export const setRiskLevel = (riskLevelId) => {
    return async (dispatch, getState) => {
        var _a;
        const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);
        if (!interimCalculation)
            return;
        const updatedOverrides = [...(((_a = interimCalculation.envSettings) === null || _a === void 0 ? void 0 : _a.envConditionOverrides) || [])].filter(o => o.envConditionType !== EnvConditionType.ExternalRelativeHumidity &&
            o.envConditionType !== EnvConditionType.ExternalTemperature &&
            o.envConditionType !== EnvConditionType.InternalRelativeHumidity);
        const updatedEnvSettings = Object.assign(Object.assign({}, interimCalculation.envSettings), { riskLevelId: riskLevelId, envConditionOverrides: updatedOverrides });
        await editEnvSettings(dispatch, getState, updatedEnvSettings);
    };
};
export const setInternalTemperature = (internalTemperature) => {
    return async (dispatch, getState) => {
        var _a;
        const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);
        if (!interimCalculation)
            return;
        const updatedOverrides = [...(((_a = interimCalculation.envSettings) === null || _a === void 0 ? void 0 : _a.envConditionOverrides) || [])].filter(o => o.envConditionType !== EnvConditionType.InternalRelativeHumidity && o.envConditionType !== EnvConditionType.InternalTemperature);
        const updatedEnvSettings = Object.assign(Object.assign({}, interimCalculation.envSettings), { internalTemperature: internalTemperature, envConditionOverrides: updatedOverrides });
        await editEnvSettings(dispatch, getState, updatedEnvSettings);
    };
};
const editEnvSettings = async (dispatch, getState, updatedEnvSettings) => {
    var _a;
    const interimCalculation = getState().calculation.interimCalculation || cloneDeep(getState().calculation.currentCalculation);
    if (!interimCalculation)
        return;
    dispatch({
        type: actionTypes.EDIT_ENV_SETTINGS,
        payload: {
            calculation: Object.assign(Object.assign({}, interimCalculation), { envSettings: Object.assign({}, updatedEnvSettings) }),
        },
    });
    if (updatedEnvSettings.buildingRegionId &&
        updatedEnvSettings.buildingTypeId &&
        updatedEnvSettings.riskLevelId &&
        updatedEnvSettings.internalTemperature &&
        !isNaN(Number(updatedEnvSettings.internalTemperature))) {
        const requestData = {
            businessUnitId: interimCalculation.businessUnitId,
            applicationDetails: interimCalculation.applicationDetails,
            calculationNotes: interimCalculation.calculationNotes,
            constructionDetails: interimCalculation.constructionDetails,
            rainscreenCladdingDetails: interimCalculation.rainscreenCladdingDetails,
            envSettings: updatedEnvSettings,
            layers: interimCalculation.layers.map(mapCurrentResponseLayerToRequest),
            saveCalculation: false,
            project: interimCalculation.project,
            productSectorId: interimCalculation.productSectorId,
            projectInsulationVolumeM2: interimCalculation.projectInsulationVolumeM2,
            contact: (_a = interimCalculation.contact) !== null && _a !== void 0 ? _a : null,
            caseNumber: interimCalculation.caseNumber,
        };
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${interimCalculation.calculationId}`, requestData);
        if (!data)
            return;
        dispatch({
            type: actionTypes.EDIT_ENV_CONDITIONS,
            payload: {
                calculation: Object.assign(Object.assign({}, data), { order: interimCalculation.order }),
            },
        });
    }
};
export const saveEnvConditions = () => {
    return async (dispatch, getState) => {
        var _a;
        const interimCalculation = getState().calculation.interimCalculation;
        if (!interimCalculation)
            return;
        const requestData = Object.assign(Object.assign({}, interimCalculation), { layers: interimCalculation.layers.map(mapCurrentResponseLayerToRequest), saveCalculation: true, contact: (_a = interimCalculation.contact) !== null && _a !== void 0 ? _a : null });
        const data = await requestCalculation(dispatch, 'PUT', `/Calculation/${interimCalculation.calculationId}`, requestData);
        if (!data)
            return;
        dispatch({
            type: actionTypes.SAVE_ENV_CONDITIONS,
            payload: {
                calculation: Object.assign(Object.assign({}, data), { order: interimCalculation.order }),
            },
        });
    };
};
