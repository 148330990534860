import { callApi } from "../common/api";
import { actionTypes } from "./actionTypes";
export const associateContact = (contact, isNewContact, shouldAssociateToAll) => {
    return async (dispatch, getState) => {
        const { calculation: { currentCalculation }, inactiveCalculations, } = getState();
        const calculationIds = shouldAssociateToAll
            ? [currentCalculation === null || currentCalculation === void 0 ? void 0 : currentCalculation.calculationId, ...inactiveCalculations.map(c => c.calculationId)]
            : [currentCalculation === null || currentCalculation === void 0 ? void 0 : currentCalculation.calculationId];
        const apiMethod = isNewContact ? 'POST' : 'PUT';
        const apiEndpoint = isNewContact ? '/Contact' : `/Contact/${contact.id}`;
        const data = await callApi(dispatch, apiMethod, apiEndpoint, Object.assign(Object.assign({ calculationIds }, contact), { contactType: contact.contactType || undefined, salesContactTeam: contact.salesContactTeam || undefined }));
        if (!(data === null || data === void 0 ? void 0 : data.id))
            return;
        /**
         * Use "data" rather than the contact param as the data from the API will
         * contain the generated unique ID.
         */
        dispatch({
            type: actionTypes.UPDATE_CALCULATION_CONTACT,
            payload: { contact: data },
        });
        if (!shouldAssociateToAll)
            return;
        dispatch({
            type: actionTypes.UPDATE_INACTIVE_CALCULATIONS_CONTACT,
            payload: { contact: data },
        });
    };
};
