import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import styles from './LayerModal.module.scss';
import { BridgingTab } from './BridgingTab';
import { FastenersTab } from './FastenersTab';
import { AirGapsTab } from './AirGapsTab';
import { VapourTab } from './VapourTab';
import { layerNotesTabIndex, airGapsTabIndex, bridgingTabIndex, fastenersTabIndex, vapourTabIndex } from './LayerModal';
import { LayerNotesTab } from './LayerNotesTab';
import { LayerMaterialTypeKeys } from '../../types/store/LayerMaterialTypes';
import { Chat } from '@material-ui/icons';
export function LayerDetailsTabs(props) {
    const { referenceLayer } = props;
    const handleTabChange = (_event, newTabIndex) => {
        props.setTabIndex(newTabIndex);
    };
    function renderNoteTabLabel() {
        return (React.createElement("span", { "data-qa-id": "notesTabLabel" },
            "Notes",
            React.createElement("span", { className: styles.layerNotesTabLabelIcon },
                React.createElement(Chat, { classes: {
                        root: styles.layerNotesTabLabelIcon,
                    }, "data-qa-id": "notesTabLabelIcon", fontSize: 'small' }))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { className: styles.tabs, value: props.tabIndex, onChange: handleTabChange, indicatorColor: "primary", textColor: "primary", "aria-label": "layer-details-tabs", "data-qa-id": "layer-details-tabs" },
            React.createElement(Tab, { disableRipple: true, disabled: (referenceLayer === null || referenceLayer === void 0 ? void 0 : referenceLayer.layerMaterialType) === LayerMaterialTypeKeys.WellVentilatedAirspace, className: styles.tab, label: "Thermal Bridging", id: "layer-bridging-details-tab", "aria-controls": "layer-bridging-details-panel", "data-qa-id": "layer-bridging-details-tab" }),
            React.createElement(Tab, { disableRipple: true, disabled: (referenceLayer === null || referenceLayer === void 0 ? void 0 : referenceLayer.layerMaterialType) === LayerMaterialTypeKeys.WellVentilatedAirspace, className: styles.tab, label: "Fasteners", id: "layer-fasteners-details-tab", "aria-controls": "layer-fasteners-details-panel", "data-qa-id": "layer-fasteners-details-tab" }),
            React.createElement(Tab, { disableRipple: true, className: styles.tab, label: "Air Gaps", id: "layer-air-gaps-details-tab", "aria-controls": "layer-air-gaps-details-panel", "data-qa-id": "layer-air-gaps-details-tab" }),
            React.createElement(Tab, { disableRipple: true, className: styles.tab, label: "Vapour", id: "layer-vapour-tab", "aria-controls": "layer-vapour-panel", "data-qa-id": "layer-vapour-tab" }),
            React.createElement(Tab, { disableRipple: true, className: styles.tab, label: renderNoteTabLabel(), id: "layer-notes-tab", "aria-controls": "layer-notes-tab-panel", "data-qa-id": "layer-notes-tab" })),
        props.tabIndex !== false && (React.createElement("div", { className: styles.tabbedContentContainer, "data-qa-id": "tab-panels-container" },
            (referenceLayer === null || referenceLayer === void 0 ? void 0 : referenceLayer.layerMaterialType) !== LayerMaterialTypeKeys.WellVentilatedAirspace && (React.createElement(BridgingTab, Object.assign({ targetIndex: bridgingTabIndex, currentTabIndex: props.tabIndex }, props))),
            (referenceLayer === null || referenceLayer === void 0 ? void 0 : referenceLayer.layerMaterialType) !== LayerMaterialTypeKeys.WellVentilatedAirspace && (React.createElement(FastenersTab, Object.assign({ targetIndex: fastenersTabIndex, currentTabIndex: props.tabIndex }, props))),
            React.createElement(AirGapsTab, Object.assign({ targetIndex: airGapsTabIndex, currentTabIndex: props.tabIndex }, props)),
            React.createElement(VapourTab, Object.assign({ targetIndex: vapourTabIndex, currentTabIndex: props.tabIndex }, props)),
            React.createElement(LayerNotesTab, Object.assign({ targetIndex: layerNotesTabIndex, currentTabIndex: props.tabIndex }, props))))));
}
