import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { AuthenticationState, AzureAD } from 'react-aad-msal';
import { SnackbarProvider } from 'notistack';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import { Layout } from '../Layout/Layout';
import { CalculationScreen } from '../Screens/CalculationScreen/CalculationScreen';
import { store } from '../../store/store';
import { theme } from '../../theme/theme';
import { authProvider } from '../../auth/authProvider';
import { UnauthenticatedLandingPage } from '../LandingPages/UnauthenticatedLandingPage/UnauthenticatedLandingPage';
import { AuthorisingLandingPage } from '../LandingPages/AuthorisingLandingPage/AuthorisingLandingPage';
import styles from './App.module.scss';
export function App() {
    injectStyle();
    return (React.createElement(Provider, { store: store },
        React.createElement(SnackbarProvider, { anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, maxSnack: 1, classes: { containerRoot: styles.snackbarRoot, anchorOriginBottomCenter: styles.snackbar } },
            React.createElement(AzureAD, { provider: authProvider, reduxStore: store, forceLogin: true }, ({ authenticationState }) => (React.createElement(ThemeProvider, { theme: theme },
                React.createElement(CssBaseline, null),
                authenticationState === AuthenticationState.InProgress && React.createElement(AuthorisingLandingPage, null),
                authenticationState === AuthenticationState.Unauthenticated && React.createElement(UnauthenticatedLandingPage, null),
                authenticationState === AuthenticationState.Authenticated && (React.createElement(Layout, null,
                    React.createElement(BrowserRouter, null,
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: "/calculation" },
                                React.createElement(CalculationScreen, null)),
                            React.createElement(Redirect, { to: "/calculation" }))))))))),
        React.createElement(ToastContainer, null)));
}
