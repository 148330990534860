import { getEmptyMasterData } from '../types/store/masterDataTypes';
import { actionTypes } from '../actions/actionTypes';
const initialState = Object.assign(Object.assign({}, getEmptyMasterData()), { isLoadingMasterData: false });
export const masterDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MASTER_DATA:
            return Object.assign(Object.assign({}, action.payload.masterData), { isLoadingMasterData: state.isLoadingMasterData });
        case actionTypes.SET_IS_LOADING_MASTER_DATA:
            return Object.assign(Object.assign({}, state), { isLoadingMasterData: action.payload.isLoadingMasterData });
        default:
            return state;
    }
};
