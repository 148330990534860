import React from 'react';
import { Grid, InputLabel, Button, ButtonGroup, FormHelperText } from '@material-ui/core';
import { TabPanel } from './TabPanel';
import { AirGapsCorrectionLevel } from '../../types/store/masterDataTypes';
import styles from './LayerModal.module.scss';
import { LayerMaterialTypeKeys } from '../../types/store/LayerMaterialTypes';
export function AirGapsTab(props) {
    var _a, _b, _c;
    const { masterData, referenceLayer, airGapsCorrectionLevel, airGapsCorrectionLevelOverride, setAirGapsCorrectionLevelOverride, insideEmissivity, outsideEmissivity, airGapsCorrectionResultValue, totalAirGapsCorrectionFactor, targetIndex, currentTabIndex, isLocked, } = props;
    const isSelected = (masterDataAirGapsCorrectionLevel) => {
        if ((masterDataAirGapsCorrectionLevel === null || masterDataAirGapsCorrectionLevel === void 0 ? void 0 : masterDataAirGapsCorrectionLevel.level) === AirGapsCorrectionLevel.Level0 && !airGapsCorrectionLevel) {
            return true;
        }
        return (masterDataAirGapsCorrectionLevel === null || masterDataAirGapsCorrectionLevel === void 0 ? void 0 : masterDataAirGapsCorrectionLevel.level) === airGapsCorrectionLevel;
    };
    const shouldDisableAirgaps = !referenceLayer ||
        !(referenceLayer.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation ||
            referenceLayer.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation);
    return (React.createElement(TabPanel, { id: "layer-air-gaps-correction-details-panel", "data-qa-id": "layer-air-gaps-correction-details-panel", targetIndex: targetIndex, currentTabIndex: currentTabIndex, "aria-labelledby": "layer-air-gaps-correction-details-tab" },
        React.createElement("div", { className: styles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 5 },
                    React.createElement(InputLabel, { "data-qa-id": "layerAirGapsCorrectionLabel" }, "Air Gaps Correction Level"),
                    React.createElement("div", { className: styles.relative },
                        React.createElement(ButtonGroup, { size: "large", variant: "contained", className: styles.multiButtonContainer, "aria-label": "air-gaps-correction-button-group", disabled: isLocked || shouldDisableAirgaps, title: shouldDisableAirgaps ? 'An air gaps correction can only be applied to an insulation layer' : '' },
                            React.createElement(Button, { disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, className: isSelected((_a = masterData.airGapsCorrectionLevels) === null || _a === void 0 ? void 0 : _a.level0) ? styles.multiButtonSelected : '', onClick: () => { var _a; return setAirGapsCorrectionLevelOverride((_a = masterData.airGapsCorrectionLevels) === null || _a === void 0 ? void 0 : _a.level0.level); }, "data-qa-id": "layerAirGapsCorrectionLevel0Button" }, "0"),
                            React.createElement(Button, { disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, className: isSelected((_b = masterData.airGapsCorrectionLevels) === null || _b === void 0 ? void 0 : _b.level1) ? styles.multiButtonSelected : '', onClick: () => { var _a; return setAirGapsCorrectionLevelOverride((_a = masterData.airGapsCorrectionLevels) === null || _a === void 0 ? void 0 : _a.level1.level); }, "data-qa-id": "layerAirGapsCorrectionLevel1Button" }, "1"),
                            React.createElement(Button, { disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, className: isSelected((_c = masterData.airGapsCorrectionLevels) === null || _c === void 0 ? void 0 : _c.level2) ? styles.multiButtonSelected : '', onClick: () => { var _a; return setAirGapsCorrectionLevelOverride((_a = masterData.airGapsCorrectionLevels) === null || _a === void 0 ? void 0 : _a.level2.level); }, "data-qa-id": "layerAirGapsCorrectionLevel2Button" }, "2")),
                        airGapsCorrectionLevelOverride && React.createElement(FormHelperText, null, "This property has been manually overridden"))))),
        React.createElement("div", { className: styles.tabContentResults },
            React.createElement(Grid, { container: true, spacing: 10 },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "layerAirGapsCorrectionFactorLabel" }, "Layer Air Gaps Correction Factor"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "layerAirGapsCorrectionFactor" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "layerAirGapsCorrectionFactorValue" }, airGapsCorrectionResultValue !== null && airGapsCorrectionResultValue !== void 0 ? airGapsCorrectionResultValue : '0.000000'))))),
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "totalAirGapsCorrectionFactorLabel" }, "Total Air Gaps Correction Factor"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "totalAirGapsCorrectionFactor" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "totalAirGapsCorrectionFactorValue" }, totalAirGapsCorrectionFactor !== null && totalAirGapsCorrectionFactor !== void 0 ? totalAirGapsCorrectionFactor : '0.000000')))))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "insideSurfaceEmissivityLabel" }, "Inside Surface Emissivity"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "insideSurfaceEmissivity" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "insideSurfaceEmissivityValue" }, insideEmissivity))))),
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "outsideSurfaceEmissivityLabel" }, "Outside Surface Emissivity"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "outsideSurfaceEmissivity" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "outsideSurfaceEmissivityValue" }, outsideEmissivity))))))))));
}
