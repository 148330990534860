export var FloorInsulationLayerReferenceType;
(function (FloorInsulationLayerReferenceType) {
    FloorInsulationLayerReferenceType["Master"] = "Master";
    FloorInsulationLayerReferenceType["None"] = "None";
    FloorInsulationLayerReferenceType["Custom"] = "Custom";
})(FloorInsulationLayerReferenceType || (FloorInsulationLayerReferenceType = {}));
export const customLayerDefaults = {
    name: '',
    thermalResistance: null,
    thermalConductivity: null,
    insideEmissivity: null,
    outsideEmissivity: null,
    layerMaterialType: null,
    isCustom: true,
    isBlank: true,
};
export var CustomLayerTypeOptions;
(function (CustomLayerTypeOptions) {
    CustomLayerTypeOptions["Blank"] = "Blank";
    CustomLayerTypeOptions["Insulation"] = "Insulation";
    CustomLayerTypeOptions["NonInsulation"] = "Non-Insulation";
    CustomLayerTypeOptions["InvertedInsulation"] = "Inverted Insulation";
})(CustomLayerTypeOptions || (CustomLayerTypeOptions = {}));
export const customLayerTypeOptions = [
    CustomLayerTypeOptions.Blank,
    CustomLayerTypeOptions.Insulation,
    CustomLayerTypeOptions.NonInsulation,
    CustomLayerTypeOptions.InvertedInsulation,
];
export var EnvConditionType;
(function (EnvConditionType) {
    EnvConditionType["InternalTemperature"] = "InternalTemperature";
    EnvConditionType["InternalRelativeHumidity"] = "InternalRelativeHumidity";
    EnvConditionType["ExternalTemperature"] = "ExternalTemperature";
    EnvConditionType["ExternalRelativeHumidity"] = "ExternalRelativeHumidity";
})(EnvConditionType || (EnvConditionType = {}));
