import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { DebouncedDecimalInput } from '../FormComponents/DebouncedDecimalInput/DebouncedDecimalInput';
import { TabPanel } from './TabPanel';
import styles from './LayerModal.module.scss';
export function VapourTab(props) {
    const { targetIndex, currentTabIndex, setVapourResistances, vapourResistance, vapourResistivity, isLocked } = props;
    const onVapourResistanceChange = (value) => setVapourResistances(value, value === '' ? vapourResistivity : null);
    const onVapourResistivityChange = (value) => setVapourResistances(value === '' ? vapourResistance : null, value);
    return (React.createElement(TabPanel, { id: "layer-vapour-panel", "data-qa-id": "layer-vapour-panel", targetIndex: targetIndex, currentTabIndex: currentTabIndex, "aria-labelledby": "layer-vapour-tab" },
        React.createElement("div", { className: styles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-vapour-resistance", "data-qa-id": "layerVapourResistanceLabel" }, "Vapour Resistance"),
                    React.createElement(Grid, { container: true, alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 10 },
                            React.createElement(DebouncedDecimalInput, { "data-qa-id": "layerVapourResistanceInput", delay: 400, onChange: onVapourResistanceChange, precision: 3, textFieldId: "modal-layer-vapour-resistance", value: vapourResistance || '', disabled: isLocked })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerVapourResistanceUnits" }, "MNs/g")))),
                React.createElement(Grid, { item: true, xs: 1 }),
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-vapour-resistivity", "data-qa-id": "layerVapourResistivityLabel" }, "Vapour Resistivity"),
                    React.createElement(Grid, { container: true, alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 10 },
                            React.createElement(DebouncedDecimalInput, { "data-qa-id": "layerVapourResistivityInput", delay: 400, onChange: onVapourResistivityChange, precision: 3, textFieldId: "modal-layer-vapour-resistivity", value: vapourResistivity || '', disabled: isLocked })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerVapourResistivityUnits" }, "MNs/gm")))))),
        React.createElement("div", { className: styles.tabContentResults },
            React.createElement(Grid, { container: true, spacing: 10 },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: styles.tabContentResult }))))));
}
