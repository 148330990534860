import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import styles from './CondensationRiskModal.module.scss';
import { EnvConditionsTab } from './EnvConditionsTab';
import { CondensationResultsTab } from './CondensationResultsTab';
const envConditionsTabIndex = 0;
const condensationResultsTabIndex = 1;
export function CondensationRiskTabs(props) {
    const [tabIndex, setTabIndex] = React.useState(envConditionsTabIndex);
    const handleTabChange = (_event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { className: styles.tabs, value: tabIndex, onChange: handleTabChange, indicatorColor: "primary", textColor: "primary", "aria-label": "condensation-risk-tabs", "data-qa-id": "condensation-risk-tabs" },
            React.createElement(Tab, { disableRipple: true, className: styles.tab, label: "Env Conditions", id: "env-conditions-tab", "aria-controls": "env-conditions-panel", "data-qa-id": "env-conditions-tab" }),
            React.createElement(Tab, { disableRipple: true, className: styles.tab, label: "Condensation Results", id: "condensation-results-tab", "aria-controls": "condensation-results-panel", "data-qa-id": "condensation-results-tab" })),
        tabIndex !== false && (React.createElement("div", { className: styles.tabbedContentContainer, "data-qa-id": "tab-panels-container" },
            React.createElement(EnvConditionsTab, Object.assign({ targetIndex: envConditionsTabIndex, currentTabIndex: tabIndex }, props)),
            React.createElement(CondensationResultsTab, Object.assign({ targetIndex: condensationResultsTabIndex, currentTabIndex: tabIndex }, props))))));
}
