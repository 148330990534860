export var AirGapsCorrectionLevel;
(function (AirGapsCorrectionLevel) {
    AirGapsCorrectionLevel["Level0"] = "Level0";
    AirGapsCorrectionLevel["Level1"] = "Level1";
    AirGapsCorrectionLevel["Level2"] = "Level2";
})(AirGapsCorrectionLevel || (AirGapsCorrectionLevel = {}));
export const getEmptyMasterData = () => ({
    businessUnitId: -1,
    layers: [],
    floorLayers: [],
    bridgeMaterials: [],
    applications: [],
    mechanicalFasteners: [],
    airGapsCorrectionLevels: {
        level0: {
            level: AirGapsCorrectionLevel.Level0,
            value: '',
        },
        level1: {
            level: AirGapsCorrectionLevel.Level1,
            value: '',
        },
        level2: {
            level: AirGapsCorrectionLevel.Level2,
            value: '',
        },
    },
    buildingRegions: [],
    buildingTypes: [],
    riskLevels: [],
    soilTypes: [],
    rainscreenCladdingDefaults: {
        bracketsPerMetreSquared: '',
        chiValue: '',
    },
    drainageFx: [],
    precipitationByPostCodeArea: [],
    buildingUsage: [],
    projectRegion: [],
    branding: [],
    roofCharacteristicsOptions: [],
    salesContactTeams: [],
    projectCountyStates: [],
    projectStages: [],
    productSectors: [],
    calculationTitles: [],
});
