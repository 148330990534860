import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Add, FolderOpen, Settings } from '@material-ui/icons';
import React from 'react';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './WelcomeModal.module.scss';
import { useSelector } from 'react-redux';
import { userProfileSelector } from '../../store/selectors';
import { checkIsPowerUser } from '../../common/userProfile';
export function WelcomeModal({ onCreateNewCalculationClick, onMasterDataUploadClick, onOpenCalculationClick }) {
    const userProfile = useSelector(userProfileSelector);
    const isPowerUser = checkIsPowerUser(userProfile);
    return (React.createElement(Dialog, { open: true, "aria-labelledby": "modal-welcome-title", disableBackdropClick: true, disableEscapeKeyDown: true },
        React.createElement("div", { className: modalStyles.modalHeader },
            React.createElement(DialogTitle, { id: "modal-welcome-title" },
                React.createElement(Typography, { component: "span", variant: "h5" }, "Kingspan Insulation Calculation Services"))),
        React.createElement("div", { className: `${[modalStyles.modalContent, styles.modalContent].join(' ')}` },
            React.createElement(DialogContent, null,
                React.createElement(Grid, { container: true, spacing: 3 },
                    React.createElement(Grid, { item: true, xs: isPowerUser ? 4 : 6, "data-qa-id": "welcomeModalCreateGrid" },
                        React.createElement(Button, { onClick: onCreateNewCalculationClick, "data-qa-id": "welcomeModalCreateButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true, startIcon: React.createElement(Add, null) }, "Create")),
                    React.createElement(Grid, { item: true, xs: isPowerUser ? 4 : 6, "data-qa-id": "welcomeModalOpenGrid" },
                        React.createElement(Button, { onClick: onOpenCalculationClick, "data-qa-id": "welcomeModalOpenButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true, startIcon: React.createElement(FolderOpen, null) }, "Open")),
                    isPowerUser && (React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "welcomeModalUploadGrid" },
                        React.createElement(Button, { onClick: onMasterDataUploadClick, "data-qa-id": "welcomeModalUploadButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true, startIcon: React.createElement(Settings, null) }, "Upload Master Data"))))))));
}
