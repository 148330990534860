import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, InputLabel, Select, MenuItem, Paper, Button } from '@material-ui/core';
import MaterialTable, { MTableCell } from 'material-table';
import { TabPanel } from '../LayerModal/TabPanel';
import { EnvConditionsEditorModal } from './EnvConditionsEditorModal';
import { buildingRegionSelector, buildingTypeSelector, riskLevelSelector, envConditionsSelector, envSettingsSelector, } from '../../store/selectors';
import { addEnvConditionOverrides, setBuildingRegion, setBuildingType, setInternalTemperature, setRiskLevel, clearEnvConditionOverrides, } from '../../actions/calculationActions';
import styles from './CondensationRiskModal.module.scss';
import tableStyles from './EnvConditionsTab.module.scss';
import { DebouncedDecimalInput } from '../FormComponents/DebouncedDecimalInput/DebouncedDecimalInput';
const dateTimeFormat = new Intl.DateTimeFormat('en-GB', { month: 'short' });
const monthColumns = new Array(12).fill({}).map((_, i) => ({
    field: `months[${i}].value`,
    key: i + 1,
    title: dateTimeFormat.format(new Date(1, i, 1)),
}));
const columns = [
    ...[],
    {
        field: 'name',
        key: 0,
        render: (rowdata) => React.createElement("span", { className: tableStyles.tableCellContainer }, `${rowdata.name} (${rowdata.unit})`),
        title: '',
    },
    ...monthColumns,
];
export function EnvConditionsTab(props) {
    const { currentTabIndex, setIsInvalid, targetIndex } = props;
    const dispatch = useDispatch();
    const buildingRegions = useSelector(buildingRegionSelector);
    const buildingTypes = useSelector(buildingTypeSelector);
    const riskLevels = useSelector(riskLevelSelector);
    const environmentalConditions = useSelector(envConditionsSelector);
    const envSettings = useSelector(envSettingsSelector);
    const [state, setState] = React.useState({
        isEditorOpen: false,
        monthEditKey: 1,
    });
    React.useEffect(() => {
        if (!envSettings) {
            dispatch(setRiskLevel(4));
            dispatch(setInternalTemperature('20'));
        }
    }, [dispatch, envSettings]);
    React.useEffect(() => {
        if ((envSettings === null || envSettings === void 0 ? void 0 : envSettings.buildingRegionId) &&
            (envSettings === null || envSettings === void 0 ? void 0 : envSettings.buildingTypeId) &&
            (envSettings === null || envSettings === void 0 ? void 0 : envSettings.internalTemperature) &&
            !isNaN(Number(envSettings === null || envSettings === void 0 ? void 0 : envSettings.internalTemperature))) {
            setIsInvalid(false);
        }
        else {
            setIsInvalid(true);
        }
    }, [setIsInvalid, envSettings]);
    const handleBuildingRegionChange = React.useCallback(({ target: { value } }) => {
        dispatch(setBuildingRegion(value));
    }, [dispatch]);
    const handleBuildingTypeChange = React.useCallback(({ target: { value } }) => {
        dispatch(setBuildingType(value));
    }, [dispatch]);
    const handleInternalTemperatureChange = React.useCallback((value) => {
        dispatch(setInternalTemperature(value));
    }, [dispatch]);
    const handleRiskLevelChange = React.useCallback(({ target: { value } }) => {
        dispatch(setRiskLevel(value));
    }, [dispatch]);
    const getEnvConditions = React.useCallback(() => (environmentalConditions || []).map(ec => (Object.assign({}, ec))), [environmentalConditions]);
    const handleRowClick = React.useCallback((key) => () => {
        if (key > 0) {
            setState(prevState => (Object.assign(Object.assign({}, prevState), { isEditorOpen: true, monthEditKey: key })));
        }
    }, []);
    const handleEditorClose = () => {
        setState(Object.assign(Object.assign({}, state), { isEditorOpen: false }));
    };
    const handleEditorSubmit = React.useCallback((envConditionOverrides) => {
        dispatch(addEnvConditionOverrides(envConditionOverrides));
    }, [dispatch]);
    const handleResetClick = React.useCallback(() => {
        dispatch(clearEnvConditionOverrides());
    }, [dispatch]);
    const handleMoveToNextMonth = () => {
        setState(prevState => {
            const { monthEditKey } = prevState;
            if (monthEditKey < 12) {
                return Object.assign(Object.assign({}, prevState), { monthEditKey: monthEditKey + 1 });
            }
            return prevState;
        });
    };
    const handleMoveToPrevMonth = () => {
        setState(prevState => {
            const { monthEditKey } = prevState;
            if (monthEditKey > 1) {
                return Object.assign(Object.assign({}, prevState), { monthEditKey: monthEditKey - 1 });
            }
            return prevState;
        });
    };
    return (React.createElement(TabPanel, { id: "env-conditions-panel", "data-qa-id": "env-conditions-panel", targetIndex: targetIndex, currentTabIndex: currentTabIndex, "aria-labelledby": "env-conditions-tab" },
        React.createElement("div", { className: styles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(InputLabel, { "data-qa-id": "buildingRegionLabel", id: "env-conditions-building-region-label" }, "Building region"),
                    React.createElement(Select, { fullWidth: true, "data-qa-id": "buildingRegionInput", id: "env-conditions-building-region", value: (envSettings === null || envSettings === void 0 ? void 0 : envSettings.buildingRegionId) || '', onChange: handleBuildingRegionChange, variant: "outlined", disabled: props.isLocked }, buildingRegions.map(buildingRegion => {
                        return (React.createElement(MenuItem, { key: buildingRegion.id, value: buildingRegion.id }, buildingRegion.name));
                    }))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(InputLabel, { "data-qa-id": "buildingTypeLabel", id: "env-conditions-building-type-label" }, "Internal humidity class"),
                    React.createElement(Select, { fullWidth: true, "data-qa-id": "buildingTypeInput", id: "env-conditions-building-type", value: (envSettings === null || envSettings === void 0 ? void 0 : envSettings.buildingTypeId) || '', onChange: handleBuildingTypeChange, variant: "outlined", disabled: props.isLocked }, buildingTypes.map(buildingType => {
                        return (React.createElement(MenuItem, { key: buildingType.id, value: buildingType.id }, buildingType.name));
                    }))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(InputLabel, { "data-qa-id": "riskLevelLabel", id: "env-conditions-risk-level-label" }, "Risk level"),
                    React.createElement(Select, { fullWidth: true, "data-qa-id": "riskLevelInput", id: "env-conditions-risk-level", value: (envSettings === null || envSettings === void 0 ? void 0 : envSettings.riskLevelId) || '', onChange: handleRiskLevelChange, variant: "outlined", disabled: props.isLocked }, riskLevels.map(riskLevel => {
                        return (React.createElement(MenuItem, { key: riskLevel.id, value: riskLevel.id }, riskLevel.name));
                    }))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(InputLabel, { "data-qa-id": "internalTemperatureLabel", id: "env-conditions-internal-temperature-label" }, "Internal temp"),
                    React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(DebouncedDecimalInput, { "data-qa-id": "internalTemperatureInput", delay: 400, onChange: handleInternalTemperatureChange, precision: 3, textFieldId: "env-conditions-internal-temperature", value: (envSettings === null || envSettings === void 0 ? void 0 : envSettings.internalTemperature) || '', disabled: props.isLocked })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "internalTemperatureUnits" }, "\u00B0C")))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(MaterialTable, { columns: columns, data: getEnvConditions(), options: {
                            sorting: false,
                            showTitle: false,
                            search: false,
                            paging: false,
                            toolbar: false,
                            grouping: false,
                            selection: false,
                            draggable: false, // Relates to draggable columns, not rows
                        }, components: {
                            Container: renderProps => (React.createElement("div", { className: tableStyles.tableContainer },
                                React.createElement(Paper, Object.assign({}, renderProps, { className: tableStyles.table, variant: "outlined" })))),
                            Cell: renderProps => {
                                const { columnDef: { key }, } = renderProps;
                                return (React.createElement("td", { className: tableStyles.tableCellContainer, onClick: handleRowClick(key) },
                                    React.createElement(MTableCell, Object.assign({ component: "span" }, renderProps))));
                            },
                        }, localization: {
                            body: {
                                emptyDataSourceMessage: 'Please select a Building Region and Internal Humidity Class',
                            },
                        } })),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Grid, { container: true, direction: "column", alignItems: "flex-end" },
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(Button, { "data-qa-id": "resetEnvConditionOverridesButton", disabled: props.isLocked || !(envSettings === null || envSettings === void 0 ? void 0 : envSettings.envConditionOverrides) || envSettings.envConditionOverrides.length === 0, variant: "outlined", onClick: handleResetClick }, "Reset")))))),
        state.isEditorOpen && (React.createElement(EnvConditionsEditorModal, { envConditions: getEnvConditions(), monthKey: state.monthEditKey, onClose: handleEditorClose, onMoveToNextMonth: handleMoveToNextMonth, onMoveToPrevMonth: handleMoveToPrevMonth, onSubmit: handleEditorSubmit, isLocked: props.isLocked }))));
}
