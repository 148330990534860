import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Header } from '../Header/Header';
import styles from './Layout.module.scss';
import { apiSelector, currentCalculationBusinessUnitIdSelector, userProfileSelector } from '../../store/selectors';
import { UnauthenticatedLandingPage } from '../LandingPages/UnauthenticatedLandingPage/UnauthenticatedLandingPage';
import { AccessDeniedLandingPage } from '../LandingPages/AccessDeniedLandingPage/AccessDeniedLandingPage';
import { AuthorisingLandingPage } from '../LandingPages/AuthorisingLandingPage/AuthorisingLandingPage';
import { fetchMasterData } from '../../actions/masterDataActions';
import { fetchUserProfile } from '../../actions/userProfileActions';
import { checkIsPowerUser } from '../../common/userProfile';
export function Layout({ children }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);
    const userProfile = useSelector(userProfileSelector);
    const currentCalculationBusinessUnitId = useSelector(currentCalculationBusinessUnitIdSelector);
    const isPowerUser = checkIsPowerUser(userProfile);
    React.useEffect(() => {
        (async () => {
            var _a, _b, _c, _d;
            if (userProfile && currentCalculationBusinessUnitId) {
                try {
                    await dispatch(fetchMasterData(currentCalculationBusinessUnitId));
                }
                catch (e) {
                    const status = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status;
                    const message = status === 400 /* Bad Request */
                        ? (_d = (_c = (_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : 'Unknown failure while fetching master data.'
                        : isPowerUser
                            ? 'Unable to fetch master data, please try uploading the previous valid set or contact support for more information.'
                            : 'Unable to fetch master data, please contact your system administrator.';
                    enqueueSnackbar(message, {
                        variant: 'error',
                        persist: true,
                        TransitionComponent: Fade,
                    });
                }
            }
        })();
    }, [dispatch, enqueueSnackbar, userProfile, currentCalculationBusinessUnitId, isPowerUser]);
    const { isFirstCallSuccessful, isAuthenticated, isAuthorised } = useSelector(apiSelector);
    /**
     * This covers the scenario where the frontend flow is happy that the user is authenticated
     * but the API throws an authentication error. This is unlikely to happen.
     */
    if (!isAuthenticated) {
        return React.createElement(UnauthenticatedLandingPage, null);
    }
    /**
     * This covers the scenario where both the frontend and API are happy that the user is
     * authenticated but they are not in the correct group(s) to be given access to the app.
     */
    if (!isAuthorised) {
        return React.createElement(AccessDeniedLandingPage, null);
    }
    return (React.createElement(React.Fragment, null,
        !isFirstCallSuccessful && React.createElement(AuthorisingLandingPage, null),
        React.createElement(Container, { fixed: true, disableGutters: true },
            React.createElement("div", { className: styles.page },
                React.createElement(Container, null,
                    React.createElement(Header, null),
                    React.createElement("div", { "data-qa-id": "pageContent", className: styles.content }, children))))));
}
