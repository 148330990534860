import React from 'react';
import { Button, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import styles from './LayerModal.module.scss';
import { customLayerDefaults, CustomLayerTypeOptions, customLayerTypeOptions, } from '../../types/store/calculationTypes';
import { LayerMaterialTypeKeys } from '../../types/store/LayerMaterialTypes';
import { isStringNotNullishOrWhitespace } from '../../common/inputValidation';
const config = {
    stringify: (option) => option.name,
};
const filter = createFilterOptions(config);
export function CoreLayerDetails(props) {
    const { masterData, layerThermalResistance, clearAllDetails, apiBridgeResponse, masterLayer, setMasterLayer, layerThickness, setLayerThickness, shortCode, setShortCode, hasShortCodeError, setHasShortCodeError, setLayerFromShortCode, customReferenceLayer, setCustomReferenceLayer, updateCustomReferenceLayer, refShortCodeField, refThicknessField, isLocked, } = props;
    const getCustomLayerType = (referenceLayer) => {
        if (referenceLayer.layerMaterialType === LayerMaterialTypeKeys.GenericInsulation) {
            return CustomLayerTypeOptions.Insulation;
        }
        if (referenceLayer.layerMaterialType === LayerMaterialTypeKeys.NonInsulation) {
            return CustomLayerTypeOptions.NonInsulation;
        }
        if (referenceLayer.layerMaterialType === LayerMaterialTypeKeys.InvertedInsulation) {
            return CustomLayerTypeOptions.InvertedInsulation;
        }
        if (referenceLayer.isBlank) {
            return CustomLayerTypeOptions.Blank;
        }
        return CustomLayerTypeOptions.Blank;
    };
    const shouldShowThermalResistance = (referenceLayer) => {
        if (isStringNotNullishOrWhitespace(referenceLayer.thermalResistance)) {
            return true;
        }
        if (!isStringNotNullishOrWhitespace(referenceLayer.thermalResistance) &&
            !isStringNotNullishOrWhitespace(referenceLayer.thermalConductivity)) {
            return true;
        }
        return false;
    };
    const shouldShowThermalConductivity = (referenceLayer) => {
        if (!isStringNotNullishOrWhitespace(referenceLayer.thermalResistance)) {
            return true;
        }
        if (!isStringNotNullishOrWhitespace(referenceLayer.thermalResistance) &&
            !isStringNotNullishOrWhitespace(referenceLayer.thermalConductivity)) {
            return true;
        }
        return false;
    };
    return (React.createElement("div", null,
        React.createElement(Grid, { container: true, spacing: 3 },
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement(InputLabel, { htmlFor: "modal-layer-short-code", "data-qa-id": "shortCodeLabel" }, "Short Code"),
                React.createElement(TextField, { id: "modal-layer-short-code", "data-qa-id": "shortCodeInput", variant: "outlined", value: shortCode || '', onChange: (event) => {
                        setShortCode(event.target.value);
                        setHasShortCodeError(false);
                    }, onBlur: () => {
                        setShortCode('');
                        setHasShortCodeError(false);
                    }, onKeyDown: event => {
                        // If "Enter" key
                        if (event.keyCode === 13) {
                            event.preventDefault();
                            setLayerFromShortCode(shortCode || '', apiBridgeResponse);
                        }
                    }, InputProps: {
                        ref: refShortCodeField,
                    }, error: hasShortCodeError, helperText: hasShortCodeError ? 'Material not found' : 'Hit enter to search', disabled: isLocked })),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement(InputLabel, { htmlFor: "modal-layer-name", "data-qa-id": "layerNameLabel" }, "Layer"),
                React.createElement(Autocomplete, { id: "modal-layer-name", "data-qa-id": "layerNameInput", options: masterData.layers.filter(layer => layer.isActive !== false), autoHighlight: true, getOptionLabel: (option) => option.name, onChange: (_event, layer) => {
                        if (!layer) {
                            clearAllDetails();
                            return;
                        }
                        if (layer.isCustom) {
                            if (customReferenceLayer == null) {
                                setCustomReferenceLayer(layer);
                            }
                            else {
                                updateCustomReferenceLayer({ name: layer.name });
                            }
                        }
                        else {
                            setMasterLayer(layer, apiBridgeResponse);
                        }
                    }, value: customReferenceLayer !== null && customReferenceLayer !== void 0 ? customReferenceLayer : masterLayer, disabled: isLocked, forcePopupIcon: true, freeSolo: true, filterOptions: (options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '') {
                            const customLayerOption = customReferenceLayer != null
                                ? Object.assign(Object.assign({}, customReferenceLayer), { name: params.inputValue }) : Object.assign(Object.assign({}, customLayerDefaults), { name: params.inputValue });
                            filtered.push(customLayerOption);
                        }
                        return filtered;
                    }, renderOption: (option) => !option.isCustom ? (option.name) : (React.createElement(Grid, { container: true, justify: "space-between", alignItems: "center" },
                        React.createElement("span", { "data-qa-id": "customLayerOptionName" }, option.name),
                        React.createElement(Grid, { item: true },
                            React.createElement(Button, { variant: "outlined", "data-qa-id": "customLayerOptionButton", disabled: isLocked }, customReferenceLayer == null ? '+ Add Custom Layer' : 'Update Custom Layer Name')))), renderInput: params => (React.createElement(TextField, Object.assign({}, params, { placeholder: "Browse catalogue...", variant: "outlined", autoComplete: "off" // Disable browser autocomplete and autofill
                     }))) })),
            React.createElement(Grid, { item: true, xs: 2 }, !(customReferenceLayer === null || customReferenceLayer === void 0 ? void 0 : customReferenceLayer.isBlank) && (React.createElement(React.Fragment, null,
                React.createElement(InputLabel, { htmlFor: "modal-layer-thickness", "data-qa-id": "layerThicknessLabel" }, "Thickness"),
                React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                    React.createElement(Grid, { item: true, xs: 10 },
                        React.createElement(TextField, { id: "modal-layer-thickness", "data-qa-id": "layerThicknessInput", placeholder: "0.0", variant: "outlined", value: layerThickness || '', disabled: isLocked, onChange: (event) => setLayerThickness(event.target.value), InputProps: {
                                ref: refThicknessField,
                            } })),
                    React.createElement(Grid, { item: true, xs: 2 },
                        React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerThicknessUnits" }, "mm")))))),
            React.createElement(Grid, { item: true, xs: 4 },
                React.createElement("div", { className: styles.layerResult },
                    React.createElement("div", { "data-qa-id": "layerThermalResistanceTitle", className: styles.layerResultTitle }, "Layer Thermal Resistance"),
                    React.createElement("div", { "data-qa-id": "layerThermalResistanceValue", className: styles.layerResultValue }, layerThermalResistance !== null && layerThermalResistance !== void 0 ? layerThermalResistance : '0.000'),
                    React.createElement("div", { "data-qa-id": "layerThermalResistanceUnit", className: styles.layerResultUnit }, "m\u00B2K/W")))),
        customReferenceLayer == null ? null : (React.createElement("div", { className: styles.customLayerDetailsWrapper, "data-qa-id": "customLayerDetailsWrapper" },
            React.createElement("div", { className: styles.customLayerDetails },
                React.createElement(Grid, { container: true, spacing: 3 },
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement(InputLabel, { htmlFor: "modal-custom-layer-type", "data-qa-id": "customlayerTypeLabel" }, "Custom Layer Type"),
                        React.createElement(Select, { fullWidth: true, "data-qa-id": "customLayerTypeInput", id: "modal-custom-layer-type", value: getCustomLayerType(customReferenceLayer), disabled: isLocked, onChange: (event) => {
                                const layerType = event.target.value;
                                if (layerType === CustomLayerTypeOptions.Blank) {
                                    const capturedReferenceLayer = customReferenceLayer;
                                    clearAllDetails();
                                    updateCustomReferenceLayer(Object.assign(Object.assign({}, customLayerDefaults), { isBlank: true, layerMaterialType: null, name: capturedReferenceLayer.name }));
                                }
                                else if (layerType === CustomLayerTypeOptions.Insulation) {
                                    updateCustomReferenceLayer({
                                        isBlank: false,
                                        layerMaterialType: LayerMaterialTypeKeys.GenericInsulation,
                                        thermalConductivity: customLayerDefaults.thermalConductivity,
                                        thermalResistance: customLayerDefaults.thermalResistance,
                                        insideEmissivity: '0.90',
                                        outsideEmissivity: '0.90',
                                    });
                                }
                                else if (layerType === CustomLayerTypeOptions.InvertedInsulation) {
                                    updateCustomReferenceLayer({
                                        isBlank: false,
                                        layerMaterialType: LayerMaterialTypeKeys.InvertedInsulation,
                                        thermalConductivity: customLayerDefaults.thermalConductivity,
                                        thermalResistance: customLayerDefaults.thermalResistance,
                                        insideEmissivity: '0.90',
                                        outsideEmissivity: '0.90',
                                    });
                                }
                                else {
                                    updateCustomReferenceLayer({
                                        isBlank: false,
                                        layerMaterialType: LayerMaterialTypeKeys.NonInsulation,
                                        thermalConductivity: customLayerDefaults.thermalConductivity,
                                        thermalResistance: customLayerDefaults.thermalResistance,
                                        insideEmissivity: '0.90',
                                        outsideEmissivity: '0.90',
                                    });
                                }
                            }, variant: "outlined" }, customLayerTypeOptions.map(customLayerTypeOption => {
                            return (React.createElement(MenuItem, { key: customLayerTypeOption, value: customLayerTypeOption }, customLayerTypeOption));
                        }))),
                    !(customReferenceLayer === null || customReferenceLayer === void 0 ? void 0 : customReferenceLayer.isBlank) && (React.createElement(Grid, { container: true, item: true, xs: 9, spacing: 3 },
                        shouldShowThermalConductivity(customReferenceLayer) && (React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(InputLabel, { htmlFor: "modal-custom-layer-thermal-conductivity", "data-qa-id": "customLayerThermalConductivityLabel" }, "Thermal Conductivity"),
                            React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(TextField, { id: "modal-custom-layer-thermal-conductivity", "data-qa-id": "customLayerThermalConductivityInput", placeholder: "0.0", variant: "outlined", className: styles.customLayerField, value: customReferenceLayer.thermalConductivity || '', disabled: isLocked, onChange: (event) => updateCustomReferenceLayer({ thermalConductivity: event.target.value, thermalResistance: null }) })),
                                React.createElement(Grid, { item: true, xs: 2 },
                                    React.createElement("div", { className: styles.inputUnit, "data-qa-id": "customLayerThermalConductivityUnits" }, "W/mk"))))),
                        shouldShowThermalResistance(customReferenceLayer) && (React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(InputLabel, { htmlFor: "modal-custom-layer-thermal-resistance", "data-qa-id": "customLayerThermalResistanceLabel" }, "Thermal Resistance"),
                            React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(TextField, { id: "modal-custom-layer-thermal-resistance", "data-qa-id": "customLayerThermalResistanceInput", placeholder: "0.0", variant: "outlined", className: styles.customLayerField, value: customReferenceLayer.thermalResistance || '', disabled: isLocked, onChange: (event) => updateCustomReferenceLayer({ thermalResistance: event.target.value, thermalConductivity: null }) })),
                                React.createElement(Grid, { item: true, xs: 2 },
                                    React.createElement("div", { className: styles.inputUnit, "data-qa-id": "customLayerThermalResistanceUnits" }, "m\u00B2K/W"))))),
                        React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(InputLabel, { htmlFor: "modal-custom-layer-inside-emissivity", "data-qa-id": "customLayerInsideEmissivityLabel" }, "Inside Emissivity"),
                            React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(TextField, { id: "modal-custom-layer-inside-emissivity", "data-qa-id": "customLayerInsideEmissivityInput", placeholder: "0.0", variant: "outlined", className: styles.customLayerField, value: customReferenceLayer.insideEmissivity, disabled: isLocked, onChange: (event) => updateCustomReferenceLayer({ insideEmissivity: event.target.value }) })))),
                        React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(InputLabel, { htmlFor: "modal-custom-layer-outside-emissivity", "data-qa-id": "customLayerOutsideEmissivityLabel" }, "Outside Emissivity"),
                            React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(TextField, { id: "modal-custom-layer-outside-emissivity", "data-qa-id": "customLayerOutsideEmissivityInput", placeholder: "0.0", variant: "outlined", className: styles.customLayerField, value: customReferenceLayer.outsideEmissivity, disabled: isLocked, onChange: (event) => updateCustomReferenceLayer({ outsideEmissivity: event.target.value }) }))))))))))));
}
