import React from 'react';
import { InputLabel, MenuItem, Select, Typography, } from '@material-ui/core';
export function BusinessUnitDropdown(props) {
    var _a;
    return (React.createElement(React.Fragment, null,
        React.createElement(InputLabel, null, "Business Unit"),
        React.createElement(Select, { fullWidth: true, id: "business-unit-id", value: props.businessUnitId, onChange: ({ target: { value } }) => props.setBusinessUnitId(value), variant: "outlined", disabled: props.disabled }, ((_a = props.businessUnits) !== null && _a !== void 0 ? _a : []).map(businessUnit => {
            return (React.createElement(MenuItem, { key: businessUnit.businessUnitId, value: businessUnit.businessUnitId },
                businessUnit.name,
                React.createElement(Typography, { variant: 'body2', display: 'inline', color: 'textSecondary' },
                    "\u00A0- Business Unit ",
                    businessUnit.businessUnitId,
                    " - ",
                    businessUnit.countryName)));
        }))));
}
