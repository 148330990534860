import React from 'react';
import { Grid, TextField, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TabPanel } from './TabPanel';
import styles from './LayerModal.module.scss';
export function FastenersTab(props) {
    const { masterData, clearFastenersDetails, targetIndex, currentTabIndex, fasteners, setFasteners, fastenersCrossArea, setFastenersCrossArea, fastenersNumber, setFastenersNumber, fastenersAlpha, fastenersThermalConductivity, fastenersCorrectionFactor, isLocked, } = props;
    return (React.createElement(TabPanel, { id: "layer-fasteners-details-panel", "data-qa-id": "layer-fasteners-details-panel", targetIndex: targetIndex, currentTabIndex: currentTabIndex, "aria-labelledby": "layer-fasteners-details-tab" },
        React.createElement("div", { className: styles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 5 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-fasteners-name", "data-qa-id": "layerFastenersNameLabel" }, "Mechanical Fasteners Name"),
                    React.createElement(Autocomplete, { id: "modal-layer-fasteners-name", "data-qa-id": "layerFastenersNameInput", options: masterData.mechanicalFasteners, autoHighlight: true, getOptionLabel: (material) => material.name, onChange: (event, fastener) => {
                            if (!fastener) {
                                clearFastenersDetails();
                                return;
                            }
                            setFasteners(fastener);
                            setFastenersCrossArea(fastener.defaultCrossArea);
                            setFastenersNumber(fastener.defaultNumber);
                        }, value: fasteners, disabled: isLocked, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { placeholder: "Browse catalogue...", variant: "outlined", inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: 'new-password' }) }))) })),
                React.createElement(Grid, { item: true, xs: 1 }),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-fasteners-cross-area", "data-qa-id": "layerFastenersCrossAreaLabel" }, "Cross Area"),
                    React.createElement(Grid, { container: true, alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 10 },
                            React.createElement(TextField, { id: "modal-layer-fasteners-cross-area", "data-qa-id": "layerFastenersCrossAreaInput", variant: "outlined", value: fastenersCrossArea, disabled: isLocked, placeholder: "0.000", onChange: (event) => setFastenersCrossArea(event.target.value) })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerFastenersCrossAreaUnits" }, "mm")))),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-fasteners-number", "data-qa-id": "layerFastenersNumberLabel" }, "No. of Fasteners"),
                    React.createElement(Grid, { container: true, alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 10 },
                            React.createElement(TextField, { id: "modal-layer-fasteners-number", "data-qa-id": "layerFastenersNumberInput", variant: "outlined", value: fastenersNumber, disabled: isLocked, placeholder: "0.000", onChange: (event) => setFastenersNumber(event.target.value) })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerFastenersNumberUnits" }, "/m\u00B2")))))),
        React.createElement("div", { className: styles.tabContentResults },
            React.createElement(Grid, { container: true, spacing: 10 },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "fastenersAlphaLabel" }, "Alpha"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "fastenersAlpha" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "fastenersAlphaValue" }, fastenersAlpha !== null && fastenersAlpha !== void 0 ? fastenersAlpha : '0.0'))))),
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "fastenersThermalConductivityLabel" }, "Fastener Thermal Conductivity"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "fastenersThermalConductivity" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "fastenersThermalConductivityValue" }, fastenersThermalConductivity !== null && fastenersThermalConductivity !== void 0 ? fastenersThermalConductivity : '0.000'),
                                    React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "fastenersThermalConductivityUnit" }, "W/mK")))))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "fastenersCorrectionFactorLabel" }, "Correction Factor"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "fastenersCorrectionFactor" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "fastenersCorrectionFactorValue" }, fastenersCorrectionFactor !== null && fastenersCorrectionFactor !== void 0 ? fastenersCorrectionFactor : '0.000'))))))))));
}
