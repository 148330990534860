import React, { useState } from 'react';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import styles from '../LayerModal/LayerModal.module.scss';
import { TabPanel } from '../LayerModal/TabPanel';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { calculationTitleSelector } from '../../store/selectors';
export function NotesTab(props) {
    const { selectedTab, calculationTitle, notes, setLocalState } = props;
    const calculationTitles = useSelector(calculationTitleSelector);
    const [filteredCalculationTitles, setFilteredCalculationTitles] = useState(calculationTitles);
    const filterCalculationTitles = (filterValue) => {
        var filteredList = [];
        calculationTitles.forEach(element => {
            if (element.title.includes(filterValue)) {
                filteredList.push(element);
            }
        });
        setFilteredCalculationTitles(filteredList);
    };
    return (React.createElement(TabPanel, { id: "notes-tab-panel", "data-qa-id": "notes-tab-panel", targetIndex: 2, currentTabIndex: selectedTab, "aria-labelledby": "notes-tab" },
        React.createElement("div", { className: styles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(InputLabel, { htmlFor: "calculation-notes-calculation-title", "data-qa-id": "calculationNotesCalculationTitleLabel" }, "Calculation Title"),
                    React.createElement(Grid, { item: true, xs: 10 },
                        React.createElement(Autocomplete, { id: "calculation-notes-calculation-title", "data-qa-id": "calculationNotesCalculationTitleInput", freeSolo: true, value: calculationTitle !== null && calculationTitle !== void 0 ? calculationTitle : calculationTitles[0].title, autoHighlight: true, disabled: !!props.isLocked, onInputChange: (event, newValue) => {
                                if (newValue) {
                                    filterCalculationTitles(newValue);
                                }
                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { calculationNotes: Object.assign(Object.assign({}, prevState.calculationNotes), { calculationTitle: newValue }) })));
                            }, options: filteredCalculationTitles.map((option) => option.title), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { variant: "outlined", fullWidth: true })) }))),
                React.createElement("div", { className: styles.verticalDivider }),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(InputLabel, { htmlFor: "calculation-notes-calculation-notes", "data-qa-id": "calculationNotesCalculationNotesLabel" }, "Supporting information about this calculation"),
                    React.createElement(Grid, { item: true, xs: 10 },
                        React.createElement(TextField, { id: "calculation-notes-calculation-notes", multiline: true, fullWidth: true, rows: 4, "data-qa-id": "calculationNotesCalculationNotesInput", variant: "outlined", value: notes || '', disabled: !!props.isLocked, onChange: (event) => {
                                const value = event.target.value;
                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { calculationNotes: Object.assign(Object.assign({}, prevState.calculationNotes), { notes: value }) })));
                            } })))))));
}
