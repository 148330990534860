import React from 'react';
import { Tabs, Tab, Fade } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClipboardIcon from '@material-ui/icons/Assignment';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import styles from './CalculationNavigation.module.scss';
export function CalculationNavigation({ items, currentItem, handleChange, handleTabClose, handleTabCopy }) {
    const { enqueueSnackbar } = useSnackbar();
    const handleTabClick = (item) => {
        copy(item);
        enqueueSnackbar(React.createElement("span", null,
            React.createElement(ClipboardIcon, { className: styles.clipboardIcon }),
            "Copied to clipboard"), { variant: 'default', autoHideDuration: 750, TransitionComponent: Fade });
    };
    const getTabContent = (item) => {
        const isSelected = item === currentItem;
        return (React.createElement("div", { className: `${styles.tabContent} ${isSelected ? styles.tabContentSelected : ''}` },
            React.createElement("div", { className: styles.tabLabel, "data-qa-id": "calculationTabLabel", onDoubleClick: () => handleTabClick(item) }, item),
            isSelected && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.tabCopyButtonContainer },
                    React.createElement(FileCopyIcon, { className: styles.tabCopyButton, "data-qa-id": "calculationCopyButton", onClick: () => handleTabCopy() })),
                React.createElement("div", { className: styles.tabCloseButtonContainer },
                    React.createElement(CancelIcon, { className: styles.tabCloseButton, "data-qa-id": "calculationCloseButton", onClick: () => handleTabClose() }))))));
    };
    return (React.createElement("div", { className: styles.container },
        React.createElement(Tabs, { value: currentItem || false, onChange: handleChange, scrollButtons: "auto", textColor: "primary", variant: "scrollable" }, items.map((item, index) => (React.createElement(Tab, { key: item, value: item, label: getTabContent(item), disableRipple: true, "data-qa-id": `calculation-tab-${index}` }))))));
}
