import axios from 'axios';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { authConfig, authProvider } from '../auth/authProvider';
import { getAppConfig } from '../config/config';
import { actionTypes } from '../actions/actionTypes';
import { getApiClientErrorMessage, getApiServerErrorMessage, isApiClientError, isApiServerError } from '../types/store/apiTypes';
function toastError(errorMessage, url, type) {
    console.warn(`Call to ${url} failed with ${type} Error`, errorMessage);
    toast(errorMessage, {
        type: type === 'Client' ? 'warning' : 'error',
        closeOnClick: false,
        onClick: () => {
            copy(errorMessage);
            toast.info('Message copied to clipboard');
        },
    });
}
export const callApi = async (dispatch, method, endpoint, requestBody, responseType) => {
    var _a;
    const url = `${getAppConfig().apiUrl}${endpoint}`;
    let accessToken;
    try {
        ({ accessToken } = await authProvider.acquireTokenSilent({
            scopes: [authConfig.apiScope],
        }));
    }
    catch (e) {
        console.warn('Could not retrieve new access token');
    }
    try {
        const requestConfig = Object.assign(Object.assign({ headers: Object.assign({}, (accessToken ? { Authorization: `Bearer ${accessToken}` } : {})), method,
            url }, (requestBody ? { data: requestBody } : {})), { responseType: responseType !== null && responseType !== void 0 ? responseType : 'json' });
        const { data } = await axios.request(requestConfig);
        dispatch({
            type: actionTypes.API_CALL_SUCCESSFUL,
        });
        return data;
    }
    catch (e) {
        const status = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status;
        if (status === 401) {
            dispatch({
                type: actionTypes.SET_UNAUTHENTICATED,
            });
        }
        else if (status === 403) {
            dispatch({
                type: actionTypes.SET_UNAUTHORISED,
            });
        }
        else {
            // If the response was a 400 Bad Request, and specifically in the API Client Error format, toast the error to the user so they know they need to fix something.
            // But also still throw the error, to allow calling code to handle the error in a more specific way, like stop loading animations and re-enable buttons.
            if (status === 400 && isApiClientError(e)) {
                toastError(getApiClientErrorMessage(e), url, 'Client');
            }
            // If the response was a 500 Internal Server Error, and specifically in the API Server Error format, toast the user to tell them something went wrong.
            // But also still throw the error, to allow calling code to handle the error in a more specific way, like stop loading animations and re-enable buttons.
            if (status === 500 && isApiServerError(e)) {
                toastError(getApiServerErrorMessage(e), url, 'Server');
            }
            throw e;
        }
    }
};
