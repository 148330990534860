import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Avatar, InputLabel, TextField, Typography, Box, CircularProgress, ListItemAvatar, List, ListItem, Backdrop, } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import { openCalculation } from '../../actions/calculationActions';
import { callApi } from '../../common/api';
import { utcDateStringToDate } from '../../common/dates';
import { inactiveCalculationsSelector, interimOrCurrentCalculationSelector } from '../../store/selectors';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './OpenCalculationModal.module.scss';
export function OpenCalculationModal({ onClose }) {
    const dispatch = useDispatch();
    const calculation = useSelector(interimOrCurrentCalculationSelector);
    const inactiveCalculations = useSelector(inactiveCalculationsSelector);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [error, setError] = React.useState(null);
    const [isOpening, setIsOpening] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [previousCalcs, setPreviousCalcs] = React.useState([]);
    const handleOpen = async (calculationId) => {
        var _a, _b, _c;
        const openCalculationIds = [calculation, ...inactiveCalculations].map(c => c && c.calculationId);
        if (openCalculationIds.includes(calculationId)) {
            setError('The requested calculation is already open, please try again.');
            return;
        }
        setIsOpening(true);
        try {
            await dispatch(openCalculation(calculationId));
            onClose();
        }
        catch (e) {
            const status = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status;
            const message = status === 400 /* Bad Request */
                ? `Unable to load calculation. ${(_c = (_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message}.`
                : status === 404 /* Not Found */
                    ? 'Your search did not match an existing calculation ID, please try again.'
                    : 'An unexpected error occurred.';
            setError(message);
            setIsOpening(false);
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        await handleOpen(searchTerm);
    };
    useEffect(() => {
        setIsLoading(true);
        callApi(dispatch, 'GET', '/calculations').then(result => {
            setPreviousCalcs(result !== null && result !== void 0 ? result : []);
            setIsLoading(false);
        }).catch(error => {
            console.error(error);
            setIsLoading(false);
        });
    }, [dispatch]);
    const calculationTitleIsSpecified = (calculation) => { var _a; return ((_a = calculation.calculationTitle) === null || _a === void 0 ? void 0 : _a.length) > 0 && calculation.calculationTitle !== calculation.applicationTypeName; };
    return (React.createElement(Dialog, { open: true, onClose: () => onClose, "aria-labelledby": "modal-open-calculation-title" },
        React.createElement("form", { "data-qa-id": "openCalculationModalForm", onSubmit: handleSubmit, noValidate: true },
            React.createElement("div", { className: modalStyles.modalHeader },
                React.createElement(DialogTitle, { id: "modal-open-calculation-title" },
                    React.createElement(Typography, { component: "span", variant: "h5" }, "Open Calculation"))),
            React.createElement(Backdrop, { open: isOpening, className: styles.loadingBackdrop },
                React.createElement(CircularProgress, null)),
            React.createElement("div", { className: modalStyles.modalContent },
                React.createElement(DialogContent, { className: styles.modalContent },
                    error && (React.createElement(Alert, { severity: "error", className: styles.alert, "data-qa-id": "openExistingCalculationError" }, error)),
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(InputLabel, { htmlFor: "openExistingCalculationSearchInput", "data-qa-id": "openExistingCalculationSearchInputLabel" }, "Existing Calculation ID"),
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 10 },
                                    React.createElement(TextField, { fullWidth: true, id: "openExistingCalculationSearchInput", "data-qa-id": "openExistingCalculationSearchInput", placeholder: 'e.g. "1-AA-200101-123000-5"', variant: "outlined", value: searchTerm, onChange: (event) => setSearchTerm(event.target.value) })),
                                React.createElement(Grid, { item: true, xs: 2 },
                                    React.createElement(Button, { fullWidth: true, type: "submit", "data-qa-id": "openExistingCalculationButton", onClick: handleSubmit, disabled: isOpening, startIcon: isOpening && React.createElement(CircularProgress, { size: 20 }), variant: "contained", color: "primary", disableElevation: true, className: styles.openButton }, "Open")))),
                        React.createElement(Grid, { item: true, xs: 12, className: styles.topMargin },
                            React.createElement(InputLabel, null, "Recent Calculations"),
                            isLoading && React.createElement(Box, { m: 2 },
                                React.createElement(CircularProgress, null)),
                            !isLoading &&
                                React.createElement(List, { className: styles.calculationList }, previousCalcs.map(calculation => (React.createElement(ListItem, { key: calculation.calculationId, button: true, onClick: async () => await handleOpen(calculation.calculationId) },
                                    React.createElement(ListItemAvatar, null,
                                        React.createElement(Avatar, null, calculation.advisorId)),
                                    React.createElement("div", null,
                                        React.createElement(Typography, { variant: 'h6' },
                                            calculation.calculationId,
                                            calculationTitleIsSpecified(calculation) && React.createElement("span", null,
                                                "\u00A0- ",
                                                calculation.calculationTitle)),
                                        React.createElement(Typography, null,
                                            calculation.applicationTypeName,
                                            " | ",
                                            calculation.businessUnitName),
                                        React.createElement(Typography, null, formatDistanceToNow(utcDateStringToDate(calculation.lastUpdated), { addSuffix: true }))))))))))),
            React.createElement("div", { className: modalStyles.modalActions },
                React.createElement(DialogActions, null,
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "openCalculationCloseButton", onClick: () => onClose(true), variant: "outlined", disabled: isOpening }, "Cancel"))))))));
}
