export var actionTypes;
(function (actionTypes) {
    // API action types
    actionTypes["API_CALL_SUCCESSFUL"] = "API_CALL_SUCCESSFUL";
    actionTypes["SET_UNAUTHENTICATED"] = "SET_UNAUTHENTICATED";
    actionTypes["SET_UNAUTHORISED"] = "SET_UNAUTHORISED";
    // MasterData action types
    actionTypes["FETCH_MASTER_DATA"] = "FETCH_MASTER_DATA";
    actionTypes["SET_IS_LOADING_MASTER_DATA"] = "SET_IS_LOADING_MASTER_DATA";
    // MasterDataUpload action types
    actionTypes["UPLOAD_MASTER_DATA"] = "UPLOAD_MASTER_DATA";
    // Calculation action types
    actionTypes["REGISTER_CALCULATION"] = "REGISTER_CALCULATION";
    actionTypes["CLOSE_ACTIVE_CALCULATION"] = "CLOSE_ACTIVE_CALCULATION";
    actionTypes["ADD_LAYER_TO_CALCULATION"] = "ADD_LAYER_TO_CALCULATION";
    actionTypes["EDIT_LAYER_IN_CALCULATION"] = "EDIT_LAYER_IN_CALCULATION";
    actionTypes["INTERIM_MOVE_LAYER_IN_CALCULATION"] = "INTERIM_MOVE_LAYER_IN_CALCULATION";
    actionTypes["MOVE_LAYER_IN_CALCULATION"] = "MOVE_LAYER_IN_CALCULATION";
    actionTypes["DUPLICATE_LAYER_IN_CALCULATION"] = "DUPLICATE_LAYER_IN_CALCULATION";
    actionTypes["REMOVE_LAYER_FROM_CALCULATION"] = "REMOVE_LAYER_FROM_CALCULATION";
    actionTypes["LOCK_CALCULATION"] = "LOCK_CALCULATION";
    actionTypes["ADD_CONSTRUCTION_DETAILS_TO_CALCULATION"] = "ADD_CONSTRUCTION_DETAILS_TO_CALCULATION";
    actionTypes["CHANGE_ACTIVE_CALCULATION"] = "CHANGE_ACTIVE_CALCULATION";
    actionTypes["MAKE_ALL_CALCULATIONS_INACTIVE"] = "MAKE_ALL_CALCULATIONS_INACTIVE";
    actionTypes["SET_ACTIVE_CALCULATION_DATA"] = "SET_ACTIVE_CALCULATION_DATA";
    actionTypes["CLEAR_CALCULATION_SAVE_SUCCESS"] = "CLEAR_CALCULATION_SAVE_SUCCESS";
    actionTypes["SET_CALCULATION_SAVE_SUCCESS"] = "SET_CALCULATION_SAVE_SUCCESS";
    actionTypes["UPDATE_CALCULATION_PROJECT"] = "UPDATE_CALCULATION_PROJECT";
    actionTypes["UPDATE_INACTIVE_CALCULATIONS_PROJECT"] = "UPDATE_INACTIVE_CALCULATIONS_PROJECT";
    actionTypes["UPDATE_CALCULATION_CONTACT"] = "UPDATE_CALCULATION_CONTACT";
    actionTypes["UPDATE_INACTIVE_CALCULATIONS_CONTACT"] = "UPDATE_INACTIVE_CALCULATIONS_CONTACT";
    // Interim calculation action types
    actionTypes["ADD_LAYER_TO_INTERIM_CALCULATION"] = "ADD_LAYER_TO_INTERIM_CALCULATION";
    actionTypes["EDIT_LAYER_IN_INTERIM_CALCULATION"] = "EDIT_LAYER_IN_INTERIM_CALCULATION";
    actionTypes["CLEAR_INTERIM_CALCULATION"] = "CLEAR_INTERIM_CALCULATION";
    // Condensation action types
    actionTypes["EDIT_ENV_SETTINGS"] = "EDIT_ENV_SETTINGS";
    actionTypes["EDIT_ENV_CONDITIONS"] = "EDIT_ENV_CONDITIONS";
    actionTypes["SAVE_ENV_CONDITIONS"] = "SAVE_ENV_CONDITIONS";
    //PDF action types
    actionTypes["EXPORT_PDF"] = "EXPORT_PDF";
    actionTypes["CLEAR_PENDING_PDF_DOWNLOADS"] = "CLEAR_PENDING_PDF_DOWNLOADS";
    // User Profile types
    actionTypes["FETCH_USER_PROFILE"] = "FETCH_USER_PROFILE";
})(actionTypes || (actionTypes = {}));
