import { callApi } from '../common/api';
import { actionTypes } from './actionTypes';
export const associateProject = (project, isNewProject, shouldAssociateToAll) => {
    return async (dispatch, getState) => {
        var _a;
        const { calculation: { currentCalculation }, inactiveCalculations, } = getState();
        const calculationIds = shouldAssociateToAll
            ? [currentCalculation === null || currentCalculation === void 0 ? void 0 : currentCalculation.calculationId, ...inactiveCalculations.map(c => c.calculationId)]
            : [currentCalculation === null || currentCalculation === void 0 ? void 0 : currentCalculation.calculationId];
        const apiMethod = isNewProject ? 'POST' : 'PUT';
        const apiEndpoint = isNewProject ? '/Project' : `/Project/${project.id}`;
        const data = await callApi(dispatch, apiMethod, apiEndpoint, Object.assign(Object.assign({ calculationIds }, project), { projectUsage: project.projectUsage || undefined, region: project.region || undefined, enquiryType: project.enquiryType || undefined, projectStage: project.projectStage || undefined, projectCountyState: project.projectCountyState || undefined, projectStartDate: new Date((_a = project.projectStartDate) !== null && _a !== void 0 ? _a : new Date()).toDateString() }));
        if (!(data === null || data === void 0 ? void 0 : data.id))
            return;
        /**
         * Use "data" rather than the project param as the data from the API will
         * contain the generated unique ID.
         */
        dispatch({
            type: actionTypes.UPDATE_CALCULATION_PROJECT,
            payload: { project: data },
        });
        if (!shouldAssociateToAll)
            return;
        dispatch({
            type: actionTypes.UPDATE_INACTIVE_CALCULATIONS_PROJECT,
            payload: { project: data },
        });
    };
};
