/**
 * The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
 * Avoid creating an instance inside the component it will be recreated on each render.
 * If two providers are created on the same page it will cause authentication errors.
 */
import { LoginType, MsalAuthProvider } from 'react-aad-msal';
// These can be hard-coded as they are neither sensitive nor environment-specific.
export const authConfig = {
    authority: 'https://login.microsoftonline.com/9fc58307-b8c4-416e-bc27-eab7ad86b306/',
    clientId: '66245245-1195-40fa-905d-14c26834e97b',
    apiScope: 'api://b27ea26b-3888-4cd3-bbfa-4638b2dc0bac/access_user_data',
};
export const authProvider = new MsalAuthProvider({
    auth: {
        authority: authConfig.authority,
        clientId: authConfig.clientId,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
}, {
    scopes: ['openid'],
}, {
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: window.location.origin + '/auth.html',
});
