import React from 'react';
import { Grid, InputLabel, Select, MenuItem, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import { TabPanel } from '../LayerModal/TabPanel';
import { interstitialInterfaceSelector } from '../../store/selectors';
import modalStyles from './CondensationRiskModal.module.scss';
import tableStyles from './EnvConditionsTab.module.scss';
import { useSelector } from 'react-redux';
const dateTimeFormat = new Intl.DateTimeFormat('en-GB', { month: 'short' });
const monthColumns = new Array(12).fill({}).map((_, i) => ({
    field: `months[${i}]`,
    key: i + 1,
    title: dateTimeFormat.format(new Date(1, i, 1)),
}));
const columns = [
    ...[],
    {
        field: 'name',
        key: 0,
        render: (rowdata) => React.createElement("span", { className: tableStyles.tableCellContainer }, rowdata.condensationProperty),
        title: '',
    },
    ...monthColumns,
];
export function CondensationResultsTab({ currentTabIndex, targetIndex, isLocked }) {
    const interstitialInterfaces = useSelector(interstitialInterfaceSelector);
    const [selectedInterfaceId, setSelectedInterfaceId] = React.useState();
    const selectedInterface = interstitialInterfaces.find(i => i.id === selectedInterfaceId);
    const handleSelectedInterfaceIdChange = ({ target: { value } }) => {
        setSelectedInterfaceId(value);
    };
    const createTableRows = () => {
        if (selectedInterface) {
            return [
                {
                    condensationProperty: 'Saturation Vapour Pressure (kPa)',
                    months: selectedInterface.months.map(m => m.saturationVapourPressure),
                },
                {
                    condensationProperty: 'Vapour Pressure (kPa)',
                    months: selectedInterface.months.map(m => m.vapourPressure),
                },
                {
                    condensationProperty: 'Gc (kg/m2)',
                    months: selectedInterface.months.map(m => m.condensationAmount),
                },
                {
                    condensationProperty: 'Ma (kg/m2)',
                    months: selectedInterface.months.map(m => m.condensationAccumulationAmount),
                },
            ];
        }
        return [];
    };
    React.useEffect(() => {
        var _a;
        setSelectedInterfaceId((_a = interstitialInterfaces[0]) === null || _a === void 0 ? void 0 : _a.id);
    }, [interstitialInterfaces]);
    return (React.createElement(TabPanel, { id: "condensation-results-panel", "data-qa-id": "condensation-results-panel", targetIndex: targetIndex, currentTabIndex: currentTabIndex, "aria-labelledby": "condensation-results-tab" },
        React.createElement("div", { className: modalStyles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(InputLabel, { "data-qa-id": "interface-select-label", id: "interface-select-label" }, "Interface"),
                    React.createElement(Select, { fullWidth: true, "data-qa-id": "interface-select-input", id: "interface-select-input", value: (selectedInterface === null || selectedInterface === void 0 ? void 0 : selectedInterface.id) || '', onChange: handleSelectedInterfaceIdChange, variant: "outlined", disabled: isLocked || interstitialInterfaces.length === 0 }, interstitialInterfaces.map(interstitialInterface => {
                        return (React.createElement(MenuItem, { key: interstitialInterface.id, value: interstitialInterface.id }, interstitialInterface.name));
                    }))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(MaterialTable, { columns: columns, data: createTableRows(), options: {
                            sorting: false,
                            showTitle: false,
                            search: false,
                            paging: false,
                            toolbar: false,
                            grouping: false,
                            selection: false,
                            draggable: false, // Relates to draggable columns, not rows
                        }, components: {
                            Container: renderProps => (React.createElement("div", { className: tableStyles.tableContainer },
                                React.createElement(Paper, Object.assign({}, renderProps, { className: tableStyles.table, variant: "outlined" })))),
                        }, localization: {
                            body: {
                                emptyDataSourceMessage: 'No interfaces defined',
                            },
                        } }))))));
}
