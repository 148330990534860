import React from 'react';
import { TextField } from '@material-ui/core';
import { useDebounce } from '../../Utilities/hooks/useDebounce';
import { usePrevious } from '../../Utilities/hooks/usePrevious';
export function DebouncedDecimalInput(props) {
    const { delay, onChange, precision, textFieldId, value, disabled } = props;
    const [localValue, setLocalValue] = React.useState(value);
    const prevValue = usePrevious(value);
    const debouncedValue = useDebounce(localValue, delay);
    const prevDebouncedValue = usePrevious(debouncedValue);
    React.useEffect(() => {
        // handle prop change
        if (value !== prevValue) {
            setLocalValue(value);
            return;
        }
        // cast to number to allow for number being returned with zeroes added
        var newNumber = debouncedValue === '' ? NaN : Number(debouncedValue);
        var currentNumber = value === '' ? NaN : Number(value);
        if (debouncedValue !== prevDebouncedValue &&
            newNumber.toFixed(precision) !== currentNumber.toFixed(precision) &&
            (!isNaN(newNumber) || debouncedValue === '')) {
            onChange(debouncedValue);
        }
    }, [onChange, debouncedValue, value, prevDebouncedValue, prevValue, precision, setLocalValue]);
    return (React.createElement(TextField, { id: textFieldId, "data-qa-id": textFieldId, onChange: (event) => setLocalValue(event.target.value), placeholder: (0).toFixed(precision), value: localValue, variant: "outlined", disabled: disabled }));
}
