/**
 * Parses the input string, expected to be ISO format (YYYY-MM-DDTHH:mm:ss.sssZ) with or without trailing Z, and converts it to a Date.
 * It expects the input string to always be a UTC date, so ignores any timezone information from the input string, and always returns a UTC date.
 * This is to deal with the backend saving dates in UTC, and sending dates without timezone information, and Javascript's default being Local time.
 * The latest version of `date-fns` has a `parseJSON` which would have worked, but we can't pull that in because the Material UI library
 * is very out of date and conflicts with the latest version of date-fns, and updating Material UI is not feasible or appropriate for such a simple function.
 */
export function utcDateStringToDate(utcDateString) {
    const utcDateStringNoTz = /\d+-\d+-\d+T\d+:\d+:\d+\.\d+/.exec(utcDateString);
    return new Date(`${utcDateStringNoTz}Z`);
}
