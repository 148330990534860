import React, { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uuid } from '../../common/uuid';
import MaterialTable, { MTableCell, MTableHeader } from 'material-table';
import { Paper } from '@material-ui/core';
import { AddBox, ArrowDownward, Cancel, Check, ChevronLeft, ChevronRight, Clear, Edit, FileCopy, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn, Chat, } from '@material-ui/icons';
import classnames from 'classnames';
import styles from './LayersTable.module.scss';
import { interimOrCurrentCalculationSelector } from '../../store/selectors';
import { duplicateLayerInCalculation, removeLayerFromCalculation } from '../../actions/calculationActions';
import { LayersTableRow } from './LayersTableRow/LayersTableRow';
import iconBridged from '../../assets/icons/bridged.svg';
import iconFastened from '../../assets/icons/fastened.svg';
import iconBridgedAndFastened from '../../assets/icons/bridged-and-fastened.svg';
export var LayersTableMode;
(function (LayersTableMode) {
    LayersTableMode[LayersTableMode["THERMAL"] = 0] = "THERMAL";
    LayersTableMode[LayersTableMode["CONDENSATION"] = 1] = "CONDENSATION";
})(LayersTableMode || (LayersTableMode = {}));
const tableIcons = {
    Add: forwardRef((props, ref) => React.createElement(AddBox, Object.assign({}, props, { ref: ref }))),
    Check: forwardRef((props, ref) => React.createElement(Check, Object.assign({}, props, { ref: ref }))),
    Clear: forwardRef((props, ref) => React.createElement(Clear, Object.assign({}, props, { ref: ref }))),
    Delete: forwardRef((props, ref) => React.createElement(Cancel, Object.assign({}, props, { ref: ref }))),
    DetailPanel: forwardRef((props, ref) => React.createElement(ChevronRight, Object.assign({}, props, { ref: ref }))),
    Edit: forwardRef((props, ref) => React.createElement(Edit, Object.assign({}, props, { ref: ref }))),
    Export: forwardRef((props, ref) => React.createElement(SaveAlt, Object.assign({}, props, { ref: ref }))),
    Filter: forwardRef((props, ref) => React.createElement(FilterList, Object.assign({}, props, { ref: ref }))),
    FirstPage: forwardRef((props, ref) => React.createElement(FirstPage, Object.assign({}, props, { ref: ref }))),
    LastPage: forwardRef((props, ref) => React.createElement(LastPage, Object.assign({}, props, { ref: ref }))),
    NextPage: forwardRef((props, ref) => React.createElement(ChevronRight, Object.assign({}, props, { ref: ref }))),
    PreviousPage: forwardRef((props, ref) => React.createElement(ChevronLeft, Object.assign({}, props, { ref: ref }))),
    ResetSearch: forwardRef((props, ref) => React.createElement(Clear, Object.assign({}, props, { ref: ref }))),
    Search: forwardRef((props, ref) => React.createElement(Search, Object.assign({}, props, { ref: ref }))),
    SortArrow: forwardRef((props, ref) => React.createElement(ArrowDownward, Object.assign({}, props, { ref: ref }))),
    ThirdStateCheck: forwardRef((props, ref) => React.createElement(Remove, Object.assign({}, props, { ref: ref }))),
    ViewColumn: forwardRef((props, ref) => React.createElement(ViewColumn, Object.assign({}, props, { ref: ref }))),
};
const typesWithGroundAsOuterSurface = ['SolidGroundFloor', 'SuspendedFloor', 'BasementWall', 'BasementFloor'];
const typesWithSoilInterface = ['SolidGroundFloor', 'BasementWall', 'BasementFloor'];
const renderLabel = (text, unit) => (React.createElement("div", { className: styles.tableHeaderText },
    text,
    React.createElement("div", { className: styles.tableHeaderUnit },
        "(",
        unit,
        ")")));
const renderValueWithMonth = (value, month) => {
    if (value === '') {
        return '';
    }
    if (value === '0.0' || value === '-') {
        return '-';
    }
    return `${value} in ${month}`;
};
const descriptionColumn = {
    title: 'Layer Description',
    field: 'name',
};
const thermalColumns = [
    descriptionColumn,
    {
        field: 'icon',
        render: ({ layerBridging, mechanicalFastener }) => {
            if (!layerBridging && !mechanicalFastener) {
                return null;
            }
            if (layerBridging && mechanicalFastener) {
                return (React.createElement("img", { src: iconBridgedAndFastened, "data-qa-id": "iconBridgedAndFastened", title: "Bridged and fastened", className: styles.iconBridgedAndFastened, alt: "" }));
            }
            if (layerBridging) {
                return React.createElement("img", { src: iconBridged, "data-qa-id": "iconBridged", title: "Bridged", className: styles.iconBridged, alt: "" });
            }
            if (mechanicalFastener) {
                return React.createElement("img", { src: iconFastened, "data-qa-id": "iconFastened", title: "Fastened", className: styles.iconFastened, alt: "" });
            }
        },
    },
    {
        title: renderLabel('Thickness', 'mm'),
        field: 'thicknessMillimetres',
        type: 'numeric',
    },
    {
        title: renderLabel('Therm Cond', 'W/mK'),
        field: 'thermalConductivity',
        type: 'numeric',
    },
    {
        title: renderLabel('Therm Res', 'm²K/W'),
        field: 'thermalResistance',
        type: 'numeric',
    },
    {
        title: React.createElement("div", { className: styles.thermalBridgingColumnCell }, "Thermal Bridging"),
        render: ({ layerBridging }) => (React.createElement("div", { className: styles.thermalBridgingColumnCell }, layerBridging ? layerBridging.bridgeDescription : '')),
    },
];
const condensationColumns = [
    descriptionColumn,
    {
        title: renderLabel('V. Resistivity', 'MNs/gm'),
        field: 'vapourResistivity',
        type: 'numeric',
    },
    {
        title: renderLabel('V. Resistance', 'MNs/g'),
        field: 'vapourResistance',
        type: 'numeric',
    },
    {
        title: renderLabel('Interface Temp', '°C'),
        field: 'interfaceTemperature',
        type: 'numeric',
        render: (rowdata) => React.createElement("div", null, rowdata.interfaceTemperature),
    },
    {
        title: renderLabel('Dewpoint Temp', '°C'),
        field: 'dewpointTemperature',
        type: 'numeric',
        render: (rowdata) => React.createElement("div", null, rowdata.dewpointTemperature),
    },
    {
        title: renderLabel('Worst Build-up (Gc)', 'g/m²'),
        render: (rowdata) => (React.createElement("div", null, renderValueWithMonth(rowdata.peakCondensationAmount, rowdata.peakCondensationAmountMonth))),
        type: 'numeric',
    },
    {
        title: renderLabel('Peak Accumulation (Ma)', 'g/m²'),
        render: (rowdata) => (React.createElement("div", null, renderValueWithMonth(rowdata.peakCondensationAccumulationAmount, rowdata.peakCondensationAccumulationAmountMonth))),
        type: 'numeric',
    },
];
export function LayersTable({ layersTableMode, onLayerClick, isLocked }) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const dispatch = useDispatch();
    const calculation = useSelector(interimOrCurrentCalculationSelector);
    const layers = [
        {
            id: 0,
            instanceId: uuid(),
            name: 'Inside Surface',
            thicknessMillimetres: '-',
            thermalConductivity: '-',
            thermalResistance: (_b = (_a = calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails) === null || _a === void 0 ? void 0 : _a.insideSurfaceThermalResistance) !== null && _b !== void 0 ? _b : '',
            insideEmissivity: '-',
            outsideEmissivity: '-',
            vapourResistance: '-',
            vapourResistivity: '-',
            interfaceTemperature: '',
            dewpointTemperature: '',
            layerNotes: undefined,
            peakCondensationAccumulationAmount: '',
            peakCondensationAccumulationAmountMonth: '',
            peakCondensationAmount: '',
            peakCondensationAmountMonth: '',
        },
        ...(calculation && calculation.layers
            ? calculation.layers.map((layer) => {
                var _a;
                const associatedInterface = (_a = calculation.interstitialInterfaces) === null || _a === void 0 ? void 0 : _a.find(i => i.innerLayerInstanceId === layer.instanceId);
                return {
                    id: layer.id,
                    instanceId: layer.instanceId,
                    name: layer.name,
                    thicknessMillimetres: layer.thicknessMillimetres,
                    thermalConductivity: layer.thermalConductivity,
                    insideEmissivity: layer.insideEmissivity,
                    outsideEmissivity: layer.outsideEmissivity,
                    thermalResistance: layer.thermalResistance,
                    layerBridging: layer.layerBridging,
                    mechanicalFastener: layer.mechanicalFastener,
                    vapourResistance: layer.vapourResistance || '-',
                    vapourResistivity: layer.vapourResistivity || '-',
                    dewpointTemperature: (associatedInterface === null || associatedInterface === void 0 ? void 0 : associatedInterface.worstMonthDewpointTemperature) || '-',
                    interfaceTemperature: (associatedInterface === null || associatedInterface === void 0 ? void 0 : associatedInterface.worstMonthInterfaceTemperature) || '-',
                    layerNotes: layer.layerNotes,
                    peakCondensationAccumulationAmount: (associatedInterface === null || associatedInterface === void 0 ? void 0 : associatedInterface.peakCondensationAccumulationAmount) || '-',
                    peakCondensationAccumulationAmountMonth: (associatedInterface === null || associatedInterface === void 0 ? void 0 : associatedInterface.peakCondensationAccumulationAmountMonth) || '-',
                    peakCondensationAmount: (associatedInterface === null || associatedInterface === void 0 ? void 0 : associatedInterface.peakCondensationAmount) || '-',
                    peakCondensationAmountMonth: (associatedInterface === null || associatedInterface === void 0 ? void 0 : associatedInterface.peakCondensationAmountMonth) || '-',
                    isReadOnly: layer.isReadOnly,
                };
            })
            : []),
        {
            id: 0,
            instanceId: uuid(),
            name: typesWithGroundAsOuterSurface.includes((_d = (_c = calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails) === null || _c === void 0 ? void 0 : _c.type) !== null && _d !== void 0 ? _d : '') ? 'Ground' : 'Outside Surface',
            thicknessMillimetres: '-',
            thermalConductivity: '-',
            thermalResistance: (_f = (_e = calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails) === null || _e === void 0 ? void 0 : _e.outsideSurfaceThermalResistance) !== null && _f !== void 0 ? _f : '',
            insideEmissivity: '-',
            outsideEmissivity: '-',
            vapourResistance: '-',
            vapourResistivity: '-',
            interfaceTemperature: '',
            dewpointTemperature: '',
            peakCondensationAccumulationAmount: '',
            peakCondensationAccumulationAmountMonth: '',
            peakCondensationAmount: '',
            peakCondensationAmountMonth: '',
        },
    ];
    const hideLastInterface = LayersTableMode.CONDENSATION && layers.length > 2 && !typesWithSoilInterface.includes((_h = (_g = calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails) === null || _g === void 0 ? void 0 : _g.type) !== null && _h !== void 0 ? _h : '');
    return (React.createElement(MaterialTable, { icons: tableIcons, columns: layersTableMode === LayersTableMode.THERMAL ? thermalColumns : condensationColumns, data: layers, options: {
            actionsColumnIndex: layersTableMode === LayersTableMode.THERMAL ? 6 : 8,
            sorting: false,
            showTitle: false,
            search: false,
            paging: false,
            toolbar: false,
            grouping: false,
            selection: false,
            draggable: false, // Relates to draggable columns, not rows
        }, actions: [
            layer => ({
                icon: () => { var _a, _b; return React.createElement("div", null, ((_a = layer.layerNotes) === null || _a === void 0 ? void 0 : _a.notes) !== '' && ((_b = layer.layerNotes) === null || _b === void 0 ? void 0 : _b.notes) !== undefined ? React.createElement(Chat, null) : null); },
                onClick: (event, layer) => {
                    if (Array.isArray(layer))
                        return;
                    onLayerClick(layer.instanceId, 4);
                },
            }),
            layer => ({
                icon: () => (React.createElement("div", { "data-qa-id": "duplicateLayerButton", title: "Duplicate layer" },
                    React.createElement(FileCopy, null))),
                hidden: isLocked || layer.isReadOnly,
                onClick: (event, layer) => {
                    // Unlike selection, we're never expecting duplication of multiple records at once
                    if (Array.isArray(layer))
                        return;
                    if (layer.isReadOnly)
                        return;
                    dispatch(duplicateLayerInCalculation(layer.instanceId));
                },
            }),
            layer => ({
                icon: () => (React.createElement("div", { "data-qa-id": "deleteLayerButton", title: "Delete layer" },
                    React.createElement(Cancel, null))),
                hidden: isLocked || layer.isReadOnly,
                onClick: (event, layer) => {
                    // Unlike selection, we're never expecting deletion of multiple records at once
                    if (Array.isArray(layer))
                        return;
                    if (layer.isReadOnly)
                        return;
                    dispatch(removeLayerFromCalculation(layer.instanceId));
                },
            }),
        ], components: {
            Container: renderProps => (React.createElement("div", { className: classnames(styles.tableContainer, {
                    [styles.hideLastInterface]: hideLastInterface,
                }) },
                React.createElement(Paper, Object.assign({}, renderProps, { className: styles.table, variant: "outlined" })))),
            Header: renderProps => (React.createElement("div", { className: classnames(styles.tableHeaderContainer, {
                    [styles.condensation]: layersTableMode === LayersTableMode.CONDENSATION,
                    [styles.thermal]: layersTableMode === LayersTableMode.THERMAL,
                }) },
                React.createElement(MTableHeader, Object.assign({}, renderProps)))),
            Row: renderProps => (React.createElement(LayersTableRow, { renderProps: renderProps, onLayerClick: onLayerClick, disableDrag: isLocked || layersTableMode === LayersTableMode.CONDENSATION })),
            Cell: renderProps => (React.createElement("tbody", { className: classnames(styles.tableCellContainer, {
                    [styles.condensation]: layersTableMode === LayersTableMode.CONDENSATION,
                    [styles.thermal]: layersTableMode === LayersTableMode.THERMAL,
                }) },
                React.createElement(MTableCell, Object.assign({}, renderProps)))),
        } }));
}
