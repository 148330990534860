import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewCalculation } from '../../actions/calculationActions';
import { userProfileSelector } from '../../store/selectors';
import { BusinessUnitDropdown } from '../FormComponents/BusinessUnitDropdown/BusinessUnitDropdown';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './NewCalculationModal.module.scss';
const none = -1;
export function NewCalculationModal({ onClose, canClose }) {
    var _a;
    const dispatch = useDispatch();
    const userProfile = useSelector(userProfileSelector);
    const [businessUnitId, setBusinessUnitId] = useState((_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.primaryBusinessUnitId) !== null && _a !== void 0 ? _a : none);
    const [isCreating, setIsCreating] = useState(false);
    const handleCreate = async () => {
        setIsCreating(true);
        try {
            await dispatch(createNewCalculation(businessUnitId));
            onClose();
        }
        catch (e) {
            console.error('createNewCalculation client/server occured', e);
            setIsCreating(false);
        }
    };
    return (React.createElement(Dialog, { open: true, onClose: () => onClose, "aria-labelledby": "modal-new-calculation-title", disableBackdropClick: !canClose, disableEscapeKeyDown: !canClose },
        React.createElement("div", { className: modalStyles.modalHeader },
            React.createElement(DialogTitle, { id: "modal-new-calculation-title" },
                React.createElement(Typography, { component: "span", variant: "h5" }, "Create Calculation"))),
        React.createElement("div", { className: `${[modalStyles.modalContent, styles.modalContent].join(' ')}` },
            React.createElement(DialogContent, null,
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(BusinessUnitDropdown, { businessUnits: userProfile === null || userProfile === void 0 ? void 0 : userProfile.useableBusinessUnits, businessUnitId: businessUnitId, setBusinessUnitId: setBusinessUnitId, disabled: isCreating }))))),
        React.createElement("div", { className: modalStyles.modalActions },
            React.createElement(DialogActions, null,
                React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                    canClose && (React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                        React.createElement(Button, { "data-qa-id": "newCalculationCloseButton", disabled: isCreating, onClick: () => onClose(true), variant: "outlined" }, "Cancel"))),
                    React.createElement(Grid, { item: true, xs: 3 },
                        React.createElement(Button, { type: "button", "data-qa-id": "newCalculationSubmitButton", fullWidth: true, disabled: isCreating || ((businessUnitId !== null && businessUnitId !== void 0 ? businessUnitId : none) === none), onClick: handleCreate, color: "primary", variant: "contained", startIcon: isCreating && React.createElement(CircularProgress, { size: 20 }), autoFocus: true }, "Create New")))))));
}
