export var MasterDataType;
(function (MasterDataType) {
    MasterDataType["BALLAST_TYPES"] = "Ballast Types";
    MasterDataType["BRANDING"] = "Branding";
    MasterDataType["BRIDGE_MATERIALS"] = "Bridge Materials";
    MasterDataType["ENV_CON_BUILDING_REGIONS"] = "E.C. Building Regions";
    MasterDataType["ENV_CON_HUMIDITY_CLASSES"] = "E.C. Humidity Classes";
    MasterDataType["ENV_CON_RISK_LEVELS"] = "E.C. Risk Levels";
    MasterDataType["LAYERS"] = "Layers";
    MasterDataType["MECHANICAL_FASTENERS"] = "Mechanical Fasteners";
    MasterDataType["PRECIPITATION"] = "Precipitation";
    MasterDataType["PROJECT_REGIONS"] = "Project Regions";
    MasterDataType["PROJECT_USAGES"] = "Project Usages";
    MasterDataType["RAINSCREEN_CLADDING"] = "Rainscreen Cladding";
    MasterDataType["SOIL_TYPES"] = "Soil Types";
    MasterDataType["SALES_CONTACT_TEAMS"] = "Sales Contact Teams";
    MasterDataType["PROJECT_COUNTY_STATE"] = "Project County State";
    MasterDataType["PROJECT_STAGE"] = "Project Stage";
    MasterDataType["PRODUCT_SECTOR"] = "Product Sector";
    MasterDataType["CALCULATION_TITLE"] = "Calculation Title";
})(MasterDataType || (MasterDataType = {}));
