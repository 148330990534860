import { FloorInsulationLayerReferenceType, } from '../../types/store/calculationTypes';
export const noFloorReferenceOption = {
    id: null,
    type: FloorInsulationLayerReferenceType.None,
    name: 'None',
};
export const clientSpecifiedFloorReferenceOption = {
    id: null,
    type: FloorInsulationLayerReferenceType.Custom,
    name: 'Client Specified',
};
