import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select, Typography, } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Alert } from '@material-ui/lab';
import { uploadMasterData } from '../../actions/masterDataUploadActions';
import { MasterDataType } from '../../types/store/masterDataUploadTypes';
import { userProfileSelector } from '../../store/selectors';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { BusinessUnitDropdown } from '../FormComponents/BusinessUnitDropdown/BusinessUnitDropdown';
import styles from '../MasterDataUploadModal/MasterDataUploadModal.module.scss';
var UploadStatus;
(function (UploadStatus) {
    UploadStatus["PENDING"] = "PENDING";
    UploadStatus["ERROR"] = "ERROR";
    UploadStatus["SUCCESS"] = "SUCCESS";
})(UploadStatus || (UploadStatus = {}));
export function MasterDataUploadModal({ onClose }) {
    var _a;
    const dispatch = useDispatch();
    const userProfile = useSelector(userProfileSelector);
    const [dataType, setDataType] = React.useState(MasterDataType.LAYERS);
    const [businessUnitId, setBusinessUnitId] = React.useState((_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.primaryBusinessUnitId) !== null && _a !== void 0 ? _a : -1);
    const [file, setFile] = React.useState(null);
    const [uploadStatus, setUploadStatus] = React.useState(null);
    const [errors, setErrors] = React.useState(null);
    const handleDataTypeChange = ({ target: { value } }) => {
        setDataType(value);
    };
    const onDrop = ([acceptedFile]) => {
        setFile(acceptedFile);
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.csv',
        multiple: false,
        disabled: uploadStatus === UploadStatus.PENDING,
    });
    const handleSubmit = React.useCallback(async (event) => {
        var _a;
        event.preventDefault();
        if (dataType && file) {
            try {
                setUploadStatus(UploadStatus.PENDING);
                await dispatch(uploadMasterData(dataType, file, businessUnitId));
                setUploadStatus(UploadStatus.SUCCESS);
                setFile(null);
                setErrors(null);
            }
            catch (e) {
                setUploadStatus(UploadStatus.ERROR);
                setFile(null);
                const responseData = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data;
                const errorMessages = Array.isArray(responseData) ? responseData : null;
                console.error('uploadMasterData failed', errorMessages);
                setErrors(errorMessages);
            }
        }
    }, [dispatch, dataType, file, businessUnitId]);
    return (React.createElement(Dialog, { open: true, onClose: () => onClose, "aria-labelledby": "modal-master-data-upload-title", disableBackdropClick: uploadStatus === UploadStatus.PENDING, disableEscapeKeyDown: uploadStatus === UploadStatus.PENDING },
        React.createElement("form", { "data-qa-id": "newMasterDataUploadModalForm", onSubmit: handleSubmit, noValidate: true },
            React.createElement("div", { className: styles.modalHeader },
                React.createElement(DialogTitle, { id: "modal-master-data-upload-title" },
                    React.createElement(Typography, { component: "span", variant: "h5" }, "Upload Master Data"))),
            React.createElement("div", { className: styles.modalContent },
                React.createElement(DialogContent, null,
                    uploadStatus === UploadStatus.ERROR && !file && (React.createElement(Alert, { severity: "error", className: styles.alert },
                        "An error has occurred, please try again.",
                        errors && errors.length > 0 && React.createElement("ul", null, errors.map(error => React.createElement("li", null, error))))),
                    uploadStatus === UploadStatus.SUCCESS && !file && (React.createElement(Alert, { severity: "success", className: styles.alert }, "The file has been uploaded. Please refresh the page to use the latest data or upload another dataset.")),
                    React.createElement(Grid, { container: true, spacing: 3 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(BusinessUnitDropdown, { businessUnits: userProfile === null || userProfile === void 0 ? void 0 : userProfile.administrableBusinessUnits, businessUnitId: businessUnitId, setBusinessUnitId: setBusinessUnitId, disabled: uploadStatus === UploadStatus.PENDING })),
                        React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement("div", { className: styles.masterDataTypeContainer },
                                React.createElement(InputLabel, { "data-qa-id": "masterDataTypeLabel", id: "master-data-type-label" }, "Master Data Type"),
                                React.createElement(Select, { fullWidth: true, "data-qa-id": "masterDataTypeInput", id: "master-data-type-input", value: dataType, onChange: handleDataTypeChange, variant: "outlined", disabled: uploadStatus === UploadStatus.PENDING }, Object.values(MasterDataType).map(masterDataType => {
                                    return (React.createElement(MenuItem, { key: masterDataType, value: masterDataType }, masterDataType));
                                })))),
                        React.createElement(Grid, { item: true, xs: 8 },
                            React.createElement("div", { className: styles.dropzoneContainer },
                                React.createElement("div", Object.assign({}, getRootProps(), { className: styles.dropzone, "data-qa-id": "masterDataFileInputContainer" }),
                                    React.createElement("input", Object.assign({}, getInputProps(), { "data-qa-id": "masterDataFileInput" })),
                                    !file &&
                                        (isDragActive ? (React.createElement("span", null, "Drop the file here ...")) : (React.createElement("span", { "data-qa-id": "masterDataMessageWithoutFile" }, "Drop a file here, or click to select a file"))),
                                    file && (React.createElement("div", null,
                                        React.createElement(DescriptionOutlinedIcon, { className: styles.fileIcon }),
                                        React.createElement("span", { "data-qa-id": "masterDataMessageWithFile" },
                                            React.createElement("span", { className: styles.fileName },
                                                file.name,
                                                "\u00A0"),
                                            uploadStatus === UploadStatus.PENDING ? 'is uploading...' : 'is ready to be uploaded'))))))))),
            React.createElement("div", { className: styles.modalActions },
                React.createElement(DialogActions, null,
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "masterDataUploadCloseButton", onClick: () => onClose(true), variant: "outlined", disabled: uploadStatus === UploadStatus.PENDING }, "Cancel")),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(Button, { type: "submit", "data-qa-id": "masterDataUploadSubmitButton", fullWidth: true, onClick: handleSubmit, color: "primary", variant: "contained", disabled: !dataType || !file || uploadStatus === UploadStatus.PENDING || businessUnitId < 0, className: styles.uploadButton }, uploadStatus === UploadStatus.PENDING ? React.createElement(CircularProgress, { size: 28 }) : 'Upload'))))))));
}
