import React from 'react';
import download from 'downloadjs';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Typography, CircularProgress, InputLabel, MenuItem, Select, FormGroup, FormControlLabel, Checkbox, Tooltip, } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import { without, uniq } from 'lodash';
import { exportPdf } from '../../actions/pdfActions';
import { masterDataBradingSelector, pdfExportsSelector, userProfileSelector, interimOrCurrentCalculationSelector, pdfPendingDownloadsSelector, inactiveCalculationsByBusinessUnitSelector, } from '../../store/selectors';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './PdfModal.module.scss';
import { callApi } from '../../common/api';
import { actionTypes } from '../../actions/actionTypes';
var PreviewStatus;
(function (PreviewStatus) {
    PreviewStatus["PENDING"] = "PENDING";
    PreviewStatus["ERROR"] = "ERROR";
    PreviewStatus["SUCCESS"] = "SUCCESS";
})(PreviewStatus || (PreviewStatus = {}));
var ExportStatus;
(function (ExportStatus) {
    ExportStatus["PENDING"] = "PENDING";
    ExportStatus["ERROR"] = "ERROR";
    ExportStatus["SUCCESS"] = "SUCCESS";
})(ExportStatus || (ExportStatus = {}));
export function PdfModal(props) {
    var _a, _b, _c, _d;
    const { onClose } = props;
    const dispatch = useDispatch();
    const exports = useSelector(pdfExportsSelector);
    const pendingDownloads = useSelector(pdfPendingDownloadsSelector);
    const branding = useSelector(masterDataBradingSelector);
    const userProfile = useSelector(userProfileSelector);
    const currentCalculation = useSelector(interimOrCurrentCalculationSelector);
    const inactiveCalculations = useSelector(inactiveCalculationsByBusinessUnitSelector);
    const [previewStatus, setPreviewStatus] = React.useState(null);
    const [exportStatus, setExportStatus] = React.useState(null);
    const [selectedCalculations, setSelectedCalculations] = React.useState(currentCalculation ? [currentCalculation.calculationId] : []);
    const [previewErrors, setPreviewErrors] = React.useState([]);
    const [exportErrors, setExportErrors] = React.useState([]);
    const [shouldApplyWatermark, setShouldApplyWatermark] = React.useState(false);
    const userGroup = (_d = (_b = (_a = branding.find(b => b.adGroups === (userProfile === null || userProfile === void 0 ? void 0 : userProfile.primaryBusinessUnitId))) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : (_c = branding[0]) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : 0;
    const [brandId, setBrandId] = React.useState(userGroup);
    const changeTemplate = (event) => {
        setBrandId(event.target.value);
    };
    const countPreviewErrors = previewErrors.length;
    const countExportErrors = exportErrors.length;
    React.useEffect(() => {
        if (countPreviewErrors > 0) {
            setPreviewStatus(PreviewStatus.ERROR);
        }
        if (countExportErrors > 0) {
            setExportStatus(ExportStatus.ERROR);
        }
    }, [countPreviewErrors, countExportErrors]);
    const handlePreviewPdf = async () => {
        if (currentCalculation) {
            setPreviewErrors([]);
            setExportErrors([]);
            setPreviewStatus(PreviewStatus.PENDING);
            setExportStatus(null);
            try {
                await Promise.all(selectedCalculations.map(async (selectedCalculation) => {
                    try {
                        const data = await callApi(dispatch, 'GET', `/PDF/${selectedCalculation}/${brandId}?showWatermark=${shouldApplyWatermark}`, undefined, 'blob');
                        if (!data)
                            return;
                        const previewUrl = window.URL.createObjectURL(data);
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        window.open(previewUrl, '_blank');
                        window.URL.revokeObjectURL(previewUrl);
                    }
                    catch (e) {
                        setPreviewErrors(prevState => [...prevState, selectedCalculation]);
                        throw e;
                    }
                }));
                setPreviewStatus(PreviewStatus.SUCCESS);
            }
            catch (e) { }
        }
    };
    React.useEffect(() => {
        pendingDownloads.forEach(({ uploadSuccess, pdfFile, fileName, calculationId }) => {
            if (uploadSuccess) {
                if (pdfFile && fileName) {
                    download(pdfFile, fileName, 'application/pdf');
                }
                setExportStatus(ExportStatus.SUCCESS);
            }
            else {
                setExportErrors(prevState => uniq([...prevState, calculationId]));
                setExportStatus(ExportStatus.ERROR);
            }
        });
    }, [pendingDownloads]);
    const handleExportPdf = React.useCallback(async (event) => {
        event.preventDefault();
        setPreviewErrors([]);
        setExportErrors([]);
        setPreviewStatus(null);
        setExportStatus(ExportStatus.PENDING);
        try {
            await Promise.all(selectedCalculations.map(async (selectedCalculation) => {
                const exported = exports.find(e => e.calculationId === selectedCalculation);
                if (!(exported === null || exported === void 0 ? void 0 : exported.uploadSuccess)) {
                    try {
                        await dispatch(exportPdf(selectedCalculation, brandId, shouldApplyWatermark));
                        dispatch({
                            type: actionTypes.CLEAR_PENDING_PDF_DOWNLOADS,
                        });
                    }
                    catch (e) {
                        setExportErrors(prevState => [...prevState, selectedCalculation]);
                        throw e;
                    }
                }
            }));
        }
        catch (e) { }
    }, [dispatch, brandId, exports, selectedCalculations, shouldApplyWatermark]);
    const updateCalculationsList = React.useCallback((isChecked, calculationId) => {
        if (isChecked) {
            setSelectedCalculations(prevState => [...prevState, calculationId]);
        }
        else {
            setSelectedCalculations(prevState => without(prevState, calculationId));
        }
    }, [setSelectedCalculations]);
    const areNoCalculationsSelected = selectedCalculations.length === 0;
    const areSomeNotAllCalculationsSelected = selectedCalculations.length !== 0 && selectedCalculations.length !== inactiveCalculations.length + 1;
    const areAllCalculationsSelected = selectedCalculations.length === inactiveCalculations.length + 1;
    const areOnlyExportedCalculationsSelected = !areNoCalculationsSelected && selectedCalculations.every(c => { var _a; return (_a = exports.find(e => e.calculationId === c)) === null || _a === void 0 ? void 0 : _a.uploadSuccess; });
    const disableExport = areNoCalculationsSelected ||
        areOnlyExportedCalculationsSelected ||
        previewStatus === PreviewStatus.PENDING ||
        exportStatus === ExportStatus.PENDING;
    const renderExportStatusIcon = (pdfExport) => pdfExport ? (React.createElement(Tooltip, { arrow: true, placement: "right", title: "Open in SharePoint" },
        React.createElement("a", { href: pdfExport.url, target: "_blank", rel: "noopener noreferrer", className: styles.exportIconContainer },
            React.createElement(CloudDoneIcon, { className: styles.cloudDoneIcon })))) : (React.createElement(Tooltip, { arrow: true, placement: "right", title: "Not yet exported to SharePoint" },
        React.createElement("span", { className: styles.exportIconContainer },
            React.createElement(CloudOutlinedIcon, { className: styles.cloudIcon }))));
    return (React.createElement(Dialog, { open: true, onClose: onClose },
        React.createElement("form", { "data-qa-id": "pdfModalForm", onSubmit: handleExportPdf, noValidate: true },
            React.createElement("div", { className: styles.modalHeader },
                React.createElement(DialogTitle, { id: "modal-layer-title" },
                    React.createElement(Typography, { component: "span", variant: "h5" }, "Export PDF"))),
            React.createElement("div", { className: `${[modalStyles.modalContent, styles.modalContent].join(' ')}` },
                React.createElement(DialogContent, null,
                    previewStatus === PreviewStatus.ERROR && (React.createElement(Alert, { severity: "error", className: styles.alert, "data-qa-id": "previewPdfError" },
                        "Could not generate a preview for the following calculation(s):",
                        React.createElement("ul", null, previewErrors.map(id => (React.createElement("li", { key: id }, id)))))),
                    exportStatus === ExportStatus.ERROR && (React.createElement(Alert, { severity: "error", className: styles.alert, "data-qa-id": "exportPdfError" },
                        "Could not export the following calculation(s) to SharePoint:",
                        React.createElement("ul", null, exportErrors.map(id => (React.createElement("li", { key: id }, id)))))),
                    React.createElement(Grid, { container: true, spacing: 3 },
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement("div", { className: styles.checkboxListContainer, "data-qa-id": "calculationCheckboxContainer" },
                                React.createElement("div", { className: styles.checkboxList },
                                    React.createElement(FormControlLabel, { label: React.createElement(InputLabel, { htmlFor: "pdfSelectCalculationsCheckbox", "data-qa-id": "pdfSelectCalculationsLabel" }, "Select Calculations:"), control: React.createElement("div", { className: styles.raisedCheckboxContainer },
                                            React.createElement(Checkbox, { id: "pdfSelectCalculationsCheckbox", "data-qa-id": "pdfSelectCalculationsCheckbox", name: 'All', checked: areAllCalculationsSelected, indeterminate: areSomeNotAllCalculationsSelected, onChange: (_, isChecked) => {
                                                    if (!currentCalculation)
                                                        return;
                                                    if (isChecked) {
                                                        setSelectedCalculations(areSomeNotAllCalculationsSelected
                                                            ? []
                                                            : [currentCalculation.calculationId, ...inactiveCalculations.map(({ calculationId }) => calculationId)]);
                                                    }
                                                    else {
                                                        setSelectedCalculations([]);
                                                    }
                                                } })) }),
                                    React.createElement("hr", null),
                                    currentCalculation && (React.createElement(FormGroup, null,
                                        React.createElement(FormControlLabel, { key: "FormControlLabel-currentCalculation", label: React.createElement("div", null,
                                                React.createElement("span", { className: styles.checkboxListLabel }, currentCalculation.calculationId),
                                                React.createElement("span", { className: styles.checkboxListLabelEmphasis }, "(current)"),
                                                renderExportStatusIcon(exports.find(pdf => pdf.uploadSuccess && pdf.calculationId === currentCalculation.calculationId))), control: React.createElement(Checkbox, { key: currentCalculation.calculationId, name: currentCalculation.calculationId, checked: selectedCalculations.includes(currentCalculation.calculationId), onChange: (_, isChecked) => updateCalculationsList(isChecked, currentCalculation.calculationId) }) }),
                                        inactiveCalculations.map(inactiveCalculation => (React.createElement(FormControlLabel, { key: `FormControlLabel-inactiveCalculation-${inactiveCalculation.calculationId}`, label: React.createElement("div", null,
                                                React.createElement("span", null, inactiveCalculation.calculationId),
                                                renderExportStatusIcon(exports.find(pdf => pdf.uploadSuccess && pdf.calculationId === inactiveCalculation.calculationId))), control: React.createElement(Checkbox, { key: inactiveCalculation.calculationId, name: inactiveCalculation.calculationId, checked: selectedCalculations.includes(inactiveCalculation.calculationId), onChange: (_, isChecked) => updateCalculationsList(isChecked, inactiveCalculation.calculationId) }) })))))))),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(InputLabel, { htmlFor: "pdfTemplateInput", "data-qa-id": "pdfTemplateLabel" }, "Select Template:"),
                            React.createElement(Select, { fullWidth: true, "data-qa-id": "pdfTemplateInput", id: "pdfTemplateInput", value: brandId, onChange: changeTemplate, variant: "outlined" }, branding.map(({ id, brandName, country }) => (React.createElement(MenuItem, { key: id, value: id },
                                brandName,
                                " ",
                                country)))),
                            React.createElement("div", { className: styles.watermarkCheckboxContainer, "data-qa-id": "watermarkCheckboxContainer" },
                                React.createElement(FormControlLabel, { label: "Apply a 'Not Recommended' watermark", control: React.createElement(Checkbox, { name: "watermark", checked: shouldApplyWatermark, onChange: (_, isChecked) => setShouldApplyWatermark(isChecked) }) })))))),
            React.createElement("div", { className: styles.modalActions },
                React.createElement(DialogActions, null,
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 6, justify: "flex-start", alignItems: "center" },
                            React.createElement("span", null,
                                React.createElement("b", null, "Note"),
                                ": Exporting will lock the calculation")),
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "cancelPdfButton", onClick: onClose, variant: "outlined" }, "Cancel")),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(Button, { type: "button", onClick: handlePreviewPdf, "data-qa-id": "previewPdfButton", disabled: areNoCalculationsSelected || previewStatus === PreviewStatus.PENDING || exportStatus === ExportStatus.PENDING, fullWidth: true, color: "primary", variant: "outlined" }, previewStatus === PreviewStatus.PENDING ? React.createElement(CircularProgress, { size: 28 }) : 'Preview')),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(Button, { type: "submit", "data-qa-id": "exportPdfButton", disabled: disableExport, fullWidth: true, color: "primary", variant: "contained" }, exportStatus === ExportStatus.PENDING ? React.createElement(CircularProgress, { size: 28 }) : 'Export'))))))));
}
