import { callApi } from '../common/api';
const utf8EncodeFile = async (file) => new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = resolve;
    reader.readAsText(file);
});
export const uploadMasterData = (dataType, file, businessUnitId) => {
    return async (dispatch) => {
        var _a;
        await callApi(dispatch, 'POST', `/MasterDataUpload`, {
            masterDataType: dataType,
            csvFormData: (_a = (await utf8EncodeFile(file)).target) === null || _a === void 0 ? void 0 : _a.result,
            businessUnitId,
        });
    };
};
