import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import styles from './CondensationRiskModal.module.scss';
import { CondensationRiskTabs } from './CondensationRiskTabs';
import { useDispatch, useSelector } from 'react-redux';
import { clearInterimCalculation, saveEnvConditions } from '../../actions/calculationActions';
import { interimOrCurrentCalculationSelector } from '../../store/selectors';
export function CondensationRiskModal(props) {
    const dispatch = useDispatch();
    const [isInvalid, setIsInvalid] = React.useState(true);
    const calculation = useSelector(interimOrCurrentCalculationSelector);
    const handleDialogCancel = React.useCallback(() => {
        dispatch(clearInterimCalculation());
        props.onClose();
    }, [dispatch, props]);
    const handleSaveEnvConditions = React.useCallback((event) => {
        event.preventDefault();
        dispatch(saveEnvConditions());
        props.onClose();
    }, [dispatch, props]);
    return (React.createElement(Dialog, { open: true, onClose: handleDialogCancel, scroll: "paper", "aria-labelledby": "condensation-risk-title" },
        React.createElement("div", { className: styles.modalHeader },
            React.createElement(DialogTitle, { id: "condensation-risk-title" },
                React.createElement(Typography, { component: "span", variant: "h5" }, "Condensation Risk"))),
        React.createElement("div", { className: styles.modalContent },
            React.createElement(DialogContent, { dividers: true },
                React.createElement(CondensationRiskTabs, { setIsInvalid: setIsInvalid, isLocked: calculation === null || calculation === void 0 ? void 0 : calculation.locked }))),
        React.createElement("div", { className: styles.modalActions },
            React.createElement(DialogActions, null,
                React.createElement("form", { "data-qa-id": "condensationRiskModalForm", onSubmit: handleSaveEnvConditions, noValidate: true },
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "envConditionsCloseButton", onClick: handleDialogCancel, variant: "outlined" }, "Cancel")),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(Button, { type: "submit", "data-qa-id": "envConditionsSubmitButton", disabled: (calculation === null || calculation === void 0 ? void 0 : calculation.locked) || isInvalid, fullWidth: true, onClick: handleSaveEnvConditions, color: "primary", variant: "contained" }, "Save"))))))));
}
