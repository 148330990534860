import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Button, InputLabel, TextField, Paper, } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import styles from './EnvConditionsEditorModal.module.scss';
export const EnvConditionsEditorModal = ({ envConditions, monthKey, onClose, onMoveToNextMonth, onMoveToPrevMonth, onSubmit, isLocked, }) => {
    const dateTimeFormat = new Intl.DateTimeFormat('en-GB', { month: 'long' });
    const [editableConditions, setEditableConditions] = React.useState(envConditions.map(e => ({
        envConditionType: e.envConditionType,
        name: e.name,
        months: e.months.map(m => ({
            id: m.id,
            name: m.name,
            overridden: false,
            value: m.value,
        })),
        unit: e.unit,
    })));
    const handleDialogCancel = () => {
        onClose();
    };
    const overrides = editableConditions
        .filter(e => e.months.some(m => m.overridden))
        .map(e => ({
        envConditionType: e.envConditionType,
        months: e.months
            .filter(m => m.overridden)
            .map(m => ({
            id: m.id,
            value: m.value,
        })),
    }));
    const handleSubmitMonthValues = (event) => {
        event.preventDefault();
        onSubmit(overrides);
        onClose();
    };
    const isInvalid = overrides.length === 0 ||
        editableConditions.flatMap((o) => o.months.map(m => m.value)).some(v => v === '' || isNaN(Number(v)));
    const handleMonthValueChange = (envConditionType) => ({ target: { value } }) => {
        setEditableConditions(prevState => {
            const updatedConditions = cloneDeep(prevState);
            const conditionToUpdate = updatedConditions.find(o => o.envConditionType === envConditionType);
            if (conditionToUpdate) {
                const monthToUpdate = conditionToUpdate.months.find(m => m.id === monthKey);
                if (monthToUpdate) {
                    monthToUpdate.overridden = true;
                    monthToUpdate.value = value;
                    return updatedConditions;
                }
            }
            return prevState;
        });
    };
    return (React.createElement(Dialog, { open: true, onClose: handleDialogCancel, "aria-labelledby": "condensation-risk-title", maxWidth: "xs" },
        React.createElement("form", { "data-qa-id": "envConditionsEditorForm", onSubmit: handleSubmitMonthValues, noValidate: true },
            React.createElement("div", { className: styles.modalHeader },
                React.createElement(DialogTitle, { id: "condensation-risk-title" }, "Update Values")),
            React.createElement("div", { className: styles.modalContent },
                React.createElement(DialogContent, { className: styles.envConditionData },
                    React.createElement(Grid, { container: true, spacing: 3 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Grid, { container: true, justify: "center" },
                                React.createElement(Grid, { item: true, xs: 2, container: true, justify: "flex-end" },
                                    React.createElement(Button, { "data-qa-id": "editEnvConditionsPrevMonthButton", disabled: monthKey < 2, onClick: onMoveToPrevMonth }, '<')),
                                React.createElement(Grid, { item: true, xs: 2, container: true, alignItems: "center", justify: "center" },
                                    React.createElement(Typography, { align: "center" }, dateTimeFormat.format(new Date(1, monthKey - 1, 1)))),
                                React.createElement(Grid, { item: true, xs: 2, container: true, justify: "flex-start" },
                                    React.createElement(Button, { "data-qa-id": "editEnvConditionsNextMonthButton", disabled: monthKey > 11, onClick: onMoveToNextMonth }, '>')))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Paper, null,
                                React.createElement("div", { className: styles.envConditions },
                                    React.createElement(Grid, { container: true, direction: "row", justify: "space-around", spacing: 2 }, editableConditions.map(({ envConditionType, name, months, unit }) => {
                                        var _a;
                                        return (React.createElement(Grid, { key: envConditionType, item: true, xs: 6 },
                                            React.createElement(InputLabel, { "data-qa-id": `envCondition${envConditionType}Label`, id: `env-condition-${envConditionType}-label` }, name),
                                            React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                                                React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(TextField, { className: styles.envConditionEditBox, id: `env-condition-${envConditionType}`, "data-qa-id": `envCondition${envConditionType}Input`, placeholder: "0.0", variant: "outlined", value: (_a = months[monthKey - 1]) === null || _a === void 0 ? void 0 : _a.value, onChange: handleMonthValueChange(envConditionType), disabled: isLocked })),
                                                React.createElement(Grid, { item: true, xs: 2 },
                                                    React.createElement("div", { className: styles.inputUnit, "data-qa-id": `envCondition${envConditionType}Units` }, unit)))));
                                    })))))))),
            React.createElement("div", { className: styles.modalActions },
                React.createElement(DialogActions, null,
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "editEnvConditionsCloseButton", onClick: handleDialogCancel, variant: "outlined" }, "Cancel")),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(Button, { type: "submit", "data-qa-id": "editEnvConditionsSubmitButton", disabled: isLocked || isInvalid, fullWidth: true, onClick: handleSubmitMonthValues, color: "primary", variant: "contained" }, "Done"))))))));
};
