import React from 'react';
import { Grid } from '@material-ui/core';
import styles from './VerticalAlign.module.scss';
export function VerticalAlign({ align, children }) {
    let justifications = {
        top: 'flex-start',
        center: 'center',
        bottom: 'flex-end',
    };
    return (React.createElement(Grid, { container: true, direction: "column", justify: justifications[align], className: styles.fullHeight }, children));
}
