import { actionTypes } from '../actions/actionTypes';
const initialState = null;
export const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USER_PROFILE:
            return action.payload.userProfile;
        default:
            return state;
    }
};
