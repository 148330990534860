import React from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import styles from './LandingPage.module.scss';
import logo from '../../assets/images/kingspan-logo.svg';
import { VerticalAlign } from '../Utilities/VerticalAlign/VerticalAlign';
export const LandingPage = ({ qaIdBase, shouldShowLoadingIcon, title, content, buttonText, onButtonClick }) => {
    return (React.createElement("div", { className: styles.landingPageContainer, "data-qa-id": qaIdBase },
        React.createElement("div", { className: styles.landingPage },
            React.createElement(VerticalAlign, { align: "center" },
                React.createElement("div", { className: styles.logoContainer },
                    React.createElement("img", { src: logo, alt: "Logo", className: styles.logo })),
                React.createElement("div", { className: styles.titleContainer },
                    React.createElement(Typography, { variant: "h4", className: styles.title },
                        shouldShowLoadingIcon && (React.createElement("div", { className: styles.loadingIconContainer },
                            React.createElement(CircularProgress, { size: 24 }))),
                        React.createElement("span", { "data-qa-id": `${qaIdBase}-title` }, title))),
                React.createElement("div", { className: styles.contentContainer },
                    React.createElement("div", { className: styles.content, "data-qa-id": `${qaIdBase}-content` }, content)),
                buttonText && onButtonClick && (React.createElement("div", { className: styles.buttonContainer },
                    React.createElement(Button, { variant: "contained", color: "primary", disableElevation: true, onClick: onButtonClick, "data-qa-id": `${qaIdBase}-button` }, buttonText)))))));
};
