import React from 'react';
import { CircularProgress, Grid, Tooltip } from '@material-ui/core';
import { Info, Lock } from '@material-ui/icons';
import classnames from 'classnames';
import styles from './CalculationResults.module.scss';
export function CalculationResults(props) {
    return (React.createElement("div", { className: styles.resultsContainer },
        React.createElement("div", { className: styles.results },
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("div", { className: `${styles.result} ${styles.businessUnitPanel}` }, props.isLoadingMasterData === true
                        ? React.createElement("div", { className: styles.resultValueText },
                            React.createElement(CircularProgress, null))
                        : React.createElement(React.Fragment, null,
                            React.createElement("div", { className: styles.resultTitle }, "Business Unit"),
                            React.createElement("div", { className: styles.resultValueText }, props.businessUnit
                                ? React.createElement(Tooltip, { title: React.createElement(React.Fragment, null,
                                        "Business Unit ",
                                        props.businessUnit.businessUnitId,
                                        React.createElement("br", null),
                                        props.businessUnit.countryName) },
                                    React.createElement("span", null, props.businessUnit.name))
                                : '—')))),
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("div", { className: `${styles.result} ${styles.resultCondensation}` },
                        React.createElement("div", { "data-qa-id": "condensationTitle", className: styles.resultTitle }, "Condensation Risk"),
                        React.createElement("div", { "data-qa-id": "condensationValue", className: classnames(styles.resultValue, { [styles.hasCondensation]: props.condensationResult === 'Condensation' }) }, props.condensationResult || '—'))),
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("div", { className: `${styles.result} ${styles.resultTotalThermalResistance}` },
                        React.createElement("div", { "data-qa-id": "totalThermalResistanceTitle", className: styles.resultTitle }, "Total Thermal Resistance"),
                        React.createElement("div", { "data-qa-id": "totalThermalResistanceValue", className: styles.resultValue }, props.totalThermalResistance || '—'),
                        React.createElement("div", { "data-qa-id": "totalThermalResistanceUnit", className: styles.resultUnit }, "m\u00B2K/W"))),
                React.createElement(Grid, { item: true, xs: 3 },
                    React.createElement("div", { className: styles.result },
                        React.createElement("div", { "data-qa-id": "uValueTitle", className: styles.resultTitle }, "U-Value"),
                        React.createElement("div", { "data-qa-id": "uValueValue", className: styles.resultValue },
                            props.uValue || '—',
                            props.uValue2DP && (React.createElement("span", { "data-qa-id": "uValueValueSub", className: styles.resultValueSub },
                                "(",
                                props.uValue2DP,
                                ")")),
                            props.isTotalCorrectionFactorBelowThreshold && (React.createElement("span", { "data-qa-id": "uValueValueInfo", className: styles.resultValueTotalCorrectionInfo },
                                React.createElement(Tooltip, { "data-qa-id": "uValueValueInfoTooltip", title: "Correction factors have been neglected in this calculation, as they are less than 3% of the uncorrected U-value when combined.", arrow: true },
                                    React.createElement(Info, { "data-qa-id": "uValueValueInfoIcon", fontSize: 'small' })))),
                            props.isLocked && (React.createElement("span", { "data-qa-id": "uValueCalculationLocked", className: styles.resultValueCalculationLocked },
                                React.createElement(Tooltip, { "data-qa-id": "uValueCalculationLockedTooltip", title: "This calculation is locked as it has already been exported", arrow: true },
                                    React.createElement(Lock, { "data-qa-id": "uValueCalculationLockedIcon", fontSize: 'small' }))))),
                        React.createElement("div", { "data-qa-id": "uValueUnit", className: styles.resultUnit }, "W/m\u00B2K")))))));
}
