import React from 'react';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import styles from '../LayerModal/LayerModal.module.scss';
import { TabPanel } from '../LayerModal/TabPanel';
export function LayerNotesTab(props) {
    const { notes, setLayerNotes, targetIndex, currentTabIndex, isLocked } = props;
    return (React.createElement(TabPanel, { id: "layer-notes-tab-panel", "data-qa-id": "layer-notes-tab-panel", targetIndex: targetIndex, currentTabIndex: currentTabIndex, "aria-labelledby": "layer-notes-tab" },
        React.createElement("div", { className: styles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(InputLabel, { htmlFor: "layer-notes", "data-qa-id": "layerNotesLabel" }, "Supporting information about this layer"),
                    React.createElement(Grid, { item: true, xs: 10 },
                        React.createElement(TextField, { id: "layer-notes", multiline: true, fullWidth: true, rows: 4, "data-qa-id": "layerNotesInput", variant: "outlined", value: notes || '', disabled: isLocked, onChange: (event) => setLayerNotes(event.target.value) })))))));
}
