export function isApiClientError(e) {
    var _a;
    const responseData = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data;
    const candidate = responseData;
    return candidate !== null && candidate !== undefined &&
        typeof candidate === 'object' &&
        typeof candidate.message === 'string' &&
        typeof candidate.errorCode === 'string';
}
function getGenericErrorMessage(e) {
    var _a;
    const statusCode = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.status;
    return statusCode ? `Unknown error (status code ${statusCode})` : 'Unknown error';
}
export function getApiClientErrorMessage(e) {
    return isApiClientError(e) ? e.response.data.message : getGenericErrorMessage(e);
}
export function isApiServerError(e) {
    var _a;
    const responseData = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data;
    const candidate = responseData;
    return candidate !== null && candidate !== undefined &&
        typeof candidate === 'object' &&
        typeof candidate.errorMessage === 'string' &&
        typeof candidate.exception === 'string'; // Will always equal "[REACTED]" in non-Dev/Test environments
}
export function getApiServerErrorMessage(e) {
    return isApiServerError(e)
        ? `${e.response.data.errorMessage} (${e.response.data.exception.substring(0, 150)}) [${e.response.data.requestId}]`
        : getGenericErrorMessage(e);
}
