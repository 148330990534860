import React from 'react';
import { debounce } from 'lodash';
export const useDebounce = function (value, delay) {
    const [debouncedValue, setDebouncedValue] = React.useState(null);
    const debounceState = React.useRef(debounce(newValue => setDebouncedValue(newValue), delay));
    React.useEffect(() => {
        const { current } = debounceState;
        current(value);
        return () => current.cancel();
    }, [delay, value]);
    return debouncedValue;
};
