import { callApi } from '../common/api';
import { actionTypes } from './actionTypes';
export const fetchUserProfile = () => {
    return async (dispatch) => {
        const data = await callApi(dispatch, 'GET', '/Profile');
        if (!data)
            return;
        dispatch({
            type: actionTypes.FETCH_USER_PROFILE,
            payload: {
                userProfile: data,
            },
        });
    };
};
