export const isStringNotNullishOrWhitespace = (input) => input != null && input.trim().length > 0;
export const isStringValidPostiveNumber = (input) => isStringNotNullishOrWhitespace(input) && Number(input) > 0;
export const isStringValidNumberInRangeInclusive = (min, max, input) => {
    if (!isStringNotNullishOrWhitespace(input)) {
        return false;
    }
    const numberInput = Number(input);
    if (numberInput < min || numberInput > max) {
        return false;
    }
    return true;
};
