import { cloneDeep } from 'lodash';
import { actionTypes } from '../actions/actionTypes';
const initialState = {
    exports: [],
    pendingDownloads: [],
};
export const pdfReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EXPORT_PDF: {
            const pendingDownloads = [...state.pendingDownloads, action.payload.export];
            const existingIndex = state.exports.findIndex(r => r.calculationId === action.payload.export.calculationId);
            if (existingIndex === -1) {
                return {
                    exports: [...state.exports, action.payload.export],
                    pendingDownloads,
                };
            }
            const exports = cloneDeep(state.exports);
            exports.splice(existingIndex, 1, action.payload.export);
            return {
                exports,
                pendingDownloads,
            };
        }
        case actionTypes.CLEAR_PENDING_PDF_DOWNLOADS:
            return Object.assign(Object.assign({}, state), { pendingDownloads: [] });
        default:
            return state;
    }
};
