import { actionTypes } from './actionTypes';
import { callApi } from '../common/api';
import { lockCalculation } from './calculationActions';
export const exportPdf = (calculationId, brandId, shouldApplyWatermark) => {
    return async (dispatch) => {
        const request = {
            filename: `${calculationId}.pdf`,
            calculationId: calculationId,
            showWatermark: shouldApplyWatermark,
        };
        const data = await callApi(dispatch, 'POST', `/PDF/${calculationId}/${brandId}`, request);
        if (!data)
            return;
        if (data.uploadSuccess) {
            dispatch(lockCalculation(calculationId));
        }
        dispatch({
            type: actionTypes.EXPORT_PDF,
            payload: {
                export: {
                    uploadSuccess: data.uploadSuccess,
                    error: data.error,
                    url: data.url,
                    pdfFile: data.pdfFile,
                    fileName: `${calculationId}.pdf`,
                    calculationId: calculationId,
                },
            },
        });
    };
};
