import { Button, Container, Fade, FormControlLabel, Grid, Switch } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveCalculation, closeActiveCalculation, copyCalculation } from '../../../actions/calculationActions';
import { checkIsPowerUser } from '../../../common/userProfile';
import { uuid } from '../../../common/uuid';
import { applicationTypeSelector, currentBuildingRegionNameSelector, currentCalculationBusinessUnitSelector, inactiveCalculationsSelector, interimOrCurrentCalculationSelector, isLoadingMasterDataSelector, saveSuccessSelector, userProfileSelector, } from '../../../store/selectors';
import { CalculationNavigation } from '../../CalculationNavigation/CalculationNavigation';
import { CalculationResults } from '../../CalculationResults/CalculationResults';
import { CondensationRiskModal } from '../../CondensationRiskModal/CondensationRiskModal';
import { ConstructionModal } from '../../ConstructionModal/ConstructionModal';
import { bridgingTabIndex, LayerModal, LayerModalMode, vapourTabIndex } from '../../LayerModal/LayerModal';
import { LayersTable, LayersTableMode } from '../../LayersTable/LayersTable';
import { MasterDataUploadModal } from '../../MasterDataUploadModal/MasterDataUploadModal';
import { NewCalculationModal } from '../../NewCalculationModal/NewCalculationModal';
import { OpenCalculationModal } from '../../OpenCalculationModal/OpenCalculationModal';
import { WelcomeModal } from '../../WelcomeModal/WelcomeModal';
import { PdfModal } from '../../PdfModal/PdfModal';
import { ProjectInfoModal } from '../../ProjectInfoModal/ProjectInfoModal';
import { VerticalAlign } from '../../Utilities/VerticalAlign/VerticalAlign';
import styles from './CalculationScreen.module.scss';
import { ContactInfoModal } from '../../ContactInfoModal/ContactInfoModal';
const getCondensationMessage = (calculation) => {
    if (!calculation) {
        return '';
    }
    if (!calculation.envSettings) {
        return 'Not Set';
    }
    if (calculation.hasCondensation) {
        return 'Condensation';
    }
    return 'No Condensation';
};
export function CalculationScreen() {
    var _a, _b, _c, _d, _e, _f;
    const dispatch = useDispatch();
    const calculation = useSelector(interimOrCurrentCalculationSelector);
    const applicationTypes = useSelector(applicationTypeSelector);
    const inactiveCalculations = useSelector(inactiveCalculationsSelector);
    const currentBuildingRegionName = useSelector(currentBuildingRegionNameSelector);
    const saveSuccess = useSelector(saveSuccessSelector);
    const userProfile = useSelector(userProfileSelector);
    const isLoadingMasterData = useSelector(isLoadingMasterDataSelector);
    const currentCalculationBusinessUnit = useSelector(currentCalculationBusinessUnitSelector);
    const [isLayerModalOpen, setIsLayerModalOpen] = React.useState(false);
    const [layerModalLayerInstanceId, setLayerModalLayerInstanceId] = React.useState();
    const [layerModalMode, setLayerModalMode] = React.useState(LayerModalMode.ADD);
    const [isConstructionModalOpen, setIsConstructionModalOpen] = React.useState(false);
    const [isEnvConditionsModalOpen, setIsEnvConditionsModalOpen] = React.useState(false);
    const [layersTableMode, setLayersTableMode] = React.useState(LayersTableMode.THERMAL);
    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = React.useState(true);
    const [isNewCalculationModalOpen, setIsNewCalculationModalOpen] = React.useState(false);
    const [isOpenCalculationModalOpen, setIsOpenCalculationModalOpen] = React.useState(false);
    const [canDismissNewCalculationModal, setCanDismissNewCalculationModal] = React.useState(false);
    const [isPrintPdfModalOpen, setIsPrintPdfModalOpen] = React.useState(false);
    const [isMasterDataUploadModalOpen, setIsMasterDataUploadModalOpen] = React.useState(false);
    const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = React.useState(false);
    const [isContactInfoModalOpen, setIsContactInfoModalOpen] = React.useState(false);
    const [layerModalInitialTabIndex, setLayerModalInitialTabIndex] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const applicationType = (_a = applicationTypes.find(applicationType => applicationType.id === (calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails.id))) === null || _a === void 0 ? void 0 : _a.name;
    const activeCalculationId = calculation === null || calculation === void 0 ? void 0 : calculation.calculationId;
    React.useEffect(() => {
        if (saveSuccess) {
            enqueueSnackbar('Saved', { variant: 'success', autoHideDuration: 1500, TransitionComponent: Fade });
        }
    }, [enqueueSnackbar, saveSuccess]);
    const handleOpenEnvConditionsModal = () => {
        setIsEnvConditionsModalOpen(true);
    };
    const handleOpenConstructionModal = () => {
        setIsConstructionModalOpen(true);
    };
    const handleCreateNewCalculationModal = () => {
        setCanDismissNewCalculationModal(true);
        setIsNewCalculationModalOpen(true);
    };
    const handleOpenCalculationModal = () => {
        setIsOpenCalculationModalOpen(true);
    };
    const handleOpenPrintPdfModal = () => {
        setIsPrintPdfModalOpen(true);
    };
    const handleOpenMasterDataUploadModal = () => {
        setIsMasterDataUploadModalOpen(true);
    };
    const handleClosePrintPdfModal = () => {
        setIsPrintPdfModalOpen(false);
    };
    const handleOpenProjectInfoModal = () => {
        setIsProjectInfoModalOpen(true);
    };
    const handleCloseProjectInfoModal = () => {
        setIsProjectInfoModalOpen(false);
    };
    const handleOpenContactInfoModal = () => {
        setIsContactInfoModalOpen(true);
    };
    const handleCloseContactInfoModal = () => {
        setIsContactInfoModalOpen(false);
    };
    const handleOpenLayerModal = (mode, instanceId, tabIndex) => {
        if (instanceId) {
            setLayerModalLayerInstanceId(instanceId);
        }
        else {
            /*
              When the mode is add and there isn't a selected layer,
              we set the default now so that the interim calculation results for the layer
              can be accessed.
            */
            setLayerModalLayerInstanceId(uuid());
        }
        setLayerModalMode(mode);
        if (mode === LayerModalMode.ADD) {
            setLayerModalInitialTabIndex(false);
        }
        else if (tabIndex !== undefined) {
            setLayerModalInitialTabIndex(tabIndex);
        }
        else if (layersTableMode === LayersTableMode.CONDENSATION) {
            setLayerModalInitialTabIndex(vapourTabIndex);
        }
        else {
            setLayerModalInitialTabIndex(bridgingTabIndex);
        }
        setIsLayerModalOpen(true);
    };
    const handleCloseEnvConditionsModal = () => {
        setIsEnvConditionsModalOpen(false);
    };
    const handleCloseConstructionModal = () => {
        setIsConstructionModalOpen(false);
    };
    const handleCloseLayerModal = () => {
        setLayerModalLayerInstanceId(undefined);
        setIsLayerModalOpen(false);
    };
    const handleWelcomeModalCreateNewCalculation = () => {
        setIsWelcomeModalOpen(false);
        handleCreateNewCalculationModal();
    };
    const handleWelcomeModalOpenCalculation = () => {
        setIsWelcomeModalOpen(false);
        handleOpenCalculationModal();
    };
    const handleWelcomeModalUpload = () => {
        setIsWelcomeModalOpen(false);
        handleOpenMasterDataUploadModal();
    };
    const handleCloseNewCalculationModal = (forceWelcome) => {
        setIsNewCalculationModalOpen(false);
        if (forceWelcome && calculationNavigationItems.length === 0) {
            setIsWelcomeModalOpen(true);
        }
    };
    const handleCloseOpenCalculationModal = (forceWelcome) => {
        setIsOpenCalculationModalOpen(false);
        if (forceWelcome && calculationNavigationItems.length === 0) {
            setIsWelcomeModalOpen(true);
        }
    };
    const handleCloseMasterDataUploadModal = (forceWelcome) => {
        setIsMasterDataUploadModalOpen(false);
        if (forceWelcome && calculationNavigationItems.length === 0) {
            setIsWelcomeModalOpen(true);
        }
    };
    const handleLayerTableModeSwitch = () => {
        setLayersTableMode(prevLayersTableMode => prevLayersTableMode === LayersTableMode.THERMAL ? LayersTableMode.CONDENSATION : LayersTableMode.THERMAL);
    };
    const handleNavigateCalculation = React.useCallback((_, selectedItem) => {
        if (selectedItem === activeCalculationId)
            return;
        dispatch(changeActiveCalculation(selectedItem));
    }, [dispatch, activeCalculationId]);
    const getCalculationNavigationItems = () => {
        const unorderedCalculations = [...(calculation ? [calculation] : []), ...inactiveCalculations];
        const orderedCalculations = unorderedCalculations.sort((a, b) => (a.order > b.order ? 1 : -1));
        return orderedCalculations.map(c => c === null || c === void 0 ? void 0 : c.calculationId);
    };
    const calculationNavigationItems = getCalculationNavigationItems();
    const handleCloseCalculation = React.useCallback(() => {
        dispatch(closeActiveCalculation());
        // If there'll be no calculation left open the welcome modal automatically.
        if (calculationNavigationItems.length === 1) {
            setIsWelcomeModalOpen(true);
        }
    }, [dispatch, calculationNavigationItems]);
    const handleCopyCalculation = React.useCallback(() => {
        dispatch(copyCalculation());
    }, [dispatch]);
    // Power user groups are configurable so dynamically determine it
    const isPowerUser = checkIsPowerUser(userProfile);
    return (React.createElement("div", null,
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 3 }),
            React.createElement(Grid, { item: true, xs: 9 },
                React.createElement(CalculationResults, { uValue: calculation ? calculation.uValue : '', uValue2DP: calculation ? calculation.uValue2DP : '', totalThermalResistance: calculation ? calculation.totalThermalResistance : '', isTotalCorrectionFactorBelowThreshold: calculation ? calculation.isTotalCorrectionFactorBelowThreshold : false, condensationResult: getCondensationMessage(calculation), isLocked: calculation === null || calculation === void 0 ? void 0 : calculation.locked, businessUnit: currentCalculationBusinessUnit, isLoadingMasterData: isLoadingMasterData }))),
        React.createElement(Grid, { container: true, spacing: 3, alignItems: "center" },
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement("div", { className: styles.buttonLinkLabel }, "Construction Type"),
                React.createElement("button", { "data-qa-id": "applicationTypeButton", className: styles.buttonLink, onClick: handleOpenConstructionModal }, calculation ? applicationType : '—')),
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement("div", { className: styles.buttonLinkLabel }, "Env. Conditions"),
                React.createElement("button", { "data-qa-id": "envConditionsButton", className: styles.buttonLink, onClick: handleOpenEnvConditionsModal }, calculation ? currentBuildingRegionName || 'Not set' : '—')),
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement("div", { className: styles.buttonLinkLabel, "data-qa-id": "openProjectInfoModalLabel" }, "Project Info"),
                React.createElement("button", { "data-qa-id": "openProjectInfoModalButton", className: styles.buttonLink, onClick: handleOpenProjectInfoModal }, calculation ? (_c = (_b = calculation.project) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : 'Not set' : '—')),
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement("div", { className: styles.buttonLinkLabel, "data-qa-id": "openContactInfoModalLabel" }, "Project Contact"),
                React.createElement("button", { "data-qa-id": "openContactInfoModalButton", className: styles.buttonLink, onClick: handleOpenContactInfoModal }, calculation ? ((((_d = calculation === null || calculation === void 0 ? void 0 : calculation.contact) === null || _d === void 0 ? void 0 : _d.firstName) && calculation.contact.lastName) ? ((_e = calculation.contact) === null || _e === void 0 ? void 0 : _e.firstName) + " " + ((_f = calculation.contact) === null || _f === void 0 ? void 0 : _f.lastName) : 'Not set') : '—')),
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement(FormControlLabel, { "data-qa-id": "condensationViewSwitchLabel", control: React.createElement(Switch, { color: "primary", checked: layersTableMode === LayersTableMode.CONDENSATION, "data-qa-id": "condensationViewSwitch", name: "checkedB", onChange: handleLayerTableModeSwitch }), label: "Condensation", labelPlacement: "end" })),
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement(VerticalAlign, { align: "bottom" },
                    React.createElement(Button, { onClick: () => handleOpenLayerModal(LayerModalMode.ADD), "data-qa-id": "addLayerButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true, startIcon: React.createElement(AddCircle, null), autoFocus: true, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked }, "Add Layer")))),
        React.createElement(Grid, { container: true },
            React.createElement(DndProvider, { backend: Backend },
                React.createElement(LayersTable, { layersTableMode: layersTableMode, onLayerClick: (instanceId, tabIndex) => handleOpenLayerModal(LayerModalMode.EDIT, instanceId, tabIndex), isLocked: calculation === null || calculation === void 0 ? void 0 : calculation.locked }))),
        React.createElement("div", { className: styles.navigationBarContainer },
            React.createElement("div", { className: styles.navigationBar },
                React.createElement(Container, { fixed: true, disableGutters: true },
                    React.createElement(Grid, { container: true, spacing: 3 },
                        React.createElement(Grid, { item: true, xs: isPowerUser ? 8 : 9, "data-qa-id": "navigationBarGrid" },
                            React.createElement("div", { className: calculation === null ? styles.navigationBarDisabled : '' },
                                React.createElement(CalculationNavigation, { items: calculationNavigationItems, currentItem: calculation === null || calculation === void 0 ? void 0 : calculation.calculationId, handleChange: handleNavigateCalculation, handleTabClose: handleCloseCalculation, handleTabCopy: handleCopyCalculation }))),
                        isPowerUser && (React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(VerticalAlign, { align: "center" },
                                React.createElement(Button, { onClick: handleOpenMasterDataUploadModal, "data-qa-id": "openMasterDataUploadModalButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true }, "Upload")))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(VerticalAlign, { align: "center" },
                                React.createElement(Button, { onClick: handleOpenPrintPdfModal, "data-qa-id": "exportPdfButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true }, "Export"))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(VerticalAlign, { align: "center" },
                                React.createElement(Button, { onClick: handleCreateNewCalculationModal, "data-qa-id": "newCalculationButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true }, "Create"))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(VerticalAlign, { align: "center" },
                                React.createElement(Button, { onClick: handleOpenCalculationModal, "data-qa-id": "openCalculationButton", variant: "contained", color: "primary", disableElevation: true, fullWidth: true }, "Open"))))))),
        isConstructionModalOpen && React.createElement(ConstructionModal, { onClose: handleCloseConstructionModal }),
        isLayerModalOpen && (React.createElement(LayerModal, { mode: layerModalMode, layerInstanceId: layerModalLayerInstanceId, setLayerInstanceId: setLayerModalLayerInstanceId, onClose: handleCloseLayerModal, initialTabIndex: layerModalInitialTabIndex })),
        isEnvConditionsModalOpen && React.createElement(CondensationRiskModal, { onClose: handleCloseEnvConditionsModal }),
        isWelcomeModalOpen && React.createElement(WelcomeModal, { onCreateNewCalculationClick: handleWelcomeModalCreateNewCalculation, onOpenCalculationClick: handleWelcomeModalOpenCalculation, onMasterDataUploadClick: handleWelcomeModalUpload }),
        isNewCalculationModalOpen && React.createElement(NewCalculationModal, { onClose: handleCloseNewCalculationModal, canClose: canDismissNewCalculationModal }),
        isOpenCalculationModalOpen && React.createElement(OpenCalculationModal, { onClose: handleCloseOpenCalculationModal }),
        isPrintPdfModalOpen && React.createElement(PdfModal, { onClose: handleClosePrintPdfModal }),
        isMasterDataUploadModalOpen && React.createElement(MasterDataUploadModal, { onClose: handleCloseMasterDataUploadModal }),
        isProjectInfoModalOpen && React.createElement(ProjectInfoModal, { onClose: handleCloseProjectInfoModal }),
        isContactInfoModalOpen && React.createElement(ContactInfoModal, { onClose: handleCloseContactInfoModal })));
}
