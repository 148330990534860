import React from 'react';
import { Grid, TextField, InputLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TabPanel } from './TabPanel';
import styles from './LayerModal.module.scss';
export function BridgingTab(props) {
    var _a, _b;
    const { masterData, clearBridgingDetails, referenceLayer, bridgeMaterial, setBridgeMaterial, bridgeWidth, setBridgeWidth, bridgeCentres, setBridgeCentres, bridgeNonBridgedHeight, setBridgeNonBridgedHeight, layerBridgePercentage, targetIndex, currentTabIndex, isLocked, } = props;
    return (React.createElement(TabPanel, { id: "layer-bridging-details-panel", "data-qa-id": "layer-bridging-details-panel", targetIndex: targetIndex, currentTabIndex: currentTabIndex, "aria-labelledby": "layer-bridging-details-tab" },
        React.createElement("div", { className: styles.tabContent },
            React.createElement(Grid, { container: true, spacing: 3 },
                React.createElement(Grid, { item: true, xs: 5 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-bridge-material", "data-qa-id": "layerBridgeMaterialLabel" }, "Bridge Material"),
                    React.createElement(Autocomplete, { id: "modal-layer-bridge-material", "data-qa-id": "layerBridgeMaterialInput", options: masterData.bridgeMaterials, autoHighlight: true, getOptionLabel: (material) => material.name, onChange: (event, material) => {
                            if (!material) {
                                clearBridgingDetails();
                                return;
                            }
                            setBridgeMaterial(material);
                        }, value: bridgeMaterial, disabled: isLocked, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { placeholder: "Browse catalogue...", variant: "outlined", inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: 'new-password' }) }))) })),
                React.createElement(Grid, { item: true, xs: 1 }),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-bridge-width", "data-qa-id": "bridgeWidthLabel" }, "Bridge Width"),
                    React.createElement(Grid, { container: true, alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 10 },
                            React.createElement(TextField, { id: "modal-layer-bridge-width", "data-qa-id": "bridgeWidthInput", variant: "outlined", value: bridgeWidth, disabled: isLocked, placeholder: "0.0", onChange: (event) => setBridgeWidth(event.target.value) })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerBridgeWidthUnits" }, "mm")))),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-bridge-centres", "data-qa-id": "bridgeCentresLabel" }, "Centres"),
                    React.createElement(Grid, { container: true, alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 10 },
                            React.createElement(TextField, { id: "modal-layer-bridge-centres", "data-qa-id": "bridgeCentresInput", variant: "outlined", value: bridgeCentres, disabled: isLocked, placeholder: "0.0", onChange: (event) => setBridgeCentres(event.target.value) })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerBridgeCentresUnits" }, "mm")))),
                React.createElement(Grid, { item: true, xs: 2 },
                    React.createElement(InputLabel, { htmlFor: "modal-layer-bridge-non-bridged-height", "data-qa-id": "bridgeNonBridgedHeightLabel" }, "Non-Bridged Height"),
                    React.createElement(Grid, { container: true, alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 10 },
                            React.createElement(TextField, { id: "modal-layer-bridge-non-bridged-height", "data-qa-id": "bridgeNonBridgedHeightInput", variant: "outlined", value: bridgeNonBridgedHeight || '', disabled: isLocked, helperText: "(if known)", onChange: (event) => setBridgeNonBridgedHeight(event.target.value) })),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "layerNonBridgedHeightUnits" }, "mm")))))),
        React.createElement("div", { className: styles.tabContentResults },
            React.createElement(Grid, { container: true, spacing: 10 },
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "bridgedThermalConductivityLabel" }, "Bridged Thermal Conductivity"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "bridgedThermalConductivity" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "bridgedThermalConductivityValue" }, (_a = bridgeMaterial === null || bridgeMaterial === void 0 ? void 0 : bridgeMaterial.bridgeThermalConductivity) !== null && _a !== void 0 ? _a : '0.000'),
                                    React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "bridgedThermalConductivityUnit" }, "W/mK"))))),
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "unbridgedThermalConductivityLabel" }, "Unbridged Thermal Conductivity"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "unbridgedThermalConductivity" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "unbridgedThermalConductivityValue" }, (_b = referenceLayer === null || referenceLayer === void 0 ? void 0 : referenceLayer.thermalConductivity) !== null && _b !== void 0 ? _b : '0.000'),
                                    React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "unbridgedThermalConductivityUnit" }, "W/mK")))))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement("div", { className: styles.tabContentResult },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "bridgingPercentageLabel" }, "Bridging Percentage"),
                            React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "bridgingPercentage" },
                                React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                    React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "bridgingPercentageValue" }, layerBridgePercentage != null ? (Number.parseFloat(layerBridgePercentage) * 100).toFixed(1) : '0.0'),
                                    React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "bridgingPercentageUnit" }, "%"))))))))));
}
