import { actionTypes } from '../actions/actionTypes';
const initialState = {
    currentCalculation: null,
    interimCalculation: null,
    savedSuccessfully: null,
};
export const calculationReducer = (state = initialState, action) => {
    var _a, _b;
    switch (action.type) {
        case actionTypes.CHANGE_ACTIVE_CALCULATION:
            return Object.assign(Object.assign({}, state), { currentCalculation: action.payload.newActiveCalculation, interimCalculation: null });
        case actionTypes.MAKE_ALL_CALCULATIONS_INACTIVE:
            return Object.assign(Object.assign({}, state), { currentCalculation: null, interimCalculation: null });
        case actionTypes.REGISTER_CALCULATION:
            return Object.assign(Object.assign({}, state), { currentCalculation: Object.assign(Object.assign({}, action.payload.calculation), { order: action.payload.order }), interimCalculation: null });
        case actionTypes.CLOSE_ACTIVE_CALCULATION:
            return Object.assign(Object.assign({}, state), { currentCalculation: action.payload.newActiveCalculation || null, interimCalculation: null });
        case actionTypes.SET_ACTIVE_CALCULATION_DATA:
            return Object.assign(Object.assign({}, state), { currentCalculation: Object.assign(Object.assign({}, action.payload.newCalculationData), { calculationId: action.payload.shouldUseExistingCalculationId
                        ? (_a = action.payload.newCalculationData) === null || _a === void 0 ? void 0 : _a.calculationId
                        : (_b = state.currentCalculation) === null || _b === void 0 ? void 0 : _b.calculationId, order: action.payload.order }), interimCalculation: null });
        case actionTypes.LOCK_CALCULATION:
            if (action.payload.isCurrentCalculation) {
                return Object.assign(Object.assign({}, state), { currentCalculation: Object.assign(Object.assign({}, state.currentCalculation), action.payload.calculation), 
                    // Explicitly clearing interim calculation when current calculation is updated
                    interimCalculation: null });
            }
            else {
                return state;
            }
        case actionTypes.ADD_LAYER_TO_CALCULATION:
        case actionTypes.EDIT_LAYER_IN_CALCULATION:
        case actionTypes.INTERIM_MOVE_LAYER_IN_CALCULATION:
        case actionTypes.MOVE_LAYER_IN_CALCULATION:
        case actionTypes.DUPLICATE_LAYER_IN_CALCULATION:
        case actionTypes.REMOVE_LAYER_FROM_CALCULATION:
        case actionTypes.ADD_CONSTRUCTION_DETAILS_TO_CALCULATION:
            return Object.assign(Object.assign({}, state), { currentCalculation: action.payload.calculation, 
                // Explicitly clearing interim calculation when current calculation is updated
                interimCalculation: null });
        case actionTypes.ADD_LAYER_TO_INTERIM_CALCULATION:
        case actionTypes.EDIT_LAYER_IN_INTERIM_CALCULATION:
            return Object.assign(Object.assign({}, state), { interimCalculation: action.payload.calculation });
        case actionTypes.CLEAR_INTERIM_CALCULATION:
            return Object.assign(Object.assign({}, state), { interimCalculation: null });
        case actionTypes.UPDATE_CALCULATION_PROJECT:
            return Object.assign(Object.assign({}, state), { currentCalculation: Object.assign(Object.assign({}, state.currentCalculation), { project: action.payload.project }), interimCalculation: null });
        case actionTypes.UPDATE_CALCULATION_CONTACT:
            return Object.assign(Object.assign({}, state), { currentCalculation: Object.assign(Object.assign({}, state.currentCalculation), { contact: action.payload.contact }), interimCalculation: null });
        case actionTypes.EDIT_ENV_CONDITIONS:
        case actionTypes.EDIT_ENV_SETTINGS:
            return Object.assign(Object.assign({}, state), { interimCalculation: action.payload.calculation });
        case actionTypes.SAVE_ENV_CONDITIONS:
            return Object.assign(Object.assign({}, state), { currentCalculation: action.payload.calculation, interimCalculation: null });
        case actionTypes.SET_CALCULATION_SAVE_SUCCESS:
            return Object.assign(Object.assign({}, state), { savedSuccessfully: true });
        case actionTypes.CLEAR_CALCULATION_SAVE_SUCCESS:
            return Object.assign(Object.assign({}, state), { savedSuccessfully: null });
        default:
            return state;
    }
};
