import { actionTypes } from '../actions/actionTypes';
const initialState = {
    isFirstCallSuccessful: false,
    isAuthenticated: true,
    isAuthorised: true,
};
export const apiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.API_CALL_SUCCESSFUL:
            return {
                isFirstCallSuccessful: true,
                isAuthenticated: true,
                isAuthorised: true,
            };
        case actionTypes.SET_UNAUTHENTICATED:
            return Object.assign(Object.assign({}, state), { isAuthenticated: false, isAuthorised: false });
        case actionTypes.SET_UNAUTHORISED:
            return Object.assign(Object.assign({}, state), { isAuthenticated: true, isAuthorised: false });
        default:
            return state;
    }
};
